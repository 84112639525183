import { createFileRoute } from '@tanstack/react-router';
import Page from 'src/pages/dashboard/company/list';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute('/_auth/dashboard/companies/')({
  beforeLoad: ({ context }) =>
    checkRouteAccess(
      context,
      routeAccessConfigs.superAdminAndCompanyPrimaryAdmin
    ),
  component: Page,
});
