import Box from '@mui/material/Box';
import type { ReactNode } from 'react';
import type { MarkerProps } from 'react-map-gl';
import { Marker } from 'react-map-gl';

const SIZE = 35; // Increased size for better visibility

interface MapMarkerProps extends MarkerProps {
  children?: ReactNode;
  logo?: ReactNode;
}

export function MapMarker({
  latitude,
  longitude,
  color,
  logo,
  ...other
}: MapMarkerProps) {
  return (
    <Marker latitude={latitude} longitude={longitude} {...other}>
      <Box
        sx={{
          width: SIZE,
          height: SIZE,
          borderRadius: '50%',
          backgroundColor: theme =>
            color
              ? (
                  theme.vars.palette[
                    color as keyof typeof theme.vars.palette
                  ] as any
                )?.main
              : theme.vars.palette.common.white,

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          transform: `translate(${-SIZE / 2}px, ${-SIZE / 2}px)`,
          // border: theme =>
          //   `${SIZE / 3}px solid ${
          //     color
          //       ? (
          //           theme.vars.palette[
          //             color as keyof typeof theme.vars.palette
          //           ] as any
          //         )?.lighter
          //       : theme.vars.palette.primary.lighter
          //   }`,
          boxSizing: 'content-box',
        }}
      >
        {logo}
        {/* <Typography
          variant="body2"
          sx={{
            color: 'white',
            fontWeight: 'bold',
          }}
        ></Typography> */}
      </Box>
    </Marker>
  );
}
