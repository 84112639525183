import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect, useMemo, useState } from 'react';

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const useElapsedTime = (startTimestamp: number): string => {
  const [elapsedSeconds, setElapsedSeconds] = useState<number>(0);

  useEffect(() => {
    const calculateElapsed = () => {
      const elapsed = dayjs().diff(dayjs(startTimestamp), 'second');
      setElapsedSeconds(elapsed);
    };

    calculateElapsed();
    const interval = setInterval(calculateElapsed, 1000);
    return () => clearInterval(interval);
  }, [startTimestamp]);

  const displayText = useMemo(() => {
    const duration = dayjs.duration(elapsedSeconds * 1000);
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    if (hours >= 10) {
      return 'Over 10 hours';
    }

    if (hours >= 1) {
      return `${hours} hours, ${minutes} minutes`;
    }

    if (minutes >= 1) {
      return `${minutes} minutes, ${seconds} seconds`;
    }

    return `${seconds} seconds`;
  }, [elapsedSeconds]);

  return displayText;
};
