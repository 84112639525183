import { api } from '@api';
import { createFileRoute, notFound } from '@tanstack/react-router';
import { Id } from 'src/convex/_generated/dataModel';
import Page from 'src/pages/dashboard/dispatcher/edit';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute(
  '/_auth/dashboard/dispatchers/$dispatcherId'
)({
  beforeLoad: ({ context }) =>
    checkRouteAccess(context, routeAccessConfigs.dispatcherGeneral),
  loader: async ({ context, params }) => {
    try {
      const user = await context.convex.query(api.functions.users.getUserById, {
        id: params.dispatcherId as Id<'users'>,
      });

      return user;
    } catch (error) {
      // TODO: Come up with a better pattern all around for this
      // could be not found (deleted) or not authorized.. found but not authorized
      throw notFound();
    }
  },
  component: Page,
});
