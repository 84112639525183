import { debounce } from 'lodash-es';
import { useMemo, useState } from 'react';
import { searchAddresses, SearchResult } from './searchAddress';

export interface Coordinates {
  lat: number;
  long: number;
}

export const useAddressSearch = () => {
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [coordinates, setCoordinates] = useState<Coordinates | null>(null);

  const debouncedSearch = useMemo(
    () =>
      debounce(async (query: string) => {
        const results = await searchAddresses(query);
        setSearchResults(results);
      }, 200),
    []
  );

  return {
    searchResults,
    coordinates,
    setCoordinates,
    searchAddress: debouncedSearch,
  };
};
