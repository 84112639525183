import { Doc } from '../../_generated/dataModel';

export async function generateUserSearchFields(user: Doc<'users'>) {
  const components = [
    user.clerkUser.fullName,
    user.clerkUser.primaryEmailAddress?.emailAddress,
    user.clerkUser.primaryPhoneNumber?.phoneNumber,
  ].filter(Boolean);

  return {
    searchQueryText: components.join(' ').toLowerCase(),
  };
}
