import { createFileRoute } from '@tanstack/react-router';
import Page from 'src/pages/dashboard/location/new';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute('/_auth/dashboard/locations/new')({
  beforeLoad: ({ context }) =>
    checkRouteAccess(context, routeAccessConfigs.dispatcherGeneral),
  component: Page,
});
