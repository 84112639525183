import type { SxProps, Theme } from '@mui/material/styles';
import type { TablePaginationProps } from '@mui/material/TablePagination';

import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import { ROWS_PER_PAGE } from 'src/minimal-theme/utils/rowsPerPage';

// ----------------------------------------------------------------------

export type TablePaginationCustomProps = TablePaginationProps & {
  dense?: boolean;
  sx?: SxProps<Theme>;
  onChangeDense?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export function TablePaginationCustom({
  sx,
  dense,
  onChangeDense,
  rowsPerPageOptions = [5, 10, 25],
  rowsPerPage = ROWS_PER_PAGE,
  ...other
}: TablePaginationCustomProps) {
  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <TablePagination
        rowsPerPageOptions={[]}
        rowsPerPage={ROWS_PER_PAGE}
        component="div"
        {...other}
        sx={{ borderTopColor: 'transparent' }}
      />

      {/* {onChangeDense && (
        <FormControlLabel
          label="Dense"
          control={
            <Switch name="dense" checked={dense} onChange={onChangeDense} />
          }
          sx={{
            pl: 2,
            py: 1.5,
            top: 0,
            position: { sm: 'absolute' },
          }}
        />
      )} */}
    </Box>
  );
}
