import { z } from 'zod';

// Main vehicle categories
export const vehicleCategory = z.enum(['TRUCK', 'TRAILER', 'YARD_EQUIPMENT']);

export type VehicleCategories = z.infer<typeof vehicleCategory>;

// Specific types within each category
export const truckType = z.enum(['HEAVY_DUTY', 'MEDIUM_DUTY', 'LIGHT_DUTY']);

export const trailerType = z.enum([
  'FLATBED_STEP_DECK_EXTENDABLE',
  'DRY_VAN_PUP',
  'REEFER',
  'TANKER',
  'CAR_HAULER',
  'LOW_BOY_RGN',
  'CONESTOGA_CURTAIN_SIDE',
  'DUMP',
]);
export const yardEquipmentType = z.enum(['SWITCHER_YARD', 'FORKLIFT_MOFFETT']);

const allTypes = [
  ...truckType.options,
  ...trailerType.options,
  ...yardEquipmentType.options,
] as const;

// Combined type for all specific vehicle types
export const vehicleType = z.enum(allTypes);

export type VehicleType = z.infer<typeof vehicleType>;

// Friendly name mappings
export const friendlyCategoryNames: Record<VehicleCategories, string> = {
  // Vehicle Categories
  TRUCK: 'Truck',
  TRAILER: 'Trailer',
  YARD_EQUIPMENT: 'Yard Equipment',
};

export const friendlyTypeNames: Record<VehicleType, string> = {
  // Truck Types
  HEAVY_DUTY: 'Truck - Heavy Duty',
  MEDIUM_DUTY: 'Truck - Medium Duty',
  LIGHT_DUTY: 'Truck - Light Duty',

  // Trailer Types
  FLATBED_STEP_DECK_EXTENDABLE: 'Trailer - Flatbed / Step Deck / Extendable',
  DRY_VAN_PUP: 'Trailer - Dry Van / Pup',
  REEFER: 'Trailer - Reefer',
  TANKER: 'Trailer - Tanker',
  CAR_HAULER: 'Trailer - Car Hauler',
  LOW_BOY_RGN: 'Trailer - Low Boy / RGN',
  CONESTOGA_CURTAIN_SIDE: 'Trailer - Conestoga / Curtain Side',
  DUMP: 'Trailer - Dump Trailer',

  // Yard
  SWITCHER_YARD: 'Switcher / Yard Truck',
  FORKLIFT_MOFFETT: 'Forklift / Moffett',
};

// Helper function to get friendly name
export const getFriendlyVehicleTypeName = (type: VehicleType): string => {
  return friendlyTypeNames[type] || type;
};
export const getFriendlyVehicleCategoryName = (
  type: VehicleCategories
): string => {
  return friendlyCategoryNames[type] || type;
};

// Helper function to determine if vehicle should use VIN
export const requiresVIN = ({
  category,
  type,
}: {
  category?: VehicleCategories;
  type?: VehicleType;
}): boolean => {
  if (!category || !type) {
    return false;
  }
  if (category === 'YARD_EQUIPMENT') {
    return type === 'SWITCHER_YARD'; // Only switchers need VIN in yard equipment
  }
  return true; // All trucks and trailers need VIN
};

export const requiresSerial = ({
  category,
  type,
}: {
  category?: VehicleCategories;
  type?: VehicleType;
}): boolean => {
  if (!category || !type) {
    return false;
  }
  if (category === 'YARD_EQUIPMENT') {
    return type === 'FORKLIFT_MOFFETT';
  }
  return false;
};
