import { z } from 'zod';

export const companyType = z.enum([
  'REPAIR_SHOP',
  'TOWING',
  'FLEET',
  'THIRD_PARTY',
  'SYSTEM_ADMIN',
]);

export type CompanyType = z.infer<typeof companyType>;

export const COMPANY_TYPE_DISPLAY: Record<
  (typeof companyType)['options'][number],
  string
> = {
  FLEET: 'Fleet',
  SYSTEM_ADMIN: 'System',
  TOWING: 'Towing',
  THIRD_PARTY: 'Third Party Call Center',
  REPAIR_SHOP: 'Provider',
};

export const getCompanyTypeDisplay = (
  type: (typeof companyType)['options'][number]
) => COMPANY_TYPE_DISPLAY[type];
