import { zid, zodToConvex } from 'convex-helpers/server/zod';
import { defineTable } from 'convex/server';
import { z } from 'zod';
import { Id } from '../../../_generated/dataModel';
import {
  locationReferencesSchema,
  locationSchema,
} from '../../../types/locationSchema';
import {
  polygonServiceArea,
  radiusServiceArea,
  rectangleServiceArea,
} from '../../../types/serviceAreaShapes';
import { phoneSchema } from '../../../zodHelpers/phoneSchema';
import { paymentMethod } from '../../enums/paymentMethod';
import { stripeSettingsSchema } from '../stripeSettings';
import { subscriptionInfoSchema } from '../subscriptionHistory';

const serviceAreaSchema = z.discriminatedUnion('type', [
  radiusServiceArea,
  polygonServiceArea,
  rectangleServiceArea,
]);

export const physicalLocationType = z.enum([
  // Fleet company locations
  'FLEET_TERMINAL',
  'FLEET_YARD',
  'FLEET_MAINTENANCE_SHOP',

  // Service provider locations
  'REPAIR_SHOP',
  'MOBILE_REPAIR_BASE',
  'TIRE_SHOP',
  'TOW_YARD',

  'PARTS_WAREHOUSE',
  'TRUCK_STOP',

  // Dispatch-only location
  'FLEET_DISPATCH_CENTER',
  'SERVICE_DISPATCH_CENTER',
  'THIRD_PARTY_CALL_CENTER',

  // Shared/Generic
  'ADMINISTRATIVE_OFFICE',
  'OTHER',
]);

// TODO: Eventually we will get rid of this... when we know what to do with the other location types
export const limitedLocationTypes = z.enum([
  // Fleet company locations
  'FLEET_TERMINAL',
  'FLEET_YARD',
  // 'FLEET_MAINTENANCE_SHOP',

  // Service provider locations
  'REPAIR_SHOP',
  'MOBILE_REPAIR_BASE',

  // Dispatch-only location
  'FLEET_DISPATCH_CENTER',
  'SERVICE_DISPATCH_CENTER',
]);

export const FLEET_LOCATION_TYPES = z.enum([
  'FLEET_TERMINAL',
  'FLEET_YARD',
  'FLEET_DISPATCH_CENTER',
]);
export const REPAIR_SHOP_LOCATION_TYPES = z.enum([
  'REPAIR_SHOP',
  'MOBILE_REPAIR_BASE',
  'SERVICE_DISPATCH_CENTER',
]);

export const THIRD_PARTY_LOCATION_TYPES = z.enum(['THIRD_PARTY_CALL_CENTER']);

export type PhysicalLocationType = z.infer<typeof physicalLocationType>;
export function isFleetType(type?: PhysicalLocationType): boolean {
  return type
    ? [
        'FLEET_TERMINAL',
        'FLEET_YARD',
        'FLEET_MAINTENANCE_SHOP',
        'FLEET_DISPATCH_CENTER',
      ].includes(type)
    : false;
}

export function isServiceType(type?: PhysicalLocationType): boolean {
  return type
    ? [
        'REPAIR_SHOP',
        'MOBILE_REPAIR_BASE',
        'TIRE_SHOP',
        'TOW_YARD',
        'SERVICE_DISPATCH_CENTER',
      ].includes(type)
    : false;
}

export const PHYSICAL_LOCATION_TYPE_DISPLAY: Record<
  (typeof physicalLocationType)['options'][number],
  string
> = {
  FLEET_TERMINAL: 'Fleet Terminal',
  FLEET_YARD: 'Fleet Yard',
  FLEET_MAINTENANCE_SHOP: 'Fleet Maintenance Shop',
  REPAIR_SHOP: 'Repair Shop',
  FLEET_DISPATCH_CENTER: 'Fleet Dispatch Center',
  SERVICE_DISPATCH_CENTER: 'Service Dispatch Center',
  MOBILE_REPAIR_BASE: 'Mobile Repair Base',
  TIRE_SHOP: 'Tire Shop',
  TOW_YARD: 'Tow Yard',
  PARTS_WAREHOUSE: 'Parts Warehouse',
  TRUCK_STOP: 'Truck Stop',
  ADMINISTRATIVE_OFFICE: 'Administrative Office',
  OTHER: 'Other',
  THIRD_PARTY_CALL_CENTER: 'Third Party Call Center',
};

export const getPhysicalLocationTypeDisplay = (
  type: (typeof physicalLocationType)['options'][number]
) => PHYSICAL_LOCATION_TYPE_DISPLAY[type];

/** Entity for grouping users directly or nesting child groups, roles and direct permissions can be assigned to groups */
export const groupSchema = z
  .object({
    name: z.string(),
    description: z.string(),

    parentId: zid('groups').optional(), // reference to parent group
    path: z.string().optional(),

    allowDriversSelfRequest: z.boolean().optional(),
    // Physical location groups
    representsPhysicalLocation: z.boolean(), // For service vendors (or fleets) with physical locations, we will enforce that users can only belong to one of these groups
    location: locationSchema.optional(),
    locationType: physicalLocationType.optional(),
    serviceArea: serviceAreaSchema.optional(),

    isRetail: z.boolean().optional(),

    isSuperAdmin: z.boolean().optional(),

    simpleServiceAreaByMilesOut: z.number().optional(),

    // Verification/onboarding status
    /** This governs whether or not a request can directly flow to the dispatch queue pertaining to this group
     * it would apply when a fleet dispatch/owner operator driver is submitting a request to an SP dispatch (not possible if false, must call)
     */
    hasVerifiedDispatchTeam: z.boolean().optional(),
    hasVerifiedTechnicians: z.boolean().optional(),

    acceptedPaymentMethods: z.array(paymentMethod).optional(),

    // Operational details for physical locations, TODO: .. could also maybe link into google business listings for this info... but fallback if they don't have one?
    operationalHours: z
      .object({
        timezone: z.string(),
        schedule: z.array(
          z.object({
            dayOfWeek: z.number(), // 0-6, Sunday = 0
            openTime: z.string(), // "HH:mm" format
            closeTime: z.string(), // "HH:mm" format
            is24Hours: z.boolean(),
          })
        ),
        holidaySchedule: z
          .array(
            z.object({
              date: z.string(), // "YYYY-MM-DD"
              isClosed: z.boolean(),
              openTime: z.string().optional(),
              closeTime: z.string().optional(),
            })
          )
          .optional(),
      })
      .optional(),

    // Contact info for physical locations
    contactInfo: z
      .object({
        phone: phoneSchema.optional(),
        email: z.string().email().optional(),
        dispatchPhone: phoneSchema.optional(), // 24/7 dispatch contact
        emergencyPhone: phoneSchema.optional(), // After hours emergency
        notes: z.string().optional(),
      })
      .optional(),

    isActive: z.boolean(),
    systemGenerated: z.boolean(),

    createdByUserDuringRequest: z.boolean().optional(),
    createdFromRequestId: zid('requests').optional(),
    createdByLocationGroupId: zid('groups').optional(),

    invitedByUser: zid('users').optional(),
    invitedByLocation: zid('groups').optional(),
    invitedByCompany: zid('companies').optional(),

    // Admin verification progress
    isAdminVerified: z.boolean().optional(), // Only true when our team verifies
    adminVerifiedAt: z.string().optional(),
    adminVerifiedById: zid('users').optional(),

    // If service provider has completed profile/onboarding
    hasCompletedOnboarding: z.boolean().optional(),

    // Whether to show when searching for providers
    showInProviderDirectory: z.boolean().optional(),

    // For dispatchers if they cover multiple location groups
    dispatchCoverageGroupIds: z.array(zid('groups')).optional(),

    // For fleet driver locations, and service repair locations, allows requests to flow to the appropriate dispatch group
    defaultDispatchGroupId: zid('groups').optional(),

    createdById: zid('users').optional(),
    companyId: zid('companies').optional(),

    stripeAccountId: z.string().optional(),
    isStripeConnected: z.boolean().optional(),

    // Payment processing settings
    stripeSettings: stripeSettingsSchema.optional(),
    stripeConnectedAccountOnboardingLink: z.string().optional(),
  })
  .merge(locationReferencesSchema)
  .merge(subscriptionInfoSchema);

export type MyPrimaryLocation = {
  _id: Id<'groups'>;
  name: string;
  locationType: PhysicalLocationType;
  phoneNumber: string;
  address: string;
};

export type CompanyGroup = z.infer<typeof groupSchema>;

export const groups = defineTable(zodToConvex(groupSchema).fields)
  .index('by_phone', ['contactInfo.phone'])
  .index('by_dispatchPhone', ['contactInfo.dispatchPhone'])
  .index('by_email', ['contactInfo.email'])
  .index('by_emergencyPhone', ['contactInfo.emergencyPhone'])
  .index('by_companyId', ['companyId'])
  .index('by_locationType', ['locationType'])
  .index('by_defaultDispatchGroupId', ['defaultDispatchGroupId'])
  .index('by_isSuperAdmin', ['isSuperAdmin'])
  .index('by_representsPhysicalLocation', ['representsPhysicalLocation'])
  .index('by_location_address', ['location.address'])
  .index('by_cityId', ['cityId'])
  .index('by_stateId', ['stateId'])
  .index('by_createdByUserDuringRequest', ['createdByUserDuringRequest'])
  .index('by_showInProviderDirectory', ['showInProviderDirectory'])
  .index('by_countryId', ['countryId'])
  // TODO: Simple to start
  .searchIndex('search', {
    searchField: 'name',
  });

/**
 * Gets a bounding box for any service area type
 */
export function getBoundingBox(
  location: z.infer<typeof locationSchema>,
  serviceArea: z.infer<typeof serviceAreaSchema>
) {
  switch (serviceArea.type) {
    case 'radius': {
      const milesPerLatDegree = 69;
      const milesPerLonDegree =
        Math.cos((location.latitude * Math.PI) / 180) * 69;

      const latDelta = serviceArea.radiusMiles / milesPerLatDegree;
      const lonDelta = serviceArea.radiusMiles / milesPerLonDegree;

      return {
        north: location.latitude + latDelta,
        south: location.latitude - latDelta,
        east: location.longitude + lonDelta,
        west: location.longitude - lonDelta,
      };
    }

    case 'polygon': {
      const lats = serviceArea.coordinates.map(c => c.latitude);
      const lons = serviceArea.coordinates.map(c => c.longitude);
      return {
        north: Math.max(...lats),
        south: Math.min(...lats),
        east: Math.max(...lons),
        west: Math.min(...lons),
      };
    }

    case 'rectangle': {
      return serviceArea.bounds;
    }
  }
}

// TODO: if using mapbox directions API ... instead of naive between 2 points distance, we can pick the recommended full path
// const MAPBOX_ACCESS_TOKEN = process.env.MAPBOX_ACCESS_TOKEN;

// type MapboxDirectionsResponse = {
//   routes: Array<{
//     distance: number; // in meters
//     duration: number; // in seconds
//     geometry: any;
//   }>;
//   waypoints: Array<{
//     location: [number, number];
//     name: string;
//   }>;
// };

// /**
//  * Calculate driving distance between two points using Mapbox Directions API
//  * @returns Distance in miles and duration in minutes
//  */
// export async function calculateDrivingDistance(
//   start: { latitude: number; longitude: number },
//   end: { latitude: number; longitude: number }
// ): Promise<{ distanceMiles: number; durationMinutes: number }> {
//   if (!MAPBOX_ACCESS_TOKEN) {
//     throw new ConvexError("Mapbox access token not configured");
//   }

//   const url = `https://api.mapbox.com/directions/v5/mapbox/driving/` +
//     `${start.longitude},${start.latitude};${end.longitude},${end.latitude}` +
//     `?access_token=${MAPBOX_ACCESS_TOKEN}` +
//     `&geometries=geojson`;

//   try {
//     const response = await fetch(url);
//     if (!response.ok) {
//       throw new Error(`Mapbox API error: ${response.statusText}`);
//     }

//     const data = (await response.json()) as MapboxDirectionsResponse;

//     if (!data.routes || data.routes.length === 0) {
//       throw new Error("No route found");
//     }

//     const route = data.routes[0];

//     return {
//       // Convert meters to miles
//       distanceMiles: route.distance / 1609.34,
//       // Convert seconds to minutes
//       durationMinutes: route.duration / 60
//     };
//   } catch (error) {
//     console.error("Error calculating driving distance:", error);
//     // Fallback to straight-line distance if API fails
//     const straightLineDistance = calculateDistance(
//       start.latitude,
//       start.longitude,
//       end.latitude,
//       end.longitude
//     );
//     return {
//       distanceMiles: straightLineDistance,
//       durationMinutes: straightLineDistance * 2 // Rough estimate
//     };
//   }
// }

// /**
//  * Check if a location is within driving distance of a service area
//  */
// export async function isWithinDrivingDistance(
//   point: { latitude: number; longitude: number },
//   serviceLocation: { latitude: number; longitude: number },
//   maxDrivingMiles: number
// ): Promise<boolean> {
//   try {
//     const { distanceMiles } = await calculateDrivingDistance(point, serviceLocation);
//     return distanceMiles <= maxDrivingMiles;
//   } catch (error) {
//     // Fallback to straight-line distance
//     const distance = calculateDistance(
//       point.latitude,
//       point.longitude,
//       serviceLocation.latitude,
//       serviceLocation.longitude
//     );
//     return distance <= maxDrivingMiles;
//   }
// }

// // Example usage in a Convex mutation or query
// export const findNearbyServiceProviders = query({
//   args: {
//     latitude: v.number(),
//     longitude: v.number(),
//     maxDrivingMiles: v.number(),
//   },
//   handler: async (ctx, args) => {
//     // First get potential matches using geospatial index with a larger radius
//     // (since driving distance is usually longer than straight-line distance)
//     const potentialMatches = await serviceAreasIndex.query(ctx, {
//       shape: {
//         type: "rectangle",
//         rectangle: {
//           north: args.latitude + (args.maxDrivingMiles / 69) * 1.5,
//           south: args.latitude - (args.maxDrivingMiles / 69) * 1.5,
//           east: args.longitude + (args.maxDrivingMiles / 69) * 1.5,
//           west: args.longitude - (args.maxDrivingMiles / 69) * 1.5
//         }
//       },
//       filter: (q) => q.eq("isActive", true),
//       limit: 50
//     });

//     // Check actual driving distances
//     const results = await Promise.all(
//       potentialMatches.results.map(async (match) => {
//         const group = await ctx.db.get(match.key);
//         if (!group?.location) return null;

//         try {
//           const { distanceMiles, durationMinutes } = await calculateDrivingDistance(
//             { latitude: args.latitude, longitude: args.longitude },
//             group.location
//           );

//           if (distanceMiles <= args.maxDrivingMiles) {
//             return {
//               ...group,
//               drivingDistance: distanceMiles,
//               estimatedDuration: durationMinutes
//             };
//           }
//           return null;
//         } catch (error) {
//           // Skip this result if we can't calculate driving distance
//           return null;
//         }
//       })
//     );

//     return results
//       .filter((r): r is NonNullable<typeof r> => r !== null)
//       .sort((a, b) => a.drivingDistance - b.drivingDistance);
//   }
// });

// // For batch processing, you might want to use the Matrix API instead
// type MapboxMatrixResponse = {
//   distances: number[][]; // in meters
//   durations: number[][]; // in seconds
// };

// /**
//  * Calculate distances between multiple points using Mapbox Matrix API
//  */
// export async function calculateBatchDistances(
//   origin: { latitude: number; longitude: number },
//   destinations: Array<{ latitude: number; longitude: number }>
// ): Promise<Array<{ distanceMiles: number; durationMinutes: number }>> {
//   if (!MAPBOX_ACCESS_TOKEN) {
//     throw new ConvexError("Mapbox access token not configured");
//   }

//   const coordinates = [
//     `${origin.longitude},${origin.latitude}`,
//     ...destinations.map(d => `${d.longitude},${d.latitude}`)
//   ].join(';');

//   const url = `https://api.mapbox.com/directions-matrix/v1/mapbox/driving/` +
//     `${coordinates}?access_token=${MAPBOX_ACCESS_TOKEN}`;

//   try {
//     const response = await fetch(url);
//     if (!response.ok) {
//       throw new Error(`Mapbox API error: ${response.statusText}`);
//     }

//     const data = (await response.json()) as MapboxMatrixResponse;

//     // First row contains distances from origin to all destinations
//     return data.distances[0].slice(1).map((distance, i) => ({
//       distanceMiles: distance / 1609.34,
//       durationMinutes: data.durations[0][i + 1] / 60
//     }));
//   } catch (error) {
//     console.error("Error calculating batch distances:", error);
//     // Fallback to straight-line distances
//     return destinations.map(dest => {
//       const distance = calculateDistance(
//         origin.latitude,
//         origin.longitude,
//         dest.latitude,
//         dest.longitude
//       );
//       return {
//         distanceMiles: distance,
//         durationMinutes: distance * 2 // Rough estimate
//       };
//     });
//   }
// }
/**
 * Calculates the distance between two points in miles
 */
export function calculateDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number {
  const R = 3963.19; // Earth's radius in miles
  const φ1 = (lat1 * Math.PI) / 180;
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

/**
 * Checks if a point is within a polygon using ray casting algorithm
 */
export function isPointInPolygon(
  point: { latitude: number; longitude: number },
  vertices: Array<{ latitude: number; longitude: number }>
): boolean {
  let inside = false;
  for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
    const xi = vertices[i].longitude;
    const yi = vertices[i].latitude;
    const xj = vertices[j].longitude;
    const yj = vertices[j].latitude;

    const intersect =
      yi > point.latitude !== yj > point.latitude &&
      point.longitude < ((xj - xi) * (point.latitude - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
}

/**
 * Checks if a point is within any type of service area
 */
export function isPointInServiceArea(
  point: { latitude: number; longitude: number },
  location: { latitude: number; longitude: number },
  serviceArea: z.infer<typeof serviceAreaSchema>
): boolean {
  switch (serviceArea.type) {
    case 'radius': {
      const distance = calculateDistance(
        point.latitude,
        point.longitude,
        location.latitude,
        location.longitude
      );
      return distance <= serviceArea.radiusMiles;
    }

    case 'polygon': {
      return isPointInPolygon(point, serviceArea.coordinates);
    }

    case 'rectangle': {
      return (
        point.latitude <= serviceArea.bounds.north &&
        point.latitude >= serviceArea.bounds.south &&
        point.longitude <= serviceArea.bounds.east &&
        point.longitude >= serviceArea.bounds.west
      );
    }
  }
}

/**
 * Gets the approximate area in square miles of a service area
 */
export function calculateServiceAreaSize(
  location: z.infer<typeof locationSchema>,
  serviceArea: z.infer<typeof serviceAreaSchema>
): number {
  switch (serviceArea.type) {
    case 'radius': {
      return Math.PI * Math.pow(serviceArea.radiusMiles, 2);
    }

    case 'polygon': {
      // Rough approximation using latitude correction
      let area = 0;
      const coords = serviceArea.coordinates;
      for (let i = 0; i < coords.length; i++) {
        const j = (i + 1) % coords.length;
        const latCorrection = Math.cos(
          (((coords[i].latitude + coords[j].latitude) / 2) * Math.PI) / 180
        );
        area +=
          (coords[j].longitude - coords[i].longitude) *
          (coords[j].latitude + coords[i].latitude) *
          latCorrection;
      }
      return (Math.abs(area) * 69 * 69) / 2; // Convert to approximate square miles
    }

    case 'rectangle': {
      const bounds = serviceArea.bounds;
      const latCorrection = Math.cos(
        (((bounds.north + bounds.south) / 2) * Math.PI) / 180
      );
      const width = (bounds.east - bounds.west) * latCorrection * 69;
      const height = (bounds.north - bounds.south) * 69;
      return width * height;
    }
  }
}

export const locationContactSchema = z.object({
  phone: z.string().optional(),
  email: z.string().email('Invalid email address').optional(),
  dispatchPhone: z.string().optional(),
  emergencyPhone: z.string().optional(),
  notes: z.string().optional(),
});

export const restrictiveLocationContactSchemaForFleetAndRepairShops =
  locationContactSchema.extend({
    dispatchPhone: z.string(),
  });
// Base schema for location creation
export const baseLocationInput = z.object({
  name: z.string().min(1, 'Company name must be at least 1 character'),
  companyId: zid('companies'),
  location: locationSchema,
  description: z.string(),
  locationType: physicalLocationType,
  representsPhysicalLocation: z.boolean().default(true),
  showInProviderDirectory: z.boolean().optional(),
  hasVerifiedDispatchTeam: z.boolean().optional(),
  hasVerifiedTechnicians: z.boolean().optional(),
  isRetail: z.boolean().optional(),
  simpleServiceAreaByMilesOut: z.number().optional(),
  defaultDispatchGroupId: zid('groups').optional(),
  parentId: zid('groups').optional(),
  contactInfo: locationContactSchema.optional(),
  acceptedPaymentMethods: z.array(paymentMethod),
});

// TODO: Refine this... we have a loooot of work to do for proper zod typings... everything is basically optional for dev speed atm but it will bite us
export const adminLocationInputForFleetAndProviderLocation = baseLocationInput;
// baseLocationInput.extend({
//   dispatchPhone: z.string(),
//   isRetail: z.boolean(),
//   contactInfo: restrictiveLocationContactSchemaForFleetAndRepairShops,
// });

// Additional fields for invitation flow
export const invitationLocationInput = baseLocationInput.extend({
  invitedByCompanyId: zid('companies').optional(),
  invitedByLocationId: zid('groups').optional(),
  invitedByUserId: zid('users').optional(),
  createdFromRequestId: zid('requests').optional(),
});

export type LocationCreationResult = {
  locationId: Id<'groups'>;
  success: boolean;
  message: string;
};
