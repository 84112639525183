import { CONFIG } from 'src/config-global';
import { MapboxFeature } from 'src/convex/schema/enums/timezones';

export const reverseGeocode = async (
  lat: number,
  long: number
): Promise<MapboxFeature | null> => {
  try {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${long},${lat}.json?access_token=${CONFIG.mapboxApiKey}&types=address`
    );
    const data = await response.json();
    return data.features?.[0] || null;
  } catch (error) {
    console.error('Reverse geocoding error:', error);
    return null;
  }
};
