import { useEffect, useState } from 'react';
import { ViewState } from 'react-map-gl';

interface Coordinates {
  latitude: number;
  longitude: number;
}

interface UseMapViewStateProps {
  coordinates: Coordinates | null;
  initialZoom?: number;
}

export const useMapViewState = ({
  coordinates,
  initialZoom = 14,
}: UseMapViewStateProps) => {
  const [viewState, setViewState] = useState<Partial<ViewState>>({
    latitude: coordinates?.latitude ?? 0,
    longitude: coordinates?.longitude ?? 0,
    zoom: initialZoom,
  });

  // Update view state when coordinates change
  useEffect(() => {
    if (coordinates) {
      setViewState(prev => ({
        ...prev,
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
      }));
    }
  }, [coordinates]);

  return {
    viewState,
    setViewState,
  };
};
