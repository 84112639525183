import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useScheduledActions } from 'src/hooks/useScheduledActions';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { InviteLocationViaRequestDialog } from 'src/sections/location/location-new-request';
import { RequestActionProps } from 'src/sections/request/types/RequestActionProps';
import { InviteUserViaRequestDialog } from 'src/sections/request/view/InviteUserViaRequestDialog';
import { RequestFindServiceProvider } from 'src/sections/request/view/request-find-service-provider';
import { InvitingUser } from 'src/sections/request/view/request-step-information';

export const FindServiceProviderAction = ({
  request,
  me,
  requestStateInformation,
}: RequestActionProps) => {
  const [isInvitingLocation, setIsInvitingLocation] = useState<boolean>(false);
  const [isFindingAServiceProvider, setIsFindingAServiceProvider] =
    useState<boolean>(false);
  const [userInvitation, setUserInvitation] = useState<InvitingUser | null>(
    null
  );

  const { watchIds } = useScheduledActions();
  const { watchIds: watchUserInviteActionIds } = useScheduledActions();

  const {
    isAssignedToMe,
    isFleetDispatcher,
    needsServiceProvider,
    isThirdPartyDispatcher,
  } = requestStateInformation;

  const isVisible = needsServiceProvider;
  const isEnabled =
    isAssignedToMe && (isFleetDispatcher || isThirdPartyDispatcher);

  const handleFindAServiceProvider = () => {
    setIsFindingAServiceProvider(true);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <LoadingButton
        onClick={handleFindAServiceProvider}
        fullWidth
        sx={{ height: '100%' }}
        color="primary"
        variant="contained"
        disabled={!isEnabled || isFindingAServiceProvider}
        startIcon={
          <Iconify icon="solar:map-point-search-bold-duotone" width={40} />
        }
      >
        {isEnabled ? 'Find Service Provider' : 'Waiting On Fleet Dispatch'}
      </LoadingButton>
      <InviteLocationViaRequestDialog
        open={isInvitingLocation}
        onClose={() => setIsInvitingLocation(false)}
        watchIds={watchIds}
      />
      <RequestFindServiceProvider
        open={isFindingAServiceProvider}
        needToFindAServiceProvider={isEnabled}
        onClose={() => setIsFindingAServiceProvider(false)}
        inviteLocation={() => setIsInvitingLocation(true)}
        setUserInvitation={setUserInvitation}
      />
      {!!userInvitation?.selectedServiceProvider && (
        <InviteUserViaRequestDialog
          open={!!userInvitation?.dialogOpen}
          onClose={() => setUserInvitation(null)}
          selectedServiceProvider={userInvitation.selectedServiceProvider}
          watchIds={watchUserInviteActionIds}
        />
      )}
    </>
  );
};
