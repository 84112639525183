import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { useQuery } from 'convex/react';
import { useCallback } from 'react';
import { api } from 'src/convex/_generated/api';
import { Doc } from 'src/convex/_generated/dataModel';
import { ConfirmDialog } from 'src/minimal-theme/components/custom-dialog';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Label } from 'src/minimal-theme/components/label';
import { RouterLink } from 'src/minimal-theme/components/routes/components';
import { Scrollbar } from 'src/minimal-theme/components/scrollbar';
import {
  emptyRows,
  getComparator,
  rowInPage,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/minimal-theme/components/table';
import { useBoolean } from 'src/minimal-theme/hooks/use-boolean';
import { useSetState } from 'src/minimal-theme/hooks/use-set-state';
import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';
import { varAlpha } from 'src/minimal-theme/theme/styles';
import type {
  CompanyStatusOption,
  CompanyTableFilters,
} from 'src/types/company';
import { CompanyTableFiltersResult } from '../company-table-filters-result';
import { CompanyTableRow } from '../company-table-row';
import { CompanyTableToolbar } from '../company-table-toolbar';

const STATUS_OPTIONS = [
  { value: 'all' as const, label: 'All' },
  { value: 'incomplete' as const, label: 'Incomplete Representatives' },
];

const TABLE_HEAD = [
  { id: 'name', label: 'Company', width: 250 },
  { id: 'companyType', label: 'Company Type' },
  { id: 'isSignUpFormCompleted', label: 'Completed Sign Up' },
  { id: 'ownerId', label: 'Owner' },
  { id: 'actions', label: 'Actions' },
];

export function CompanyListView() {
  const table = useTable();

  const confirm = useBoolean();

  const me = useQuery(api.functions.users.getMe);
  const isSuperAdmin = me?.clerkUser.isSuperAdmin;
  const isImpersonating = me?.impersonatingCompanyId;

  const tableData = useQuery(api.functions.companies.getCompanies, {});

  const filters = useSetState<CompanyTableFilters>({
    search: '',
    status: 'all',
  });

  const dataFiltered = applyFilter({
    inputData: tableData ?? [],
    comparator: getComparator(table.order, table.orderBy),
    filters: filters.state,
  });

  const dataInPage = rowInPage(dataFiltered, table.page, table.rowsPerPage);

  const canReset = !!filters.state.search;

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleDeleteRow = useCallback((id: string) => {
    console.log('delete', id);
  }, []);

  const handleEditRow = useCallback((id: string) => {
    console.log('edit', id);
  }, []);

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: CompanyStatusOption) => {
      table.onResetPage();
      filters.setState({ status: newValue });
    },
    [filters, table]
  );

  const getStatusCounts = useCallback(() => {
    if (!tableData) return { all: 0, incomplete: 0 };

    const incomplete = tableData.filter(company => {
      if (!company.accountExecutiveId || !company.customerSuccessRepId) {
        return true;
      } else {
        return false;
      }
    }).length;

    return {
      all: tableData.length,
      incomplete,
    };
  }, [tableData]);

  const statusCounts = getStatusCounts();

  return (
    <>
      <DashboardContent>
        {isSuperAdmin && !isImpersonating && (
          <Button
            component={RouterLink}
            href={'/dashboard/companies/new'}
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
            sx={{ width: 'fit-content', mb: 2, ml: 'auto' }}
          >
            Add Company
          </Button>
        )}
        <Card>
          {isSuperAdmin && !isImpersonating && (
            <Tabs
              value={filters.state.status}
              onChange={handleFilterStatus}
              sx={{
                px: 2.5,
                boxShadow: theme =>
                  `inset 0 -2px 0 0 ${varAlpha(theme.vars.palette.grey['500Channel'], 0.08)}`,
              }}
            >
              {STATUS_OPTIONS.map(tab => (
                <Tab
                  key={tab.value}
                  iconPosition="end"
                  value={tab.value}
                  label={tab.label}
                  icon={
                    <Label
                      variant={
                        tab.value === filters.state.status ? 'filled' : 'soft'
                      }
                      color={tab.value === 'incomplete' ? 'error' : 'default'}
                    >
                      {statusCounts[tab.value]}
                    </Label>
                  }
                />
              ))}
            </Tabs>
          )}
          {isSuperAdmin && !isImpersonating && (
            <CompanyTableToolbar
              filters={filters}
              onResetPage={table.onResetPage}
            />
          )}

          {canReset && (
            <CompanyTableFiltersResult
              filters={filters}
              totalResults={dataFiltered.length}
              onResetPage={table.onResetPage}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <Box sx={{ position: 'relative' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={dataFiltered.length}
              onSelectAllRows={checked =>
                table.onSelectAllRows(
                  checked,
                  dataFiltered.map(row => row._id)
                )
              }
            />

            <Scrollbar>
              <Table
                size={table.dense ? 'small' : 'medium'}
                sx={{ minWidth: 960 }}
              >
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataFiltered.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={checked =>
                    table.onSelectAllRows(
                      checked,
                      dataFiltered.map(row => row._id)
                    )
                  }
                />

                <TableBody>
                  {dataFiltered
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map(row => (
                      <CompanyTableRow
                        key={row._id}
                        row={row}
                        selected={table.selected.includes(row._id)}
                        onSelectRow={() => table.onSelectRow(row._id)}
                        onDeleteRow={() => handleDeleteRow(row._id)}
                        onEditRow={() => handleEditRow(row._id)}
                      />
                    ))}

                  <TableEmptyRows
                    height={table.dense ? 56 : 56 + 20}
                    emptyRows={emptyRows(
                      table.page,
                      table.rowsPerPage,
                      dataFiltered.length
                    )}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </Box>

          <TablePaginationCustom
            page={table.page}
            dense={table.dense}
            count={dataFiltered.length}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onChangeDense={table.onChangeDense}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        </Card>
      </DashboardContent>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete{' '}
            <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              // handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

type ApplyFilterProps = {
  inputData: Doc<'companies'>[];
  filters: CompanyTableFilters;
  comparator: (a: any, b: any) => number;
};

function applyFilter({ inputData, comparator, filters }: ApplyFilterProps) {
  const { search, status } = filters;

  let filteredData = [...inputData];

  // Apply status filter
  if (status === 'incomplete') {
    filteredData = filteredData.filter(
      company => !company.accountExecutiveId || !company.customerSuccessRepId
    );
  }

  // Apply search filter
  if (search) {
    filteredData = filteredData.filter(
      company => company.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  }

  // Sort the filtered data
  const stabilizedThis = filteredData.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map(el => el[0]);
}
