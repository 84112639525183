import { createFileRoute, notFound } from '@tanstack/react-router';
import Page from 'src/pages/dashboard/invoice/new';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute('/_auth/dashboard/invoices/new')({
  beforeLoad: ({ context }) => {
    throw notFound(); // TODO: temporary disablement of invoices to merge
    checkRouteAccess(context, routeAccessConfigs.dispatcherGeneral);
  },
  component: Page,
});
