import { openDB } from 'idb';
import JSZip from 'jszip';
import { Id } from 'src/convex/_generated/dataModel';
import {
  CACHE_DB_NAME,
  CACHE_VERSION,
} from 'src/sections/request/hooks/useRequestImages';

function getFileExtensionFromBlob(blob: Blob): string {
  const mimeToExt: Record<string, string> = {
    'image/jpeg': '.jpg',
    'image/png': '.png',
    'image/webp': '.webp',
    'image/heic': '.heic',
  };
  return mimeToExt[blob.type] || '.jpg'; // Fallback
}

async function getImageBlob(
  imageId: Id<'requestImages'>,
  url: string
): Promise<Blob> {
  const db = await openDB(CACHE_DB_NAME, CACHE_VERSION);

  // Try cache first
  const cached = await db.get('images', imageId);
  if (cached?.blob) {
    return cached.blob;
  }

  // Fallback to fetch
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Failed to fetch image');
  }
  return response.blob();
}

export async function downloadImage(
  imageId: Id<'requestImages'>,
  url: string,
  baseFilename: string
) {
  const blob = await getImageBlob(imageId, url);
  const ext = getFileExtensionFromBlob(blob);
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = `${baseFilename}${ext}`;
  link.click();
  URL.revokeObjectURL(blobUrl);
}

export async function downloadImagesAsZip(
  images: { id: Id<'requestImages'>; url: string; baseFilename: string }[],
  caseNumber: string
) {
  const zip = new JSZip();

  await Promise.all(
    images.map(async ({ id, url, baseFilename }) => {
      const blob = await getImageBlob(id, url);
      const ext = getFileExtensionFromBlob(blob);
      zip.file(`${baseFilename}${ext}`, blob);
    })
  );

  const content = await zip.generateAsync({ type: 'blob' });
  const zipUrl = URL.createObjectURL(content);
  const link = document.createElement('a');
  link.href = zipUrl;
  link.download = `myMechanic-${caseNumber}-photos.zip`;
  link.click();
  URL.revokeObjectURL(zipUrl);
}
