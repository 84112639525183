import { EnhancedRequest } from 'src/convex/functions/requests';
import { EnhancedUser } from 'src/convex/functions/users';

export const getRequestStateInformation = (
  request: EnhancedRequest,
  me: EnhancedUser
): RequestStateInformation => {
  // Basic role checks
  const isAssignedToMe = request.currentAssignedToId === me._id;
  const isFleetDispatcher = me.roles.some(r => r?.type === 'FLEET_DISPATCHER');
  const isServiceDispatcher = me.roles.some(
    r => r?.type === 'SERVICE_DISPATCHER'
  );
  const isTechnician = me.roles.some(r => r?.type === 'TECHNICIAN_PROVIDER');
  const isThirdPartyDispatcher = me.roles.some(
    r => r?.type === 'THIRD_PARTY_DISPATCHER'
  );

  const isDriver = me.roles.some(r => r?.type === 'DRIVER_FLEET');

  const canEditChatAndPhotos =
    (isFleetDispatcher && me._id === request.activeFleetDispatcherId) ||
    (isServiceDispatcher && me._id === request.activeServiceDispatcherId) ||
    (isThirdPartyDispatcher &&
      me._id === request.activeBrokerageDispatcherId) ||
    (isDriver && me._id === request.activeDriverId) ||
    (isTechnician && me._id === request.activeTechnicianId);

  // Queue states
  const isFleetDispatchQueue =
    request.currentStepState === 'QUEUED' &&
    request.currentStepType === 'DISPATCH_TRIAGE';

  const isServiceProviderQueue =
    request.currentStepState === 'QUEUED' &&
    request.currentStepType === 'WITH_SERVICE_PROVIDER_DISPATCH';

  const isWaitingForTechnician =
    request.currentStepState === 'ASSIGNED' &&
    request.currentStepType === 'TECHNICIAN_ASSIGNED';

  // Action states
  const needsServiceProvider =
    request.currentStepState === 'ASSIGNED' &&
    request.currentStepType === 'DISPATCH_TRIAGE';

  const needsMechanic =
    request.currentStepState === 'ASSIGNED' &&
    request.currentStepType === 'WITH_SERVICE_PROVIDER_DISPATCH';

  const needsLocationConfirmation =
    request.currentStepState === 'ASSIGNED' &&
    request.currentStepType === 'DRIVER_CONFIRM_LOCATION';

  // Technician workflow states
  const isTechnicianAccepted =
    request.currentStepType === 'TECHNICIAN_ACCEPTED';

  const isTechnicianArrived = request.currentStepType === 'TECHNICIAN_ARRIVED';

  const isTechnicianWorking =
    request.currentStepType === 'TECHNICIAN_STARTED_WORK';

  const needsVerification =
    request.currentStepType === 'COMPLETION_VERIFICATION';

  return {
    // Role states
    isAssignedToMe,
    isFleetDispatcher,
    isServiceDispatcher,
    isTechnician,
    isThirdPartyDispatcher,
    isDriver,

    canEditChatAndPhotos,

    // Queue states
    isFleetDispatchQueue,
    isServiceProviderQueue,

    // Assigned and awaiting acceptance
    isWaitingForTechnician,

    // Action states
    needsServiceProvider,
    needsMechanic,
    needsLocationConfirmation,

    // Technician states
    isTechnicianAccepted,
    isTechnicianArrived,
    isTechnicianWorking,
    needsVerification,
  };
};

export type RequestStateInformation = {
  // Role states
  isAssignedToMe: boolean;
  isFleetDispatcher: boolean;
  isServiceDispatcher: boolean;
  isTechnician: boolean;
  isDriver: boolean;
  isThirdPartyDispatcher: boolean;

  canEditChatAndPhotos: boolean;

  // Queue states
  isFleetDispatchQueue: boolean;
  isServiceProviderQueue: boolean;

  // Assigned and awaiting acceptance
  isWaitingForTechnician: boolean;

  // Action states
  needsServiceProvider: boolean;
  needsMechanic: boolean;
  needsLocationConfirmation: boolean;

  // Technician states
  isTechnicianAccepted: boolean;
  isTechnicianArrived: boolean;
  isTechnicianWorking: boolean;
  needsVerification: boolean;
};
