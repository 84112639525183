import { api } from '@api';
import {
  Alert,
  AlertColor,
  Box,
  Button,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  Tooltip,
} from '@mui/material';
import { useQuery } from 'convex/react';

import { useParams } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Id } from 'src/convex/_generated/dataModel';
import { ServiceProviderResult } from 'src/convex/functions/providers';
import { getStepTypeDisplay } from 'src/convex/schema/entities/requests/requests';
import { usePopover } from 'src/minimal-theme/components/custom-popover';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { AcceptRequestAction } from 'src/sections/request/actions/AcceptRequestAction';
import { ArrivedAtLocationAction } from 'src/sections/request/actions/ArrivedAtLocationAction';
import { CountdownDisplay } from 'src/sections/request/actions/CountdownDisplay';
import { DeclineAssignmentAction } from 'src/sections/request/actions/DeclineAssignmentAction';
import { DeclineRequestAction } from 'src/sections/request/actions/DeclineRequestAction';
import { DriverConfirmLocationAction } from 'src/sections/request/actions/DriverConfirmLocationAction';
import { ElapsedDisplay } from 'src/sections/request/actions/ElapsedDisplay';
import { FindMechanicAction } from 'src/sections/request/actions/FindMechanicAction';
import { FindServiceProviderAction } from 'src/sections/request/actions/FindServiceProviderAction';
import { TechnicianCompleteWorkAction } from 'src/sections/request/actions/TechnicianCompleteWorkAction';
import { TechnicianStartWorkAction } from 'src/sections/request/actions/TechnicianStartWorkAction';
import { getRequestStateInformation } from 'src/sections/request/actions/utils/getRequestStateInformation';
import { VerifyWorkCompletionAction } from 'src/sections/request/actions/VerifyWorkCompletionAction';
import { RequestFormWrapper } from 'src/sections/request/RequestFormWrapper';
import { CancelRequestDialog } from 'src/sections/request/view/CancelRequestDialog';
import { getRequestStatusColor } from 'src/utils/helper';
import { NavigateToLocationAction } from '../actions/NavigateToLocationAction';

export type InvitingUser = {
  dialogOpen: boolean;
  selectedServiceProvider: ServiceProviderResult;
};

export const RequestStepInformation = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false);
  const params = useParams({ from: '/_auth/dashboard/requests/$requestId' });

  const popover = usePopover();

  const request = useQuery(api.functions.requests.getRequest, {
    requestId: params.requestId as Id<'requests'>,
  });

  const me = useQuery(api.functions.users.getMe);

  if (!request || !me) {
    return null;
  }

  const requestStateInformation = getRequestStateInformation(request, me);

  const { isFleetDispatcher, isServiceDispatcher, isThirdPartyDispatcher } =
    requestStateInformation;

  // TODO: Need to add logic to determine if user can edit request

  const canEdit =
    (isFleetDispatcher && me._id === request.activeFleetDispatcherId) ||
    (isServiceDispatcher && me._id === request.activeServiceDispatcherId) ||
    (isThirdPartyDispatcher && me._id === request.activeBrokerageDispatcherId);

  const actionProps = { request, me, requestStateInformation };

  return (
    <Stack width="100%" spacing={2}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} width="100%">
        <Stack width={{ xs: '100%', md: '70%', lg: '60%' }} spacing={2}>
          {request.currentStepType && (
            <Alert
              sx={{ width: '100%', fontWeight: 600 }}
              variant="outlined"
              severity={getRequestStatusColor(request.status) as AlertColor}
            >
              {getStepTypeDisplay(request.currentStepType)}
            </Alert>
          )}
        </Stack>
        <Stack
          width={{ xs: '100%', md: '30%', lg: '40%' }}
          spacing={1}
          direction="row"
          sx={{ justifyContent: 'center' }}
        >
          <Tooltip
            title={canEdit ? '' : 'Only participating dispatchers can edit'}
            placement="top"
            sx={{ fontWeight: 700, fontSize: '1rem' }}
          >
            <Box sx={{ width: '100%', height: '100%' }}>
              <Button
                variant="contained"
                fullWidth
                sx={{ width: '100%', height: '100%' }}
                size="large"
                disabled={!canEdit}
                onClick={() => setIsEditDialogOpen(true)}
                startIcon={
                  <Iconify icon="solar:pen-new-round-bold-duotone" width={24} />
                }
              >
                Edit Details
              </Button>
            </Box>
          </Tooltip>
          {canEdit && (
            <Tooltip
              title={'Other Actions'}
              placement="top"
              sx={{ fontWeight: 700, fontSize: '1rem' }}
            >
              <Box sx={{ height: '100%' }}>
                <Button
                  variant="contained"
                  onClick={popover.onOpen}
                  fullWidth
                  sx={{ width: '100%', height: '100%' }}
                >
                  <Iconify icon="solar:menu-dots-square-broken" width={24} />
                </Button>
              </Box>
            </Tooltip>
          )}
          <Popover
            open={popover.open}
            anchorEl={popover.anchorEl}
            onClose={() => {
              popover.onClose();
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <MenuList>
              <MenuItem
                onClick={() => {
                  popover.onClose();
                  setIsCancelDialogOpen(true);
                }}
              >
                <Iconify icon="mingcute:close-line" />
                <ListItemText
                  primary="Cancel Request"
                  primaryTypographyProps={{
                    noWrap: true,
                    typography: 'body2',
                  }}
                  secondaryTypographyProps={{ typography: 'caption' }}
                />
              </MenuItem>
            </MenuList>
          </Popover>
        </Stack>
      </Stack>
      {request.status !== 'COMPLETED' && request.status !== 'CANCELLED' && (
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} width="100%">
          {/* Primary Action Buttons */}
          <Box width={{ xs: '100%', md: '70%', lg: '60%' }}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              sx={{ height: '100%' }}
            >
              <AcceptRequestAction {...actionProps} />
              <DeclineRequestAction {...actionProps} />
              <DeclineAssignmentAction {...actionProps} />
              <DriverConfirmLocationAction {...actionProps} />
              <FindServiceProviderAction {...actionProps} />
              <FindMechanicAction {...actionProps} />
              <NavigateToLocationAction {...actionProps} />
              <ArrivedAtLocationAction {...actionProps} />
              <TechnicianStartWorkAction {...actionProps} />
              <TechnicianCompleteWorkAction {...actionProps} />
              <VerifyWorkCompletionAction {...actionProps} />
            </Stack>
          </Box>

          <Stack width={{ xs: '100%', md: '30%', lg: '40%' }} direction="row">
            {requestStateInformation.isServiceProviderQueue ? (
              <CountdownDisplay
                heading="Time Until Returned To Customer"
                startTimestamp={dayjs(request.currentStepStartedAt).valueOf()}
              />
            ) : (
              <ElapsedDisplay
                heading="Time Since Last Update"
                startTimestamp={dayjs(request.currentStepStartedAt).valueOf()}
              />
            )}
          </Stack>
        </Stack>
      )}
      <RequestFormWrapper
        request={request}
        me={me}
        isDialog
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
      />
      <CancelRequestDialog
        open={isCancelDialogOpen}
        onClose={() => setIsCancelDialogOpen(false)}
        request={request}
      />
    </Stack>
  );
};
