import { RequestActionProps } from 'src/sections/request/types/RequestActionProps';

export const getDeclineRequestActionState = ({
  requestStateInformation,
}: RequestActionProps) => {
  const { isServiceProviderQueue, isServiceDispatcher } =
    requestStateInformation;

  const isVisible = isServiceProviderQueue && isServiceDispatcher;

  if (!isVisible) {
    return {
      isVisible: false,
      isEnabled: false,
      message: '',
    };
  }

  const declineMessage = 'Decline Request';
  const canPerform = isServiceDispatcher;

  return {
    isVisible: true,
    isEnabled: canPerform,
    message: canPerform ? declineMessage : `Waiting On Service Dispatcher`,
    confirmationTitle: declineMessage,
    confirmationMessage: 'Are you sure you want to decline this request?',
  };
};
