import { Doc } from '../../../_generated/dataModel';

export function getFleetDriverDraftInitiationFields(
  user: Doc<'users'>,
  primaryLocation: Doc<'groups'>
) {
  return {
    driverGroupId: primaryLocation._id,
    fleetDispatchGroupId: primaryLocation.defaultDispatchGroupId,
    activeDriverId: user._id,
  };
}
