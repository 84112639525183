export const getCurrentPosition = (): Promise<GeolocationPosition> => {
  if (!navigator.geolocation) {
    return Promise.reject(
      new Error('Geolocation is not supported by your browser')
    );
  }

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
};
