import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect, useMemo, useState } from 'react';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const FIVE_MINUTES = 5 * 60;

type ColorState = '#77ed8b' | '#ffab00' | '#ff5600';

export const useCountdownTimer = (
  startTimestamp: number,
  totalSeconds: number = FIVE_MINUTES,
  warningThreshold: number = 0.5,
  dangerThreshold: number = 0.25
): [string, ColorState] => {
  const [timeRemaining, setTimeRemaining] = useState<number>(totalSeconds);
  const [color, setColor] = useState<ColorState>('#77ed8b');

  useEffect(() => {
    const calculateRemaining = () => {
      const elapsed = dayjs().diff(dayjs(startTimestamp), 'second');
      const remaining = Math.max(0, totalSeconds - elapsed);
      setTimeRemaining(remaining);

      const percentageLeft = remaining / totalSeconds;
      if (percentageLeft <= dangerThreshold) {
        setColor('#ff5600');
      } else if (percentageLeft <= warningThreshold) {
        setColor('#ffab00');
      }
    };

    calculateRemaining();
    const interval = setInterval(calculateRemaining, 1000);
    return () => clearInterval(interval);
  }, [startTimestamp, totalSeconds, warningThreshold, dangerThreshold]);

  const displayText = useMemo(() => {
    if (timeRemaining <= 0) return 'Time expired';

    const duration = dayjs.duration(timeRemaining * 1000);
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    if (hours >= 1) {
      return `${hours} hours, ${minutes} minutes`;
    }

    if (minutes >= 1) {
      return `${minutes} minutes, ${seconds} seconds`;
    }

    return `${seconds} seconds`;
  }, [timeRemaining]);

  return [displayText, color];
};
