import { ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { useCountdownTimer } from 'src/sections/request/actions/hooks/useCountdownTimer';

type CountdownDisplayProps = {
  startTimestamp: number;
  heading: string;
  totalSeconds?: number;
};

export const CountdownDisplay = ({
  startTimestamp,
  heading,
  totalSeconds,
}: CountdownDisplayProps) => {
  const [timeDisplay, color] = useCountdownTimer(startTimestamp, totalSeconds);

  return (
    <Stack
      sx={{
        p: 2,
        borderRadius: 1,
        bgcolor: 'rgb(28, 37, 46)',
        color: 'white',
        width: '100%',
        height: '100%',
      }}
      justifyContent="center"
    >
      <ListItem sx={{ p: 0 }}>
        <ListItemIcon>
          <Iconify icon="solar:clock-circle-line-duotone" width={30} />
        </ListItemIcon>
        <ListItemText
          secondaryTypographyProps={{
            sx: { color, fontWeight: 800 },
          }}
          primary={heading}
          secondary={timeDisplay}
        />
      </ListItem>
    </Stack>
  );
};
