// hooks/useMapViewPermissions.ts
import { useQuery } from 'convex/react';
import { useMemo } from 'react';
import { api } from '../../../../convex/_generated/api';

export function useMapViewPermissions() {
  const me = useQuery(api.functions.users.getMe);

  const companyId = me?.impersonatingCompanyId ?? me?.companyId;
  const company = useQuery(
    api.functions.companies.getCompany,
    companyId ? { companyId } : 'skip'
  );

  // True if super admin in global view mode
  const isGlobalSuperAdmin = useMemo(
    () => me?.clerkUser.isSuperAdmin && !me?.impersonatingCompanyId,
    [me]
  );

  const shouldShowServiceLocations = useMemo(() => {
    if (!me) return false;

    // Super admin not impersonating sees all
    if (isGlobalSuperAdmin) return true;

    // When impersonating or regular user, check company type
    return (
      me.primaryRoleType === 'SERVICE_DISPATCHER' ||
      company?.companyType === 'REPAIR_SHOP' ||
      company?.companyType === 'TOWING'
    );
  }, [me, company, isGlobalSuperAdmin]);

  const shouldShowFleetLocations = useMemo(() => {
    if (!me) return false;

    if (isGlobalSuperAdmin) return true;

    return (
      me.primaryRoleType === 'FLEET_DISPATCHER' ||
      company?.companyType === 'FLEET'
    );
  }, [me, company, isGlobalSuperAdmin]);

  const shouldShowThirdPartyLocations = useMemo(() => {
    if (!me) return false;

    if (isGlobalSuperAdmin) return true;

    return (
      me.primaryRoleType === 'THIRD_PARTY_DISPATCHER' ||
      company?.companyType === 'THIRD_PARTY'
    );
  }, [me, company, isGlobalSuperAdmin]);

  return {
    shouldShowServiceLocations,
    shouldShowFleetLocations,
    shouldShowThirdPartyLocations,
  };
}
