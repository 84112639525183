import { createFileRoute, redirect } from '@tanstack/react-router';
import { AuthenticatedRoot } from 'src/components/routing/AuthenticatedRoot';
import { LoadingScreen } from 'src/minimal-theme/components/loading-screen';

/** Checks for basic authentication, i.e. is the user authenticated, sub _pathless_routes will take care of more granular checks */
export const Route = createFileRoute('/_auth')({
  beforeLoad: async ({ context: { convex, isAuthenticated, user, me } }) => {
    if (!isAuthenticated) {
      throw redirect({
        to: '/sign-in',
        search: {
          redirect: location.href,
        },
      });
    } else {
      // Check we have both Clerk user and Convex user
      if (!user || !me) {
        return false;
      }

      return true;
    }

    // const { isFullyVerified, isSignUpFormCompleted } = company;

    // if (companyOwner && !isSignUpFormCompleted) {
    //   // TODO: Might need to make this a bit nicer...
    //   throw redirect({
    //     to: '/sign-up-final',
    //   });
    // } else if (companyOwner && isSignUpFormCompleted && !isFullyVerified) {
    //   throw redirect({
    //     to: '/company-verification',
    //   });
    // } else if (!isFullyVerified) {
    //   // TODO: RF - if this route is even possible for a user to get to (ideally not).. do something nicer
    //   throw notFound();
    // }
  },
  pendingComponent: () => <LoadingScreen />,
  component: AuthenticatedRoot,
});
