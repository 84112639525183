import { ReactMutation } from 'convex/react';
import { FunctionReference } from 'convex/server';
import { debounce } from 'lodash-es';
import { useCallback } from 'react';

const MIN_DEBOUNCE_MS = 100; // Minimum allowed debounce delay
const DEFAULT_DEBOUNCE_MS = 500;

/**
 * Creates a debounced version of a Convex mutation while preserving its type information
 *
 * @param mutation - The Convex mutation to debounce
 * @param delay - Optional delay in milliseconds (minimum 100ms, default 500ms)
 * @returns A debounced version of the mutation that maintains original typings
 *
 * @example
 * const updateDraft = useMutation(api.drafts.update);
 * const debouncedUpdate = useDebouncedMutation(updateDraft);
 *
 * // Types are preserved
 * debouncedUpdate({ id: '123', content: 'new content' });
 */
export function useDebouncedMutation<
  F extends FunctionReference<'mutation', 'public'>,
>(mutation: ReactMutation<F>, delay: number = DEFAULT_DEBOUNCE_MS) {
  // Ensure minimum debounce delay
  const safeDelay = Math.max(MIN_DEBOUNCE_MS, delay);

  return useCallback(
    debounce(
      (args: Parameters<typeof mutation>[0]) => mutation(args),
      safeDelay
    ),
    [mutation, safeDelay]
  );
}
