import {
  Avatar,
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useQuery } from 'convex/react';
import { useMemo, useState } from 'react';
import { Layer, Source, type LngLatBoundsLike } from 'react-map-gl';
import { api } from 'src/convex/_generated/api';
import { Doc } from 'src/convex/_generated/dataModel';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Logo } from 'src/minimal-theme/components/logo';
import {
  Map,
  MapControl,
  MapMarker,
  MapPopup,
} from 'src/minimal-theme/components/map';
import { MuiTypeSafeLink } from 'src/minimal-theme/components/routes/components/mui-link';
import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';
import { useMapViewPermissions } from 'src/sections/map/view/hooks/useMapViewPermissions';
import {
  getBadgeVariant,
  getStatusColor,
  getStatusLabel,
} from 'src/utils/helper';

const US_BOUNDS: LngLatBoundsLike = [
  [-124.848974, 24.396308], // Southwest coordinates
  [-66.885444, 49.384358], // Northeast coordinates
];

interface MapViewProps {
  data: {
    companies: Doc<'companies'>[];
    serviceLocations: Doc<'groups'>[];
    fleetLocations: Doc<'groups'>[];
    thirdPartyLocations: Doc<'groups'>[];
    technicians: Doc<'users'>[];
    vehicles: Doc<'vehicles'>[];
    activeRequests: Doc<'requests'>[];
  };
  mapStyle: string;
}

interface MapViewData {
  companies: Doc<'companies'>[];
  serviceLocations: Doc<'groups'>[];
  fleetLocations: Doc<'groups'>[];
  thirdPartyLocations: Doc<'groups'>[];
  technicians: Doc<'users'>[];
  vehicles: Doc<'vehicles'>[];
  activeRequests: Doc<'requests'>[];
}

// Type guard to check if an item has valid location data
function hasValidLocation(item: any): boolean {
  return (
    !!item?.location?.latitude &&
    !!item?.location?.longitude &&
    typeof item.location.latitude === 'number' &&
    typeof item.location.longitude === 'number'
  );
}

type LegendFilters = {
  companies: boolean;
  serviceLocations: boolean;
  fleetLocations: boolean;
  thirdPartyLocations: boolean;
  technicians: boolean;
  vehicles: boolean;
  activeRequests: boolean;
};

export function MapView({ data, mapStyle }: MapViewProps) {
  const [popupInfo, setPopupInfo] = useState<PopupInfo | null>(null);

  console.log('data', data);

  const me = useQuery(api.functions.users.getMe);

  const {
    shouldShowServiceLocations,
    shouldShowFleetLocations,
    shouldShowThirdPartyLocations,
  } = useMapViewPermissions();

  // TODO: Not sure if it makes sense to show "companies" as many of them might not have an HQ address
  // We can have something like... filter locations by company, though
  // Select a dispatcher and see the locations they manage, etc.
  const [legendFilters, setLegendFilters] = useState<LegendFilters>({
    companies: false,
    serviceLocations: true,
    fleetLocations: true,
    technicians: true,
    thirdPartyLocations: true,
    vehicles: true,
    activeRequests: true,
  });

  const TYPE_COLORS: Record<
    keyof MapViewData,
    'primary' | 'success' | 'secondary' | 'info' | 'warning' | 'error'
  > = {
    technicians: 'info',
    vehicles: 'success',
    companies: 'secondary',
    serviceLocations: 'primary',
    fleetLocations: 'warning',
    thirdPartyLocations: 'warning',
    activeRequests: 'error',
  };

  const filteredData: MapViewData = {
    ...data,
    technicians: legendFilters.technicians ? [...data.technicians] : [],
    vehicles: legendFilters.vehicles ? [...data.vehicles] : [],
    companies: legendFilters.companies ? [...data.companies] : [],
    serviceLocations: legendFilters.serviceLocations
      ? [...data.serviceLocations]
      : [],
    fleetLocations: legendFilters.fleetLocations
      ? [...data.fleetLocations]
      : [],
    thirdPartyLocations: legendFilters.thirdPartyLocations
      ? [...data.thirdPartyLocations]
      : [],
    activeRequests: legendFilters.activeRequests
      ? data.activeRequests.map(request => ({
          ...request,
          location: {
            latitude: request.latitude,
            longitude: request.longitude,
          },
        }))
      : [],
  };

  const bounds = useMemo(() => {
    const allLocations = Object.values(filteredData)
      .flat()
      .filter(hasValidLocation);

    if (allLocations.length === 0) {
      return US_BOUNDS;
    }

    const lngs = allLocations.map(item => item.location!.longitude);
    const lats = allLocations.map(item => item.location!.latitude);

    return [
      [Math.min(...lngs) - 0.1, Math.min(...lats) - 0.1],
      [Math.max(...lngs) + 0.1, Math.max(...lats) + 0.1],
    ] as LngLatBoundsLike;
  }, [filteredData]);

  const serviceAreasData = useMemo(() => {
    // Only include service locations that have service areas
    if (!filteredData.serviceLocations?.length) return null;

    const locations = filteredData.serviceLocations.filter(hasValidLocation);

    const features = locations.map(item => {
      const radius = item.simpleServiceAreaByMilesOut || 25; // Default 25 mile radius
      const circlePoints = createCirclePoints(
        item.location!.longitude,
        item.location!.latitude,
        radius
      );

      return {
        type: 'Feature' as const,
        properties: {
          color: '#1976d2',
          radius: radius,
        },
        geometry: {
          type: 'Polygon' as const,
          coordinates: [circlePoints],
        },
      };
    });

    return {
      type: 'FeatureCollection' as const,
      features,
    };
  }, [filteredData]);

  const currentDriverAssignments = useQuery(
    api.functions.driverHistory.getCurrentDriverAssignments,
    popupInfo?.type === 'vehicles'
      ? { vehicleId: (popupInfo.item as Doc<'vehicles'>)._id }
      : 'skip'
  );

  return (
    <DashboardContent>
      <Card sx={{ height: '85vh' }}>
        <Map
          initialViewState={{
            bounds: bounds,
            fitBoundsOptions: { padding: 100 },
          }}
          mapStyle={mapStyle}
        >
          {serviceAreasData && (
            <Source type="geojson" data={serviceAreasData}>
              <Layer
                id="service-areas"
                type="fill"
                paint={{
                  'fill-color': ['get', 'color'],
                  'fill-opacity': 0.15,
                  'fill-outline-color': ['get', 'color'],
                }}
              />
              <Layer
                id="service-areas-outline"
                type="line"
                paint={{
                  'line-color': ['get', 'color'],
                  'line-opacity': 0.3,
                  'line-width': 1,
                }}
              />
            </Source>
          )}
          <MapControl />

          {(Object.entries(filteredData) as [keyof MapViewData, any[]][]).map(
            ([type, items]) =>
              items?.filter(hasValidLocation).map(item => (
                <MapMarker
                  key={`marker-${type}-${item._id}`}
                  color={type === 'serviceLocations' ? '' : TYPE_COLORS[type]}
                  latitude={item.location.latitude}
                  longitude={item.location.longitude}
                  onClick={event => {
                    event.originalEvent?.preventDefault();
                    event.originalEvent?.stopPropagation();
                    setPopupInfo({ item, type });
                  }}
                  logo={
                    (type === 'serviceLocations' && (
                      <Logo sx={{ height: 25 }} />
                    )) ||
                    (type === 'vehicles' && (
                      <Iconify
                        icon="mdi:truck"
                        color="white"
                        sx={{ height: 25, width: 25 }}
                      />
                    )) ||
                    (type === 'fleetLocations' && (
                      <Iconify
                        icon="mdi:office-building-marker-outline"
                        color="white"
                        sx={{ height: 20, width: 20 }}
                      />
                    )) ||
                    (type === 'activeRequests' && (
                      <Iconify
                        icon="mdi:cog"
                        color="white"
                        sx={{ height: 25, width: 25 }}
                      />
                    )) ||
                    (type === 'technicians' && (
                      <Badge
                        variant={getBadgeVariant(item.status)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                      >
                        <Avatar
                          alt={item.clerkUser.firstName}
                          src={item.imageUrl}
                        >
                          {item.clerkUser.firstName[0] +
                            item.clerkUser.lastName[0]}
                        </Avatar>
                      </Badge>
                    ))
                  }
                >
                  {getMarkerLabel(type, item)}
                </MapMarker>
              ))
          )}

          {popupInfo && (
            <MapPopup
              latitude={popupInfo.item.location!.latitude}
              longitude={popupInfo.item.location!.longitude}
              onClose={() => setPopupInfo(null)}
              sx={{ top: '-37px', left: '-17px' }}
            >
              <Grid container sx={{ flexWrap: 'nowrap', gap: 2 }}>
                {popupInfo.type === 'technicians' ? (
                  <Stack spacing={2} direction="row" alignItems="start">
                    <Badge
                      variant={getBadgeVariant(
                        (popupInfo.item as Doc<'users'>).status ?? 'OFFLINE'
                      )}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                    >
                      <Avatar
                        alt={
                          (popupInfo.item as Doc<'users'>).clerkUser.firstName
                        }
                        src={
                          (popupInfo.item as Doc<'users'>).clerkUser.imageUrl
                        }
                      >
                        {(popupInfo.item as Doc<'users'>).clerkUser
                          .firstName[0] +
                          (popupInfo.item as Doc<'users'>).clerkUser
                            .lastName[0]}
                      </Avatar>
                    </Badge>

                    <Stack
                      sx={{
                        typography: 'body2',
                        flex: '1 1 auto',
                        alignItems: 'flex-start',
                        gap: 1,
                      }}
                    >
                      <MuiTypeSafeLink
                        to="/dashboard/mechanics/$mechanicId"
                        params={{ mechanicId: popupInfo.item._id }}
                        color="inherit"
                        sx={{ fontWeight: 'bold' }}
                        // sx={{ cursor: 'pointer' }}
                      >
                        {(popupInfo.item as Doc<'users'>).clerkUser.firstName}{' '}
                        {(popupInfo.item as Doc<'users'>).clerkUser.lastName}
                      </MuiTypeSafeLink>

                      <Typography
                        variant="caption"
                        color={getStatusColor(
                          (popupInfo.item as Doc<'users'>).status ?? 'OFFLINE'
                        )}
                      >
                        {getStatusLabel(
                          (popupInfo.item as Doc<'users'>).status ?? 'OFFLINE'
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                ) : (
                  <Box>
                    <Typography variant="subtitle2">
                      {popupInfo.type === 'companies' ||
                      popupInfo.type === 'serviceLocations' ||
                      popupInfo.type === 'fleetLocations' ? (
                        (popupInfo.item as Doc<'companies' | 'groups'>).name
                      ) : popupInfo.type === 'vehicles' ? (
                        <MuiTypeSafeLink
                          to={'/dashboard/units/$unitId'}
                          params={{
                            unitId: (popupInfo.item as Doc<'vehicles'>)._id,
                          }}
                        >
                          {(popupInfo.item as Doc<'vehicles'>).maker +
                            ' ' +
                            (popupInfo.item as Doc<'vehicles'>).model +
                            ' ' +
                            (popupInfo.item as Doc<'vehicles'>).modelYear +
                            ' / ' +
                            (popupInfo.item as Doc<'vehicles'>).unitNumber}
                        </MuiTypeSafeLink>
                      ) : popupInfo.type === 'activeRequests' ? (
                        <Stack direction="column">
                          <Typography variant="subtitle2">
                            Case Number :
                          </Typography>
                          <MuiTypeSafeLink
                            to={'/dashboard/requests/$requestId'}
                            params={{
                              requestId: (popupInfo.item as any)._id,
                            }}
                          >
                            {(popupInfo.item as any).caseNumber}
                          </MuiTypeSafeLink>
                        </Stack>
                      ) : (
                        ''
                      )}
                    </Typography>
                    {(popupInfo.type === 'serviceLocations' ||
                      popupInfo.type === 'fleetLocations') &&
                      (popupInfo.item as Doc<'groups'>)
                        .simpleServiceAreaByMilesOut && (
                        <Typography variant="caption" color="text.secondary">
                          Service Area:{' '}
                          {
                            (popupInfo.item as Doc<'groups'>)
                              .simpleServiceAreaByMilesOut
                          }{' '}
                          miles
                        </Typography>
                      )}

                    {currentDriverAssignments && (
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="subtitle2">
                          Assigned Drivers :
                        </Typography>
                        {currentDriverAssignments.map(driver => (
                          <MuiTypeSafeLink
                            to={'/dashboard/drivers/$driverId'}
                            params={{ driverId: driver?._id }}
                            sx={{ width: 'fit-content' }}
                          >
                            <Typography
                              variant="caption"
                              // color="text.secondary"
                            >
                              {driver?.clerkUser?.fullName}
                            </Typography>
                          </MuiTypeSafeLink>
                        ))}
                      </Box>
                    )}

                    {((popupInfo.type === 'activeRequests' &&
                      (popupInfo.item as any).address) ||
                      popupInfo.item.location?.address) && (
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="subtitle2">Address :</Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          color="text.secondary"
                        >
                          {popupInfo.item.location?.address ||
                            (popupInfo.type === 'activeRequests'
                              ? (popupInfo.item as any).address
                              : '')}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
              </Grid>
            </MapPopup>
          )}
          <Card
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              mt: 3,
              mr: 3,
              // height: 300,
              width: 350,
            }}
          >
            <CardHeader title="Legend" />
            <CardContent>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {/* <FormControlLabel
                  label="Companies"
                  control={
                    <Checkbox
                      size="small"
                      checked={legendFilters.companies}
                      color="primary"
                    />
                  }
                /> */}
                {/* // TODO: Will have to change eventually when higher level organizations (companies) have users who can see all the locations */}
                {shouldShowServiceLocations && (
                  <FormControlLabel
                    label={`Service Locations (${filteredData.serviceLocations.length})`}
                    control={
                      <Checkbox
                        size="small"
                        checked={legendFilters.serviceLocations}
                        color={TYPE_COLORS.serviceLocations}
                        onClick={() =>
                          setLegendFilters(prev => ({
                            ...prev,
                            serviceLocations: !prev.serviceLocations,
                          }))
                        }
                      />
                    }
                  />
                )}
                {shouldShowFleetLocations && (
                  <FormControlLabel
                    label={`Fleet Locations (${filteredData.fleetLocations.length})`}
                    control={
                      <Checkbox
                        size="small"
                        checked={legendFilters.fleetLocations}
                        color={TYPE_COLORS.fleetLocations}
                        onClick={() =>
                          setLegendFilters(prev => ({
                            ...prev,
                            fleetLocations: !prev.fleetLocations,
                          }))
                        }
                      />
                    }
                  />
                )}
                {shouldShowThirdPartyLocations && (
                  <FormControlLabel
                    label={`Third Party Call Center Locations (${filteredData.thirdPartyLocations.length})`}
                    control={
                      <Checkbox
                        size="small"
                        checked={legendFilters.thirdPartyLocations}
                        color={TYPE_COLORS.thirdPartyLocations}
                        onClick={() =>
                          setLegendFilters(prev => ({
                            ...prev,
                            thirdPartyLocations: !prev.thirdPartyLocations,
                          }))
                        }
                      />
                    }
                  />
                )}
                {(me?.impersonatingLocationId ||
                  me?.primaryRoleType === 'SERVICE_DISPATCHER') && (
                  <FormControlLabel
                    label={`Service Technicians (${filteredData.technicians.length})`}
                    control={
                      <Checkbox
                        size="small"
                        checked={legendFilters.technicians}
                        color={TYPE_COLORS.technicians}
                        onClick={() =>
                          setLegendFilters(prev => ({
                            ...prev,
                            technicians: !prev.technicians,
                          }))
                        }
                      />
                    }
                  />
                )}
                {me?.primaryRoleType === 'FLEET_DISPATCHER' && (
                  <>
                    <FormControlLabel
                      label={`Fleet Vehicles (${filteredData.vehicles.length})`}
                      control={
                        <Checkbox
                          size="small"
                          checked={legendFilters.vehicles}
                          color={TYPE_COLORS.vehicles}
                          onClick={() =>
                            setLegendFilters(prev => ({
                              ...prev,
                              vehicles: !prev.vehicles,
                            }))
                          }
                        />
                      }
                    />
                  </>
                )}
                {(me?.primaryRoleType === 'FLEET_DISPATCHER' ||
                  me?.primaryRoleType === 'SERVICE_DISPATCHER' ||
                  me?.primaryRoleType === 'THIRD_PARTY_DISPATCHER' ||
                  me?.clerkUser.isSuperAdmin) && (
                  <FormControlLabel
                    label={`Active Requests (${filteredData.activeRequests.length})`}
                    control={
                      <Checkbox
                        size="small"
                        checked={legendFilters.activeRequests}
                        color={TYPE_COLORS.activeRequests}
                        onClick={() =>
                          setLegendFilters(prev => ({
                            ...prev,
                            activeRequests: !prev.activeRequests,
                          }))
                        }
                      />
                    }
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </Map>
      </Card>
    </DashboardContent>
  );
}

function getMarkerLabel(
  type: keyof MapViewData,
  item: Doc<'companies' | 'groups' | 'users' | 'vehicles'>
): string {
  if (type === 'technicians') {
    return (item as Doc<'users'>).clerkUser?.firstName?.[0] || 'U';
  }
  if (type === 'companies') {
    return (item as Doc<'companies'>).name?.[0] || 'C';
  }
  if (type === 'serviceLocations' || type === 'fleetLocations') {
    return (
      (item as Doc<'groups'>).name?.[0] ||
      (type === 'serviceLocations' ? 'S' : 'F')
    );
  }
  if (type === 'vehicles') {
    return 'V';
  }
  return '';
}

type PopupInfo = {
  item: Doc<'companies' | 'groups' | 'users' | 'vehicles'>;
  type: keyof MapViewData;
};

function createCirclePoints(
  centerLng: number,
  centerLat: number,
  radiusMiles: number
) {
  const points = [];
  const km = radiusMiles * 1.60934;
  const distanceX = km / (111.32 * Math.cos((centerLat * Math.PI) / 180));
  const distanceY = km / 110.574;

  const steps = 64;
  for (let i = 0; i < steps; i++) {
    const angle = (i / steps) * (2 * Math.PI);
    const dx = distanceX * Math.cos(angle);
    const dy = distanceY * Math.sin(angle);
    points.push([centerLng + dx, centerLat + dy]);
  }
  points.push(points[0]);

  return points;
}
