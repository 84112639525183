import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';
import { InvoiceNewEditForm } from '../../invoice/invoice-new-edit-form';

export function InvoiceEditView() {
  return (
    <DashboardContent>
      <InvoiceNewEditForm />
    </DashboardContent>
  );
}
