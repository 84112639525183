// hooks/useRequestImages.ts
import { useAuth } from '@clerk/clerk-react';
import { openDB } from 'idb';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Id } from '../../../convex/_generated/dataModel';

export const CACHE_DB_NAME = 'request-images-cache';
export const CACHE_VERSION = 1;
const CACHE_DURATION_DAYS = 30;

type ImageState = {
  isLoading: boolean;
  url: string | null;
  error: Error | null;
};

type ImageCache = {
  id: Id<'requestImages'>;
  requestId: Id<'requests'>;
  blob: Blob;
  timestamp: number;
};

// Initialize DB connection once at module level
const dbPromise = openDB<ImageCache>(CACHE_DB_NAME, CACHE_VERSION, {
  upgrade(db) {
    const store = db.createObjectStore('images', { keyPath: 'id' });
    store.createIndex('timestamp', 'timestamp');
    store.createIndex('requestId', 'requestId');
  },
});

export const useRequestImages = (
  requestId: Id<'requests'>,
  imageIds: Id<'requestImages'>[]
) => {
  const { getToken } = useAuth();
  const [imageStates, setImageStates] = useState<Record<string, ImageState>>(
    {}
  );
  const mountedRef = useRef(true);
  const urlCache = useRef<Record<string, string>>({});

  // Cleanup function to revoke object URLs
  const revokeUrls = useCallback(() => {
    Object.values(urlCache.current).forEach(URL.revokeObjectURL);
    urlCache.current = {};
  }, []);

  // Load a single image with caching
  const loadImage = useCallback(
    async (imageId: Id<'requestImages'>) => {
      if (!mountedRef.current) return;

      try {
        setImageStates(prev => ({
          ...prev,
          [imageId]: { isLoading: true, url: null, error: null },
        }));

        const db = await dbPromise;

        // Try cache first
        const cached = await db.get('images', imageId);
        if (cached) {
          const url = URL.createObjectURL(cached.blob);
          urlCache.current[imageId] = url;

          if (mountedRef.current) {
            setImageStates(prev => ({
              ...prev,
              [imageId]: { isLoading: false, url, error: null },
            }));
          }
          return;
        }

        // Fetch if not cached
        const token = await getToken();
        const url = new URL(
          `${import.meta.env.VITE_CONVEX_SITE_URL}/photo/request`
        );
        url.searchParams.set('photoId', imageId);

        const response = await fetch(url.href, {
          credentials: 'include',
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) throw new Error('Failed to load image');

        const blob = await response.blob();

        // Cache the new image
        await db.put('images', {
          id: imageId,
          requestId,
          blob,
          timestamp: Date.now(),
        });

        // Create and store URL if component still mounted
        if (mountedRef.current) {
          const url = URL.createObjectURL(blob);
          urlCache.current[imageId] = url;
          setImageStates(prev => ({
            ...prev,
            [imageId]: { isLoading: false, url, error: null },
          }));
        }
      } catch (error) {
        if (mountedRef.current) {
          setImageStates(prev => ({
            ...prev,
            [imageId]: {
              isLoading: false,
              url: null,
              error:
                error instanceof Error ? error : new Error('Unknown error'),
            },
          }));
        }
      }
    },
    [getToken, requestId]
  );

  // Load new images when ids change
  useEffect(() => {
    const newImageIds = imageIds.filter(id => !imageStates[id]?.url);
    newImageIds.forEach(loadImage);
  }, [imageIds.join(',')]);

  // Cleanup on unmount
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
      revokeUrls();
    };
  }, []);

  // Periodic cache cleanup
  useEffect(() => {
    const cleanup = async () => {
      const db = await dbPromise;
      const cutoff = Date.now() - CACHE_DURATION_DAYS * 24 * 60 * 60 * 1000;
      const tx = db.transaction('images', 'readwrite');
      const store = tx.objectStore('images');
      await store.delete(IDBKeyRange.upperBound(cutoff, true));
    };

    // Run cleanup once per day
    const interval = setInterval(cleanup, 24 * 60 * 60 * 1000);
    cleanup(); // Run once immediately

    return () => clearInterval(interval);
  }, []);

  return imageStates;
};
