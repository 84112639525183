import { zid } from 'convex-helpers/server/zod';
import { z } from 'zod';
import { Doc } from '../../../_generated/dataModel';
import { baseSubmissionSchema } from './base';

export function getFleetDispatcherDraftInitiationFields(
  user: Doc<'users'>,
  primaryLocation: Doc<'groups'>
) {
  return {
    fleetDispatchGroupId: primaryLocation._id,
    activeFleetDispatcherId: user._id,
  };
}

export const fleetSubmissionSchema = baseSubmissionSchema.extend({
  vehicleId: zid('vehicles'),
  activeDriverId: zid('users'),
});

export type FleetSubmissionInput = z.infer<typeof fleetSubmissionSchema>;
