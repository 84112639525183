import { LoadingButton } from '@mui/lab';
import { useMutation } from 'convex/react';
import { api } from 'src/convex/_generated/api';
import { getMapboxPlaceInformation } from 'src/convex/schema/enums/timezones';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { toast } from 'src/minimal-theme/components/snackbar';
import { ActionConfirmationDialog } from 'src/sections/request/actions/ActionConfirmationDialog';
import { useActionConfirmationDialog } from 'src/sections/request/actions/hooks/useActionConfirmationDialog';
import { getDriverShareLocationActionState } from 'src/sections/request/actions/utils/getDriverShareLocationActionState';
import { RequestActionProps } from 'src/sections/request/types/RequestActionProps';
import { getCurrentPosition } from 'src/utils/getCurrentPosition';
import { reverseGeocode } from 'src/utils/reverseGeocode';

export const DriverConfirmLocationAction = ({
  request,
  me,
  requestStateInformation,
}: RequestActionProps) => {
  const { confirmAction, isOpen, pendingAction, onClose } =
    useActionConfirmationDialog();

  const driverConfirmLocationAndSendBackToDispatch = useMutation(
    api.functions.requests.driverConfirmLocationAndSendBackToDispatch
  );

  const actionState = getDriverShareLocationActionState({
    request,
    me,
    requestStateInformation,
  });

  if (!actionState.isVisible) {
    return null;
  }

  const handleShareLocationAndAssignToDispatch = async () => {
    try {
      const position = await getCurrentPosition();

      const { latitude, longitude } = position.coords;
      const feature = await reverseGeocode(latitude, longitude);

      if (!feature) {
        alert('Could not find address for this location');
        return;
      }

      const info = getMapboxPlaceInformation(feature, longitude);

      const resp = await driverConfirmLocationAndSendBackToDispatch({
        location: {
          longitude,
          latitude,
          address: feature.place_name,
        },
        ...info,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        requestId: request._id,
      });

      if (resp.success) {
        toast.success(resp.message);
      }
    } catch (error) {
      console.error('Error getting location:', error);
    }
  };

  return (
    <>
      <LoadingButton
        onClick={() =>
          confirmAction({
            title: actionState.confirmationTitle!,
            message: actionState.confirmationMessage!,
            onConfirm: handleShareLocationAndAssignToDispatch,
          })
        }
        fullWidth
        sx={{ height: '100%' }}
        color="primary"
        variant="contained"
        disabled={!actionState.isEnabled}
        startIcon={
          <Iconify icon="solar:point-on-map-bold-duotone" width={40} />
        }
      >
        {actionState.message}
      </LoadingButton>

      <ActionConfirmationDialog
        open={isOpen}
        onClose={onClose}
        {...pendingAction}
      />
    </>
  );
};
