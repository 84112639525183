import { zid, zodToConvex } from 'convex-helpers/server/zod';
import { defineTable } from 'convex/server';
import { z } from 'zod';
import { Doc, Id } from '../../_generated/dataModel';
import {
  locationReferencesSchema,
  locationSchema,
} from '../../types/locationSchema';
import { phoneSchema } from '../../zodHelpers/phoneSchema';
import { CompanyType, companyType } from '../enums/companyType';
import { currencyCodeSchema } from '../enums/currencyTypes';
import { userRole } from '../enums/userRole';
import { emailAddressSchema, userSchema, userSignUpSchema } from './users';

export const einSchema = z.string().regex(/^\d{2}-\d{7}$/, {
  message: 'EIN should be in the format XX-XXXXXXX (e.g., 12-3456789)',
});
export const companyNameSchema = z.string().min(4, {
  message: 'Company name is required and must be greater than 3 characters',
});

export const dotNumberSchema = z.string().regex(/^\d{6,7}$/, {
  message: 'DOT number must be a 6 or 7 digit number',
});

export const aboutSchema = z.string().min(10);
export const partialCompanySchema = z.object({
  ein: einSchema,
  name: companyNameSchema,
});
export const signUpSchema = userSignUpSchema.merge(partialCompanySchema);
export const companySchema = z
  .object({
    name: companyNameSchema,

    contactEmail: z.string().email().optional(),
    contactPhoneNo: phoneSchema.optional(),
    about: aboutSchema.optional(),
    // TODO: RF - what is the company rate for?

    // Denormalized location count (corresponds to # groups that are locations under this company)
    locationCount: z.number().optional(),

    isSuperAdmin: z.boolean().optional(),

    isSignUpFormCompleted: z.boolean().optional(),
    isFullyVerified: z.boolean().optional(),
    // MC stands for "motor carrier". An MC number is a type of operating authority, or docket number, that is assigned to interstate for-hire motor carriers and brokers.
    // According to James, we don't need this currently and should favor DOT
    mcNo: z
      .string()
      .regex(/^[A-Z]{2}\d{6,7}$/)
      .optional(),
    isMcVerified: z.boolean().optional(),
    // Employer Identification Number, at the very least we use this as a check to avoid duplicate
    ein: einSchema.optional(),
    // TODO: RF - Ein verification would be a manual process I think and might be tricky
    isEinVerified: z.boolean().optional(),
    isInsuranceVerified: z.boolean().optional(),

    // For fleet companies
    dotNo: dotNumberSchema.optional(),

    // Booleans that determine what the verification requirements are
    isMcVerificationRequired: z.boolean().optional(),
    isInsuranceVerificationRequired: z.boolean().optional(),
    isEinVerificationRequired: z.boolean().optional(),

    // Company wide rate (if no more granular overrides exist, this is the hourly rate for all services/employees in company)
    rate: z.number().optional(),
    rateCurrencyType: currencyCodeSchema.optional(),

    location: locationSchema.optional(), // Optional on initial creation of company

    // TODO: RF - how does this work?
    recommended: z.boolean().optional(),

    // TODO: RF - in the new system would we ever want them not to be able to?
    jobCount: z.number().int().optional(),
    averageRating: z.number().int().optional(),

    createdByUserDuringRequest: z.boolean().optional(),
    createdFromRequestId: zid('requests').optional(),

    // Admin verification progress
    isAdminVerified: z.boolean().optional(), // Only true when our team verifies
    adminVerifiedAt: z.string().optional(),
    adminVerifiedById: zid('users').optional(),

    // If service provider has completed profile/onboarding
    hasCompletedOnboarding: z.boolean().optional(),

    // Whether to show when searching for providers
    showInProviderDirectory: z.boolean().optional(),

    // TODO: RF - what texts and to who?
    textNotification: z.boolean().default(true).optional(),
    // TODO: RF - need to dig in more to see if this is a protected field we should avoid exposing to front-end? Probably already is in the other version

    isPremiumCompany: z.boolean().optional(),
    customColor: z.string().optional(),
    companyType: companyType,
    invitedByUser: zid('users').optional(),
    invitedByLocation: zid('groups').optional(),
    invitedByCompany: zid('companies').optional(),

    customerSuccessRepId: zid('users').optional(),
    accountExecutiveId: zid('users').optional(),

    deletedAt: z.number().optional(),

    // Relationships
    ownerId: zid('users').optional(), // Company created before owner user
  })
  .merge(locationReferencesSchema);

export type MyCompany = {
  _id: Id<'companies'>;
  name: string;
  isFullyVerified: boolean;
  companyType: CompanyType;
  isSignUpFormCompleted: boolean;
};

export const companies = defineTable(zodToConvex(companySchema).fields)
  .index('by_mcNo', ['mcNo'])
  .index('by_ein', ['ein'])
  .index('by_dotNo', ['dotNo'])
  .index('by_isSuperAdmin', ['isSuperAdmin'])
  .index('by_isFullyVerified', ['isFullyVerified'])
  .index('by_createdByUserDuringRequest', ['createdByUserDuringRequest'])
  .index('by_showInProviderDirectory', ['showInProviderDirectory'])
  .index('by_ownerId', ['ownerId'])
  .index('by_customerSuccessRepId', ['customerSuccessRepId'])
  .index('by_accountExecutiveId', ['accountExecutiveId'])
  .index('by_cityId', ['cityId'])
  .index('by_name', ['name'])
  // TODO: Simple to start
  .searchIndex('search', {
    searchField: 'name',
  });

export type Company = Doc<'companies'>;

export const inviteUserSchema = z.object({
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  phone: phoneSchema,
  companyId: zid('companies'),
  companyName: z.string(),
  locationGroupId: zid('groups'),
  role: userRole,
  requestId: zid('requests').optional(),
  skipInvitationEmail: z.boolean().optional(),
  invitedByUserId: zid('users').optional(),
  invitedByLocationId: zid('groups').optional(),
  invitedByCompanyId: zid('companies').optional(),

  // TODO: I don't like this... but have to get this in and not sure off the top of my head what to do,
  userInviting: userSchema
    .extend({ _id: zid('users'), _creationTime: z.number() })
    .optional(),
});

export type InviteUserInput = z.infer<typeof inviteUserSchema>;

export const inviteUserToCompanyInitSchema = z.object({
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  phone: phoneSchema,
});

export const inviteVendorSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: emailAddressSchema,
  phone: phoneSchema,
  companyName: companyNameSchema,
  address: z.string(),
});

export const inviteCompanyByMyMechanicSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: emailAddressSchema,
  phone: phoneSchema,
  companyName: companyNameSchema,
  companyType: companyType,
});

export const inviteVendorActionSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: emailAddressSchema,
  phone: phoneSchema,
  companyName: companyNameSchema,
  userId: zid('users'),
  companyId: zid('companies'),
});

export const inviteCompanyActionSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: emailAddressSchema,
  phone: phoneSchema,
  companyName: companyNameSchema,
  userId: zid('users'),
  companyType: companyType,
});

export const createCompanyLocationSchema = z.object({
  companyName: z.string(),
  companyId: zid('companies'),
  parentCompanyId: zid('groups'),
  name: z.string(),
  contactEmail: z.string().email(),
  contactPhone: z.string(),
  contactFirstName: z.string(),
  contactLastName: z.string(),
  location: locationSchema,
  description: z.string(),
  representsPhysicalLocation: z.boolean(),
  isActive: z.boolean(),
  userIsALocationManager: z.boolean(),
});

// Base schemas for company creation
export const baseCompanyInput = z.object({
  name: z.string(),
  companyType: companyType,
  ein: z.string().optional(),
  dotNo: z.string().optional(),
  about: z.string().optional(),
  location: locationSchema.optional(),
  contactEmail: z.string().email().optional(),
  contactPhoneNo: phoneSchema.optional(),
  customerSuccessRepId: zid('users').nullable().optional(),
  accountExecutiveId: zid('users').nullable().optional(),
});

export const editCompanyInfoSchema = baseCompanyInput
  .omit({ companyType: true })
  .extend({
    companyId: zid('companies'),
    location: locationSchema.optional(),
  });

// Additional fields needed for invitation flow
export const invitationCompanyInput = baseCompanyInput.extend({
  invitedByCompanyId: zid('companies').optional(),
  invitedByLocationId: zid('groups').optional(),
  invitedByUserId: zid('users').optional(),
  createdFromRequestId: zid('requests').optional(),
});

export type CompanyCreationResult = {
  companyId: Id<'companies'>;
  success: boolean;
  message: string;
};
