import {
  customCtx,
  customMutation,
  NoOp,
} from 'convex-helpers/server/customFunctions';
import {
  zCustomAction,
  zCustomMutation,
  zCustomQuery,
} from 'convex-helpers/server/zod';
import { z } from 'zod';
import {
  action,
  internalAction,
  internalMutation,
  internalQuery,
  mutation,
  query,
} from '../../_generated/server';
import { triggers } from '../triggers';

// For triggers
const wrappedMutation = customMutation(mutation, customCtx(triggers.wrapDB));
const wrappedInternalMutation = customMutation(
  internalMutation,
  customCtx(triggers.wrapDB)
);

export const zQuery = zCustomQuery(query, NoOp);
export const zInternalQuery = zCustomQuery(internalQuery, NoOp);

export const zMutation = zCustomMutation(wrappedMutation, NoOp);
export const zInternalMutation = zCustomMutation(wrappedInternalMutation, NoOp);

export const zAction = zCustomAction(action, NoOp);
export const zInternalAction = zCustomAction(internalAction, NoOp);

export const zPaginationOptsValidator = z.object({
  numItems: z.number(),
  cursor: z.string().nullable(),
  endCursor: z.string().nullable().optional(),
  id: z.number().optional(),
  maximumRowsRead: z.number().optional(),
  maximumBytesRead: z.number().optional(),
});
