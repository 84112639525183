import { zid } from 'convex-helpers/server/zod';
import { Id } from '../_generated/dataModel';
import { MutationCtx } from '../_generated/server';
import { getUserContext } from '../utils/getUserContext';
import { zQuery } from './helpers/zodHelpers';

export const getServiceCategories = zQuery({
  handler: async ctx => {
    return await ctx.db.query('serviceCategories').collect();
  },
});

export const getLocationServices = zQuery({
  args: {
    locationId: zid('groups'),
  },
  handler: async (ctx, args) => {
    return await ctx.db
      .query('locationServices')
      .withIndex('by_groupId_and_isActive', q =>
        q.eq('groupId', args.locationId).eq('isActive', true)
      )
      .collect();
  },
});

// Query to get services organized by categories
export const getServicesGroupedByCategory = zQuery({
  handler: async ctx => {
    await getUserContext(ctx);

    // First get all categories
    const categories = await ctx.db
      .query('serviceCategories')
      .filter(q => q.eq(q.field('deletedAt'), undefined))
      .collect();

    // Then get all services
    const services = await ctx.db
      .query('services')
      .filter(q => q.eq(q.field('deletedAt'), undefined))
      .collect();

    // Split services into categorized and standalone
    const categorizedServices = services.filter(
      service => service.serviceCategoryId
    );
    const standaloneServices = services.filter(
      service => !service.serviceCategoryId
    );

    // Group categorized services
    const servicesGroupedByCategory = [
      // Add standalone services as first "category"
      {
        category: {
          _id: 'standalone' as Id<'serviceCategories'>,
          name: 'General Services',
          description: 'Standalone services',
          systemGenerated: true,
        },
        services: standaloneServices,
      },
      // Add all regular categories with their services
      ...categories.map(category => ({
        category,
        services: categorizedServices.filter(
          service => service.serviceCategoryId === category._id
        ),
      })),
    ];

    return servicesGroupedByCategory;
  },
});

export async function updateLocationServices(
  ctx: MutationCtx,
  locationId: Id<'groups'>,
  serviceIds: Id<'services'>[],
  companyId: Id<'companies'>
) {
  // Get existing location services
  const existingServices = await ctx.db
    .query('locationServices')
    .withIndex('by_groupId', q => q.eq('groupId', locationId))
    .collect();

  // Determine which services to add and which to deactivate
  const existingServiceIds = existingServices.map(s => s.serviceId);
  const servicesToAdd = serviceIds.filter(
    id => !existingServiceIds.includes(id)
  );
  const servicesToDeactivate = existingServices.filter(
    s => !serviceIds.includes(s.serviceId) && s.isActive
  );

  // Add new services
  for (const serviceId of servicesToAdd) {
    await ctx.db.insert('locationServices', {
      serviceId,
      groupId: locationId,
      companyId,
      isActive: true,
    });
  }

  // Deactivate removed services
  for (const service of servicesToDeactivate) {
    await ctx.db.patch(service._id, {
      isActive: false,
    });
  }
}
