import { getManyFrom, getOneFrom } from 'convex-helpers/server/relationships';
import { zid } from 'convex-helpers/server/zod';
import { v } from 'convex/values';
import { isEqual } from 'lodash-es';
import { z } from 'zod';
import { internal } from '../_generated/api';
import { Doc, Id } from '../_generated/dataModel';
import { internalQuery, MutationCtx, QueryCtx } from '../_generated/server';
import { inviteUserSchema, MyCompany } from '../schema/entities/companies';
import { MyPrimaryLocation } from '../schema/entities/groups/groups';
import { RoleDefinition } from '../schema/entities/roleDefinitions';
import {
  isUserADispatcher,
  notificationConfigSchema,
  partialClerkUserSchema,
} from '../schema/entities/users';
import { MechanicStatus, mechanicStatus } from '../schema/enums/mechanicStatus';
import { UserRole, userRole } from '../schema/enums/userRole';
import { locationUpdateInput } from '../types/locationSchema';
import { getCustomUserIdentity } from '../utils/getCustomUserIdentity';
import { getUserContext } from '../utils/getUserContext';
import { phoneSchema } from '../zodHelpers/phoneSchema';
import { getExistingUser } from './companies';
import { driverHistoryMutations } from './driverHistory';
import { createConvexUserAndRoleHelper } from './helpers/userCreation';
import {
  zInternalMutation,
  zInternalQuery,
  zMutation,
  zQuery,
} from './helpers/zodHelpers';
import { getActiveRequestsForUserHelper } from './requests';

/** Get user by Clerk use id (AKA "subject" on auth)  */
export const getUser = internalQuery({
  args: { subject: v.string() },
  async handler(ctx, args) {
    return await userQueryByClerkId(ctx, args.subject);
  },
});

export type EnhancedUser = Doc<'users'> & {
  roles: Doc<'roleDefinitions'>[];
  company: MyCompany;
  location: MyPrimaryLocation;
};

export const getMe = zQuery({
  args: {},
  async handler(ctx) {
    const { user, roles, company, primaryLocation } = await getUserContext(ctx);

    return {
      ...user,
      roles,
      company: {
        _id: company._id,
        name: company.name,
        companyType: company.companyType,
        isFullyVerified: company.isFullyVerified,
        isSignUpFormCompleted: company.isSignUpFormCompleted,
      },
      location: {
        _id: primaryLocation._id,
        name: primaryLocation.name,
        locationType: primaryLocation.locationType,
        phoneNumber: primaryLocation.contactInfo?.phone ?? undefined,
        address: primaryLocation.location?.address ?? undefined,
      },
    } as EnhancedUser;
  },
});

export const getUserById = zQuery({
  args: {
    id: zid('users'),
  },
  handler: async (ctx, { id }) => {
    const { user, roles, company } = await getUserContext(ctx);

    const u = await ctx.db.get(id);

    if (!u || !u.primaryLocationGroupId) {
      throw new Error(`User with id ${id} not found`);
    }

    // TODO: Switch to more specific authz in the future
    // const canRead = await canReadUser({
    //   ctx,
    //   user,
    //   roles,
    //   targetLocationId: u.primaryLocationGroupId,
    // });

    const canRead = company._id === u.companyId;

    if (!user.clerkUser.isSuperAdmin && !canRead) {
      throw new Error(`Not authorized to view this user`);
    } else {
      return u;
    }
  },
});

export type LimitedRepresentativeUser = {
  _id: Id<'users'>;
  fullName: string;
  imageUrl: string;
  email: string;
};

export const getCompanyReps = zQuery({
  handler: async ctx => {
    const { user, company } = await getUserContext(ctx);

    const results: LimitedRepresentativeUser[] = [];

    if (user.clerkUser.isSuperAdmin || user.isCompanyPrimaryAdmin) {
      const [accountExecutive, customerSuccessRep] = await Promise.all([
        company.accountExecutiveId
          ? ctx.db.get(company.accountExecutiveId)
          : null,
        company.customerSuccessRepId
          ? ctx.db.get(company.customerSuccessRepId)
          : null,
      ]);

      if (accountExecutive) {
        results.push({
          _id: accountExecutive._id,
          fullName: accountExecutive.clerkUser.fullName,
          imageUrl: accountExecutive.clerkUser.imageUrl,
          email: accountExecutive.clerkUser.primaryEmailAddress.emailAddress,
        });
      }
      if (customerSuccessRep) {
        results.push({
          _id: customerSuccessRep._id,
          fullName: customerSuccessRep.clerkUser.fullName,
          imageUrl: customerSuccessRep.clerkUser.imageUrl,
          email: customerSuccessRep.clerkUser.primaryEmailAddress.emailAddress,
        });
      }
    }

    return results;
  },
});

export const getUserByIdInternally = zInternalQuery({
  args: {
    id: zid('users'),
  },
  handler: async (ctx, { id }) => {
    const u = await ctx.db.get(id);

    if (!u) {
      throw new Error(`User with id ${id} not found`);
    }
    return u;
  },
});

export const getMyMechanicUsers = zQuery({
  handler: async ctx => {
    const { user } = await getUserContext(ctx);

    if (!user.clerkUser.isSuperAdmin) {
      throw new Error('Not authorized to view admin users');
    }

    const allUsers = await ctx.db
      .query('users')
      .withIndex('by_superAdmin', q => q.eq('clerkUser.isSuperAdmin', true))
      .collect();

    return allUsers.filter(u => u.isDeleted !== true);
  },
});

// TODO: pushing out pagination and server side filtering for now... no time.. front-end expects client side filtering for now, just keep it, be mindful of query return limits... 8Mib is pretty huge
// For now this is only handling dispatchers being the ones who query groupings of users... there is another query for pulling from a single location
export const getUsersOfRoleForMultipleLocations = zQuery({
  args: {
    roleType: userRole,
  },
  handler: async (ctx, { roleType }) => {
    const { user, primaryLocation, isDispatcher } = await getUserContext(ctx);

    if (!isDispatcher && !user.clerkUser.isSuperAdmin) {
      throw new Error('Not authorized');
    }

    // TODO: Simplified for now, getting all from company... only people who can do this are dispatchers and super admins
    // Allows dispatchers to see and manage all users regardless of their default dispatch id, long term we will allow for more locked down permissions
    const allUsers = await ctx.db
      .query('users')
      .withIndex('by_companyId_and_primaryRoleType', q =>
        q
          .eq('companyId', primaryLocation.companyId)
          .eq('primaryRoleType', roleType as UserRole)
      )
      .filter(q => q.neq(q.field('isDeleted'), true))
      .collect();

    return allUsers.filter(u => u.isDeleted !== true);

    // const [managedUsers, usersAtMyLocation] = await Promise.all([
    //   ctx.db
    //     .query('users')
    //     .withIndex('by_defaultDispatchGroupId_and_primaryRoleType', q =>
    //       q
    //         .eq('defaultDispatchGroupId', primaryLocation._id)
    //         .eq('primaryRoleType', roleType as UserRole)
    //     )
    //     .collect(),
    //   ctx.db
    //     .query('users')
    //     .withIndex('by_primaryLocationGroupId_and_primaryRoleType', q =>
    //       q
    //         .eq('primaryLocationGroupId', primaryLocation._id)
    //         .eq('primaryRoleType', roleType as UserRole)
    //     )
    //     .collect(),
    // ]);

    // return uniqBy(
    //   [...managedUsers, ...usersAtMyLocation].filter(u => u.isDeleted !== true),
    //   '_id'
    // );
  },
});

// export const getUsersOfRoleForMultipleLocations = zQuery({
//   args: {
//     roleType: userRole,
//     paginationOpts: zPaginationOptsValidator, // TODO: implement elsewhere? Need to test ... regular query is working in other spots so not sure why query wasn't working here
//   },
//   handler: async (ctx, { roleType, paginationOpts }) => {
//     const { user, primaryLocation } = await getUserContext(ctx);

//     if (!user) {
//       throw new Error('User not authenticated');
//     }

//     return ctx.db
//       .query('users')
//       .withIndex('by_defaultDispatchGroupId_and_primaryRoleType', q =>
//         q
//           .eq('defaultDispatchGroupId', primaryLocation._id)
//           .eq('primaryRoleType', roleType as UserRole)
//       )
//       .paginate(paginationOpts);
//   },
// });

export const getUsersOfRoleForLocation = zQuery({
  args: {
    roleType: userRole,
  },
  handler: async (ctx, { roleType }) => {
    const { user } = await getUserContext(ctx);
    if (!user) {
      throw new Error('User not authenticated');
    }
    const allUsers = await ctx.db
      .query('users')
      .withIndex('by_primaryLocationGroupId_and_primaryRoleType', q =>
        q
          .eq(
            'primaryLocationGroupId',
            user.impersonatingLocationId ?? user.primaryLocationGroupId
          )
          .eq('primaryRoleType', roleType)
      )
      .collect();

    return allUsers.filter(u => u.isDeleted !== true);
  },
});

// TODO: Putting the paginated/efficient request on the back burner for now... requires too much refactoring on the frontend that has client side filtering everywhere
// export const searchUsersOfRoleForMultipleLocations = zQuery({
//   args: {
//     roleType: userRole,
//     searchTerm: z.string(),
//   },
//   handler: async (ctx, { roleType, searchTerm }) => {
//     const { user, primaryLocation } = await getUserContext(ctx);

//     if (!user) {
//       throw new Error('User not authenticated');
//     }

//     const trimmed = searchTerm.trim();

//     if (!trimmed) {
//       return [];
//     }

//     return ctx.db
//       .query('users')
//       .withSearchIndex(
//         'searchQueryText_defaultDispatchGroupId_primaryRoleType',
//         q =>
//           q
//             .search('searchQueryText', trimmed)
//             .eq('defaultDispatchGroupId', primaryLocation._id)
//             .eq('primaryRoleType', roleType)
//       )
//       .take(ROWS_PER_PAGE);
//   },
// });

export const getAllUsersForLocation = zQuery({
  handler: async ctx => {
    const { user, primaryLocation } = await getUserContext(ctx);

    if (!user) {
      throw new Error('User not authenticated');
    }

    // TODO: Need extra layers of validation, not every user in a company should be able to view all info about all other users in the company
    // And also it's eventually going to be getting the users for a particular group ("location" group, etc.), especially for large multi-location companies
    const allUsers = await getManyFrom(
      ctx.db,
      'users',
      'by_defaultDispatchGroupId',
      user.impersonatingLocationId ?? primaryLocation._id
    );

    return allUsers.filter(u => u.isDeleted !== true);
  },
});

export const getAllUsersForSuperAdmin = zQuery({
  handler: async ctx => {
    const { user } = await getUserContext(ctx);

    if (!user || !user.clerkUser.isSuperAdmin) {
      throw new Error('User not authenticated');
    }

    const allUsers = await ctx.db
      .query('users')
      .filter(q =>
        q.or(
          q.eq(q.field('primaryRoleType'), 'TECHNICIAN_PROVIDER'),
          q.eq(q.field('primaryRoleType'), 'TECHNICIAN_FLEET')
        )
      )
      .collect();

    return allUsers.filter(u => u.isDeleted !== true);
  },
});

// TODO: Might need to rethink this... just for super admins at the moment
export const getAllUsersForCompany = zQuery({
  args: { companyId: zid('companies') },
  handler: async (ctx, { companyId }) => {
    const { user } = await getUserContext(ctx);

    if (!user) {
      throw new Error('User not authenticated');
    }

    if (!user.clerkUser.isSuperAdmin) {
      throw new Error('Not authorized to view all users');
    }

    // TODO: Need extra layers of validation, not every user in a company should be able to view all info about all other users in the company
    // And also it's eventually going to be getting the users for a particular group ("location" group, etc.), especially for large multi-location companies
    const allUsers = await getManyFrom(
      ctx.db,
      'users',
      'by_companyId',
      companyId
    );

    return allUsers.filter(u => u.isDeleted !== true);
  },
});

export const updateUserFromClerkWebhook = zInternalMutation({
  args: {
    clerkUser: partialClerkUserSchema,
    hasVerifiedEmail: z.boolean(),
    hasVerifiedPhone: z.boolean(),
  },
  async handler(ctx, { clerkUser, ...restOfUser }) {
    const user = await getUser(ctx, { subject: clerkUser.id });

    if (user) {
      await ctx.db.patch(user._id, {
        clerkUser,
        ...restOfUser,
      });
    }
  },
});

// TODO: need to be very careful with this obviously... currently purely for invitation errors being thrown/user cleanup in that specific case
export const deleteUserByClerkId = zInternalMutation({
  args: {
    clerkUserId: z.string(),
  },
  async handler(ctx, { clerkUserId }) {
    const user = await getOneFrom(
      ctx.db,
      'users',
      'by_clerkId',
      clerkUserId,
      'clerkUser.id'
    );

    if (user) {
      await ctx.db.delete(user._id);
    } else {
      throw new Error('Could not find user by clerk id to delete');
    }
  },
});

async function userQueryByClerkId(ctx: QueryCtx, clerkUserId: string) {
  return await getOneFrom(
    ctx.db,
    'users',
    'by_clerkId',
    clerkUserId,
    'clerkUser.id'
  );
}

export type ConvexUserCreationResult = {
  userId: Id<'users'>;
};

export const createConvexUserAndRole = zInternalMutation({
  args: {
    input: inviteUserSchema.omit({ companyName: true }),
    clerkId: z.string(),
  },
  handler: async (ctx, args): Promise<ConvexUserCreationResult> => {
    const result = await createConvexUserAndRoleHelper(
      ctx.db,
      args.input,
      args.clerkId
    );

    return result;
  },
});

export const updateTechnicianStatus = zInternalMutation({
  args: {
    technicianId: zid('users'),
    status: mechanicStatus,
  },
  handler: async (ctx, { technicianId, status }) => {
    await updateTechnicianStatusHelper(ctx, technicianId, status);
  },
});

export const updateTechnicianStatusHelper = async (
  ctx: MutationCtx,
  technicianId: Id<'users'>,
  status: MechanicStatus
) => {
  return ctx.db.patch(technicianId, { status });
};

export const updateTechnicianLocation = zMutation({
  args: locationUpdateInput.shape,
  handler: async (ctx, { latitude, longitude }) => {
    const { user, roles } = await getUserContext(ctx);
    if (!user) throw new Error('Not authenticated');

    const isTechnician = roles.some(
      r => r?.type === 'TECHNICIAN_PROVIDER' || r?.type === 'TECHNICIAN_FLEET'
    );

    if (!isTechnician) {
      throw new Error('Only technicians can update their location');
    }

    await ctx.db.patch(user._id, {
      location: { latitude, longitude, lastUpdated: new Date().toISOString() },
    });
    return true;
  },
});

export const updateUserSchema = z.object({
  userId: zid('users'),
  firstName: z.string().min(2, 'First name cannot be empty'),
  lastName: z.string().min(2, 'First name cannot be empty'),
  email: z.string().email('Please provide a valid email address'),
  imageUrl: z.string().optional(),
  phone: phoneSchema,
  primaryLocationGroupId: zid('groups'),
  notificationSettings: notificationConfigSchema.optional(),
});

export const updateMe = zMutation({
  args: {},
  handler: async (ctx, args) => {},
});

export const setCompanyPrimaryAdmin = zMutation({
  args: { userId: zid('users'), isAdmin: z.boolean() },
  handler: async (ctx, { userId, isAdmin }) => {
    const { user } = await getUserContext(ctx);

    if (!user.clerkUser.isSuperAdmin) {
      throw new Error('Not authorized to set primary admin');
    }

    const userBeingUpdated = await ctx.db.get(userId);

    if (!userBeingUpdated) {
      throw new Error('User not found');
    }

    ctx.db.patch(userId, { isCompanyPrimaryAdmin: isAdmin });

    return { success: true, message: 'Primary admin status updated' };
  },
});

export const updateUser = zMutation({
  args: updateUserSchema.shape,
  handler: async (ctx, args) => {
    const { user, roles, isDispatcher, company } = await getUserContext(ctx);

    const {
      userId,
      firstName,
      lastName,
      imageUrl,
      email,
      phone,
      primaryLocationGroupId,
      notificationSettings,
    } = args;

    const userBeingUpdated = await ctx.db.get(userId);

    if (!userBeingUpdated || !userBeingUpdated.primaryLocationGroupId) {
      throw new Error('User not found');
    }

    if (userId === user._id) {
      // Users can edit their own basic info but not location, TODO: maybe not some other things as well...
      if (primaryLocationGroupId !== userBeingUpdated.primaryLocationGroupId) {
        return {
          success: false,
          message: 'Not authorized to change location',
        };
      }
    } else {
      // If editing another user, need dispatch permissions
      // TODO: return to something like this in the future once we work out the UX

      const hasRequiredDispatchPermissions =
        company._id === userBeingUpdated.companyId && isDispatcher;
      // const hasRequiredDispatchPermissions = await canManageLocationUsers({
      //   ctx,
      //   user,
      //   roles,
      //   targetLocationId: primaryLocationGroupId,
      //   currentLocationId: userBeingUpdated.primaryLocationGroupId,
      //   userPrimaryLocationId: primaryLocation._id,
      //   userPrimaryLocationDefaultDispatchGroupId:
      //     primaryLocation.defaultDispatchGroupId,
      // });
      if (!user.clerkUser.isSuperAdmin && !hasRequiredDispatchPermissions) {
        return {
          success: false,
          message: 'Not authorized to update user',
        };
      }
    }

    // Check for duplicates (excluding current user)
    const duplicate = await getExistingUser(ctx, email, phone);
    if (duplicate && duplicate._id !== userId) {
      throw new Error('User with this email or phone already exists');
    }

    let notificationSettingsChanged = false;
    if (notificationSettings) {
      // If user previously had no settings and now they do, or vice versa
      if (!userBeingUpdated.notificationSettings !== !notificationSettings) {
        notificationSettingsChanged = true;
      } else if (
        userBeingUpdated.notificationSettings &&
        notificationSettings
      ) {
        // Deep compare the settings objects
        notificationSettingsChanged = !isEqual(
          userBeingUpdated.notificationSettings,
          notificationSettings
        );
      }
    }

    // Only schedule Clerk update if name/email/phone changed
    let scheduleId: Id<'_scheduled_functions'> | undefined = undefined;
    if (
      firstName !== userBeingUpdated.clerkUser.firstName ||
      lastName !== userBeingUpdated.clerkUser.lastName ||
      email !== userBeingUpdated.clerkUser.primaryEmailAddress?.emailAddress ||
      phone !== userBeingUpdated.clerkUser.primaryPhoneNumber?.phoneNumber ||
      imageUrl !== userBeingUpdated.clerkUser.imageUrl ||
      primaryLocationGroupId !== userBeingUpdated.primaryLocationGroupId ||
      notificationSettingsChanged
    ) {
      scheduleId = (await ctx.scheduler.runAfter(
        0,
        internal.actions.users.updateClerkUserAction,
        {
          userId,
          firstName,
          lastName,
          email,
          phone,
          imageUrl,
          primaryLocationGroupId,
          userPerformingActionId: user._id,
          notificationSettings,
        }
      )) as Id<'_scheduled_functions'>;
    }

    return {
      success: true,
      message: 'User update initiated',
      scheduleId,
    };
  },
});

export const updateUserInternally = zInternalMutation({
  args: {
    id: zid('users'),
    clerkUser: partialClerkUserSchema,
    primaryLocationGroupId: zid('groups').optional(),
    currentLocationGroupId: zid('groups').optional(),
    userPerformingActionId: zid('users'),
    notificationSettings: notificationConfigSchema.optional(),
  },
  handler: async (ctx, args) => {
    const {
      id,
      clerkUser,
      primaryLocationGroupId,
      currentLocationGroupId,
      userPerformingActionId,
      notificationSettings,
    } = args;

    if (currentLocationGroupId !== primaryLocationGroupId) {
      // End all driver assignments when location changes
      await driverHistoryMutations.endAllAssignmentsForUser(ctx, {
        userId: id,
        updatedBy: userPerformingActionId,
      });
    }

    let additionalPatchFields: Partial<Doc<'users'>> = {};

    if (notificationSettings) {
      additionalPatchFields.notificationSettings = notificationSettings;
    }

    await ctx.db.patch(id, {
      primaryLocationGroupId: primaryLocationGroupId,
      clerkUser,
      ...additionalPatchFields,
    });

    return { success: true };
  },
});

export const deleteUser = zMutation({
  args: { userId: zid('users') },
  handler: async (ctx, { userId }) => {
    const { user, company, isDispatcher } = await getUserContext(ctx);

    const userBeingDeleted = await ctx.db.get(userId);

    // TODO: Switched to simplified authz for now, need to flesh out more
    // const isAuthororizedDispatcher =
    //   isDispatcher &&
    //   userBeingDeleted?.defaultDispatchGroupId === primaryLocation._id;

    const isAuthororizedDispatcher =
      isDispatcher && company._id === userBeingDeleted?.companyId;

    if (
      (user.clerkUser.isSuperAdmin || isAuthororizedDispatcher) &&
      userBeingDeleted
    ) {
      const {
        allNonDraftRequests,
        draftRequestsAsDriver,
        draftRequestsAsFleetDispatcher,
      } = await getActiveRequestsForUserHelper(ctx, userId);

      if (allNonDraftRequests.length > 0) {
        return {
          success: false,
          message: 'User has active requests and cannot be deleted',
        };
      }

      // Clear out references to user in draft requests
      await Promise.all([
        Promise.all(
          draftRequestsAsDriver.map(request =>
            ctx.db.patch(request._id, { activeDriverId: undefined })
          )
        ),
        Promise.all(
          draftRequestsAsFleetDispatcher.map(request =>
            ctx.db.patch(request._id, { activeFleetDispatcherId: undefined })
          )
        ),
      ]);

      const scheduleId: Id<'_scheduled_functions'> =
        await ctx.scheduler.runAfter(
          0,
          internal.actions.users.deleteUserAction,
          {
            userId: userBeingDeleted._id,
            userClerkId: userBeingDeleted.clerkUser.id,
            userPerformingActionId: user._id,
          }
        );

      return {
        success: true,
        message: 'User deleted successfully',
        scheduleId,
      };
    } else {
      return {
        success: false,
        message: 'Not authorized to delete user',
      };
    }
  },
});

export const deleteUserInternal = zInternalMutation({
  args: {
    userId: zid('users'),
    userPerformingActionId: zid('users'),
  },
  handler: async (ctx, { userId, userPerformingActionId }) => {
    const userBeingDeleted = await ctx.db.get(userId);
    console.log('Starting internal user deletion');

    // TODO: This should not happen.. but if it did we'd need a way to reverse the clerk deletion somehow.. don't even think this is possible. Figure out a good solution
    if (!userBeingDeleted) {
      throw new Error('User not found');
    }

    const assignmentsEnded =
      await driverHistoryMutations.endAllAssignmentsForUser(ctx, {
        userId,
        updatedBy: userPerformingActionId,
      });

    console.log(`Ended ${assignmentsEnded} assignments`);

    const deletionTime = Date.now();

    await ctx.db.patch(userId, {
      isDeleted: true,
      deletedAt: Date.now(),
      searchQueryText: undefined,

      pushToken: undefined,
      arePushNotificationsEnabled: false,

      clerkUser: {
        ...userBeingDeleted.clerkUser,
        id: `${userBeingDeleted.clerkUser.id}_DELETED_${deletionTime}`,
        primaryEmailAddress: {
          emailAddress: `${userBeingDeleted.clerkUser.primaryEmailAddress?.emailAddress}_DELETED_${deletionTime}`,
        },
        primaryPhoneNumber: {
          phoneNumber: `${userBeingDeleted.clerkUser.primaryPhoneNumber?.phoneNumber}_DELETED_${deletionTime}`,
        },
      },

      status: 'OFFLINE', // TODO: Anything else here?
    });

    return { success: true };
  },
});

// TODO: Move this/use it.. random example of ... how annoying conditional query building is... https://discord.com/channels/1019350475847499849/1312152405969993779
// type RequestTableInfo = {
//   document: Doc<'requests'>;
//   fieldPaths: string;
//   indexes: {
//     by_caseNumber: ['caseNumber', '_creationTime'];
//   };
//   searchIndexes: {
//     search: {
//       searchField: 'searchText';
//       filterFields: never;
//     };
//   };
//   vectorIndexes: Record<string, never>;
// };

// const preIndexQuery = ctx.db.query('requests');
// let postIndexQuery: Query<RequestTableInfo> = preIndexQuery;

// if (true) {
//   postIndexQuery = preIndexQuery.withIndex('by_caseNumber', q =>
//     q.eq('caseNumber', 'blah')
//   );
// }

// if (true) {
//   postIndexQuery = postIndexQuery.filter(q =>
//     q.neq(q.field('status'), 'DRAFT')
//   );
// }

// let orderedQuery: OrderedQuery<RequestTableInfo> = postIndexQuery;

// if (true) {
//   orderedQuery = postIndexQuery.order('desc');
// }

// const results = await orderedQuery.collect();

export const initSuperAdminUser = zMutation({
  handler: async (ctx, { latitude, longitude }) => {
    const identity = await getCustomUserIdentity(ctx);
    if (!identity) throw new Error('User not authenticated');

    const { isSuperAdmin, subject, email, givenName, familyName, phoneNumber } =
      identity;
    if (!isSuperAdmin) return { success: false, message: 'Not a superadmin' };

    if (!email || !givenName || !familyName || !phoneNumber) {
      return { success: false, message: 'Missing attributes in identity' };
    }

    const [adminCompany, adminPrimaryGroup] = await Promise.all([
      getOneFrom(ctx.db, 'companies', 'by_isSuperAdmin', true),
      getOneFrom(ctx.db, 'groups', 'by_isSuperAdmin', true),
    ]);

    if (!adminCompany || !adminPrimaryGroup) {
      throw Error('Admin company or group does not exist');
    }

    await ctx.runMutation(internal.functions.users.createConvexUserAndRole, {
      input: {
        email,
        phone: phoneNumber,
        firstName: givenName,
        lastName: familyName,
        role: 'SUPER_ADMIN',
        companyId: adminCompany._id,
        locationGroupId: adminPrimaryGroup._id,
      },
      clerkId: subject,
    });

    return { success: true, message: 'Created superadmin user' };
  },
});

export const checkSuperAdminInit = zQuery({
  handler: async ctx => {
    return isNewSuperAdmin(ctx);
  },
});

async function isNewSuperAdmin(ctx: QueryCtx) {
  const identity = await getCustomUserIdentity(ctx);
  if (!identity) return { needsSuperAdminInit: undefined };

  const { isSuperAdmin, subject } = identity;
  if (!isSuperAdmin) return { needsSuperAdminInit: false };

  const user = await getUser(ctx, { subject });
  return { needsSuperAdminInit: !user };
}

type CanManageLocations = {
  ctx: MutationCtx;
  user: Doc<'users'>;
  roles: Doc<'roleDefinitions'>[];
  targetLocationId: Id<'groups'>; // User being moved to
  currentLocationId: Id<'groups'>; // User being moved from
  // User performing action
  userPrimaryLocationId: Id<'groups'>;
  userPrimaryLocationDefaultDispatchGroupId?: Id<'groups'>;
};

export async function canManageLocationUsers({
  ctx,
  user,
  roles,
  targetLocationId,
  currentLocationId,
  userPrimaryLocationId,
  userPrimaryLocationDefaultDispatchGroupId,
}: CanManageLocations) {
  // Super admins always have access
  if (user.clerkUser.isSuperAdmin) return true;

  // Check if user is a dispatcher
  const isDispatcher = isUserADispatcher(roles);

  if (!isDispatcher || !userPrimaryLocationDefaultDispatchGroupId) return false;

  // User could be a dispatcher who moved to a regular location, or already at the central dispatch
  // Either way, give them permissions to move around/move other users around
  // TODO: need to flesh this out more
  const [locationsManagedByUserPrimary, managingLocation] = await Promise.all([
    getManyFrom(
      ctx.db,
      'groups',
      'by_defaultDispatchGroupId',
      userPrimaryLocationId
    ),
    ctx.db.get(userPrimaryLocationDefaultDispatchGroupId),
  ]);

  const locationsInTrust = [
    ...locationsManagedByUserPrimary,
    managingLocation,
  ].filter(l => l !== null);

  // Check if the target location and current location are in the trust
  const targetIsInTrust = locationsInTrust.some(
    location => location._id === targetLocationId
  );
  const currentIsInTrust = locationsInTrust.some(
    location => location._id === currentLocationId
  );

  return targetIsInTrust && currentIsInTrust;
}

type CanReadUserParams = {
  ctx: QueryCtx;
  user: Doc<'users'>; // User performing query
  roles: RoleDefinition[];
  targetLocationId: Id<'groups'>;
};

export async function canReadUser({
  ctx,
  user,
  roles,
  targetLocationId,
}: CanReadUserParams) {
  if (user.clerkUser.isSuperAdmin) return true;

  const isDispatcher = isUserADispatcher(roles);

  if (!isDispatcher || !user.primaryLocationGroupId) return false;

  // Get locations managed by the user's primary location
  const [locationsManagedByUserPrimary, managingLocation] = await Promise.all([
    getManyFrom(
      ctx.db,
      'groups',
      'by_defaultDispatchGroupId',
      user.primaryLocationGroupId
    ),
    ctx.db.get(user.primaryLocationGroupId),
  ]);

  const locationsInTrust = [
    ...locationsManagedByUserPrimary,
    managingLocation,
  ].filter(l => l !== null);

  // Check if the target location is in the trust
  return locationsInTrust.some(location => location._id === targetLocationId);
}
