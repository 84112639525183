import { useSignIn } from '@clerk/clerk-react';
import { isClerkAPIResponseError } from '@clerk/clerk-react/errors';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Dialog, Stack, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useForm } from '@tanstack/react-form';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { useConvexAuth } from 'convex/react';
import { parsePhoneNumber } from 'libphonenumber-js';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useEffect, useState } from 'react';
import { FormHead } from 'src/components/form/form-head';
import { phoneSchema } from 'src/convex/zodHelpers/phoneSchema';
import { usePreferredLoginMethod } from 'src/hooks/usePreferredLoginMethod';
import { LoadingScreen } from 'src/minimal-theme/components/loading-screen';
import { Logo } from 'src/minimal-theme/components/logo';
import { PhoneInput } from 'src/minimal-theme/components/phone-input';
import { toast } from 'src/minimal-theme/components/snackbar';
import { CenteredLayout } from 'src/minimal-theme/layouts/centered';
import { SplitLayout } from 'src/minimal-theme/layouts/split';
import { dashboardIndexLinkOptions } from 'src/routes/_auth.dashboard.index';
import { getAbsoluteLink } from 'src/utils/getAbsoluteLink';
import { z } from 'zod';

type SignInViewProps = {
  dialogMode?: boolean;
  closeDialog?: () => void;
};

export function SignInView({ dialogMode, closeDialog }: SignInViewProps) {
  const { isAuthenticated, isLoading: isAuthenticating } = useConvexAuth();
  const [isLinkSent, setIsLinkSent] = useState<boolean>(false);
  const [isWaitingForOtpInput, setIsWaitingForOtpInput] =
    useState<boolean>(false);

  const { redirect } = useSearch({
    from: '/sign-in',
  });

  const navigate = useNavigate();

  // TODO: RF - https://clerk.com/docs/custom-flows/email-links more to do
  const { signIn, isLoaded, setActive } = useSignIn();

  const [loginMethod, setLoginMethod] = usePreferredLoginMethod();

  useEffect(() => {
    if (isAuthenticated) {
      navigate({ to: redirect ?? dashboardIndexLinkOptions.to });
    }
  }, [isAuthenticated]);

  const form = useForm({
    defaultValues: {
      email: '',
      phoneNumber: '',
      otp: '',
    },
    onSubmit: async ({ value }) => {
      if (value.otp) {
        try {
          const { createdSessionId } = await signIn!.attemptFirstFactor({
            strategy: 'phone_code',
            code: value.otp,
          });

          if (createdSessionId) {
            setActive!({ session: createdSessionId });
            toast.success('OTP verified!');
          }
        } catch (e) {
          toast.error('Invalid OTP. Please try again.');
          reset();
        }

        return;
      }

      try {
        await signIn!.create({
          strategy: loginMethod === 'email' ? 'email_link' : 'phone_code',
          identifier:
            loginMethod === 'email'
              ? value.email
              : parsePhoneNumber(value.phoneNumber).format('E.164'),
          redirectUrl:
            redirect ?? getAbsoluteLink(dashboardIndexLinkOptions.to),
        });

        toast.success(
          loginMethod === 'email'
            ? `Login link sent to your email address!`
            : `Secret code sent to SMS!`,
          {
            position: 'top-center',
            dismissible: false,
          }
        );

        if (loginMethod === 'email') {
          setIsLinkSent(true);
        } else {
          setIsWaitingForOtpInput(true);
        }
      } catch (e) {
        if (
          isClerkAPIResponseError(e) &&
          e.errors.length &&
          e.errors[0].message === "Couldn't find your account."
        ) {
          toast.error('No user found');
          // toast.warning(`Let's get you signed up first!`, {
          //   position: 'top-center',
          //   duration: 2000,
          // });
          // navigate({ to: '/sign-up', search: { notSignedUp: true } });
        }
      }
    },
  });

  const { Field, Subscribe, handleSubmit, reset, store } = form;

  const handleMethodSwitch = () => {
    const newMethod = loginMethod === 'email' ? 'phoneNumber' : 'email';
    setLoginMethod(newMethod);

    reset();
  };

  const Layout = dialogMode ? CenteredLayout : SplitLayout;

  const renderForm = (
    <Box display="flex" flexDirection="column" sx={{ mb: 0 }}>
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}
        style={{ width: '100%' }}
      >
        {loginMethod === 'email' && !isWaitingForOtpInput && (
          <>
            <Field
              name="email"
              validators={{
                onChange: z
                  .string()
                  .email({ message: 'Please provide a valid email' }),
              }}
              children={({ state, handleChange, handleBlur }) => (
                <TextField
                  label="Email Address"
                  placeholder="name@yourcompany.com"
                  variant="outlined"
                  fullWidth
                  autoFocus
                  value={state.value}
                  helperText={
                    state.meta.errors[0] ?? 'Please provide a valid email'
                  }
                  onChange={e => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  type="email"
                  autoComplete="email"
                />
              )}
            />
            <LoadingButton
              onClick={handleMethodSwitch}
              size="large"
              fullWidth
              variant="outlined"
              sx={{ mt: 2 }}
            >
              Use Phone Number
            </LoadingButton>
          </>
        )}
        {loginMethod === 'phoneNumber' && !isWaitingForOtpInput && (
          <>
            <Field
              name="phoneNumber"
              validators={{
                onChange: phoneSchema,
              }}
              children={({ state, handleChange, handleBlur }) => (
                <PhoneInput
                  required
                  placeholder="(123) 456-7890"
                  fullWidth
                  autoFocus
                  value={state.value}
                  onChange={newValue => handleChange(newValue || '')}
                  onBlur={handleBlur}
                  helperText={
                    state.meta.errors[0] ??
                    'Please provide a valid phone number'
                  }
                  label="Phone Number"
                />
              )}
            />
            <LoadingButton
              onClick={handleMethodSwitch}
              fullWidth
              variant="outlined"
              size="large"
              sx={{ mt: 2 }}
            >
              Use Email Address
            </LoadingButton>
          </>
        )}
        {isWaitingForOtpInput && (
          <Field
            name="otp"
            validators={{
              onChange: z
                .string()
                .length(6, { message: 'OTP must be exactly 6 digits' })
                .regex(/^\d+$/, { message: 'OTP must be numeric' }),
            }}
            children={({ state, handleChange, handleBlur }) => (
              <MuiOtpInput
                value={state.value}
                length={6}
                onChange={handleChange}
                autoFocus
                onBlur={handleBlur}
                TextFieldsProps={{
                  inputProps: {
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  },
                }}
              />
            )}
          />
        )}
        <Stack sx={{ mt: 3 }}>
          <Subscribe
            selector={state => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => {
              return (
                <LoadingButton
                  size="large"
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!canSubmit && !!signIn}
                >
                  {loginMethod === 'email' ||
                  (loginMethod === 'phoneNumber' && canSubmit)
                    ? `Sign In`
                    : 'Send SMS Code'}
                </LoadingButton>
              );
            }}
          />
        </Stack>
      </form>
    </Box>
  );

  let content = (
    <Layout>
      <Logo sx={{ mx: 'auto', width: '64px', height: '64px' }} />

      <FormHead
        title={isLinkSent ? 'Login link sent!' : 'Sign in to your account'}
        //* Commenting sign up link for now
        // description={
        //   !isLinkSent && (
        //     <>
        //       {`Don’t have an account? `}
        //       <Link
        //         component={RouterLink}
        //         href={'/sign-up'}
        //         variant="subtitle2"
        //       >
        //         Sign Up
        //       </Link>
        //     </>
        //   )
        // }
      />

      {isLinkSent ? (
        <>
          <Typography align="center">
            Please check your email for the login link. Or request a new link if
            you didn't receive one.
          </Typography>
          <Button onClick={handleSubmit}>Request New Link</Button>
        </>
      ) : (
        renderForm
      )}
      {/* 
      {!isLinkSent && (
        <>
          <FormDivider />
          <FormSocials
            signInWithGoogle={() => {}}
            singInWithGithub={() => {}}
            signInWithTwitter={() => {}}
          />
        </>
      )} */}
    </Layout>
  );

  if (isAuthenticating) {
    return <LoadingScreen />;
  }

  if (dialogMode) {
    content = (
      <Dialog open fullScreen onClose={() => {}}>
        {content}
      </Dialog>
    );
  }

  if (!isLoaded) {
    return null;
  }

  return <>{content}</>;
}
