import { DashboardContent } from '../../../minimal-theme/layouts/dashboard';

import { InvoiceNewEditForm } from '../../invoice/invoice-new-edit-form';

export function InvoiceCreateView() {
  return (
    <DashboardContent>
      <InvoiceNewEditForm />
    </DashboardContent>
  );
}
