import { Stack, Tab } from '@mui/material';
import { useState } from 'react';
import { EnhancedRequest } from 'src/convex/functions/requests';
import { EnhancedUser } from 'src/convex/functions/users';
import { CustomTabs } from 'src/minimal-theme/components/custom-tabs';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { RequestChat } from 'src/sections/chat/view/RequestChat';
import { RequestHistory } from 'src/sections/chat/view/RequestHistory';
import { RepairDetailsForm } from 'src/sections/request/RepairDetailsForm';
import { RequestDisplayView } from 'src/sections/request/RequestDisplayView';
import { RequestFormWrapper } from 'src/sections/request/RequestFormWrapper';
import { RequestPhotos } from 'src/sections/request/RequestPhotos';

export const REQUEST_TABS = {
  main: 'main',
  photos: 'photos',
  chat: 'chat',
  history: 'history',
} as const;

export type RequestTabs = (typeof REQUEST_TABS)[keyof typeof REQUEST_TABS];

type RequestTabsContentProps = {
  request: EnhancedRequest;
  me: EnhancedUser;
};
export const RequestTabsContent = ({
  request,
  me,
}: RequestTabsContentProps) => {
  const [currentTab, setCurrentTab] = useState<RequestTabs>(REQUEST_TABS.main);

  return (
    <Stack spacing={2}>
      <CustomTabs
        sx={{ borderRadius: 1 }}
        value={currentTab}
        onChange={(e, val) => setCurrentTab(val)}
      >
        <Tab
          icon={<Iconify icon="solar:bill-list-bold-duotone" />}
          label="Details"
          value={REQUEST_TABS.main}
        />
        {request.status !== 'DRAFT' && (
          <Tab
            icon={<Iconify icon="solar:chat-round-call-bold-duotone" />}
            label="Chat"
            value={REQUEST_TABS.chat}
          />
        )}
        <Tab
          icon={<Iconify icon="solar:camera-minimalistic-bold-duotone" />}
          label="Photos"
          value={REQUEST_TABS.photos}
        />
        <Tab
          icon={<Iconify icon="solar:sort-by-time-bold-duotone" />}
          label="History"
          value={REQUEST_TABS.history}
        />
      </CustomTabs>

      {currentTab === REQUEST_TABS.main && (
        <>
          {(request.currentStepType === 'TECHNICIAN_STARTED_WORK' ||
            request.currentStepType === 'COMPLETION_VERIFICATION' ||
            request.currentStepType === 'COMPLETED') &&
            me && (
              <RepairDetailsForm
                requestId={request._id}
                currentRepairDetails={request.repairDetails}
                editable={
                  request.currentAssignedToId === me._id &&
                  request.currentStepType === 'TECHNICIAN_STARTED_WORK'
                }
              />
            )}
          {request.status === 'DRAFT' ? (
            <RequestFormWrapper request={request} me={me} />
          ) : (
            <RequestDisplayView request={request} me={me} />
          )}
        </>
      )}
      {currentTab === REQUEST_TABS.chat && (
        <RequestChat setCurrentTab={setCurrentTab} me={me} request={request} />
      )}
      {currentTab === REQUEST_TABS.photos && (
        <RequestPhotos request={request} me={me} />
      )}
      {currentTab === REQUEST_TABS.history && <RequestHistory />}
    </Stack>
  );
};
