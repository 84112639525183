import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';
import { UserEditForm } from '../user-edit-form';

export function ProfileView() {
  return (
    <DashboardContent>
      <UserEditForm />
    </DashboardContent>
  );
}
