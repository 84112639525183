import { LoadingButton } from '@mui/lab';
import { Box, Tooltip } from '@mui/material';
import { useMutation } from 'convex/react';
import { toast } from 'sonner';
import { api } from 'src/convex/_generated/api';
import { repairDetailsSchema } from 'src/convex/schema/entities/requests/requests';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { ActionConfirmationDialog } from 'src/sections/request/actions/ActionConfirmationDialog';
import { useActionConfirmationDialog } from 'src/sections/request/actions/hooks/useActionConfirmationDialog';
import { getCompleteWorkActionState } from 'src/sections/request/actions/utils/getCompleteWorkActionState';
import { RequestActionProps } from 'src/sections/request/types/RequestActionProps';

export const TechnicianCompleteWorkAction = ({
  request,
  me,
  requestStateInformation,
}: RequestActionProps) => {
  const { confirmAction, isOpen, pendingAction, onClose } =
    useActionConfirmationDialog();

  const completeWorkOnRequest = useMutation(
    api.functions.requests.completeWorkOnRequest
  );

  const handleCompleteWork = async () => {
    const response = await completeWorkOnRequest({ requestId: request._id });
    if (response.success) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  const validateRepairDetails = (): null | string => {
    if (!request.repairDetails) {
      return 'Must submit repair details before completing work';
    }

    const result = repairDetailsSchema.safeParse(request.repairDetails);
    if (!result.success) {
      // Get the first error message
      const errorMessage =
        result.error.errors[0]?.message || 'Invalid repair details';
      return errorMessage;
    }

    return null;
  };

  const invalidRepairDetails = validateRepairDetails();

  const actionState = getCompleteWorkActionState({
    request,
    me,
    requestStateInformation,
  });

  if (!actionState.isVisible) {
    return null;
  }

  return (
    <>
      <Tooltip
        title={invalidRepairDetails || ''}
        disableHoverListener={!invalidRepairDetails}
        placement="top"
      >
        <Box sx={{ height: '100%', width: '100%' }}>
          <LoadingButton
            onClick={() =>
              confirmAction({
                title: actionState.confirmationTitle!,
                message: actionState.confirmationMessage!,
                onConfirm: handleCompleteWork,
              })
            }
            fullWidth
            sx={{ height: '100%' }}
            color="primary"
            variant="contained"
            disabled={!actionState.isEnabled || !!invalidRepairDetails}
            startIcon={
              <Iconify
                icon="solar:checklist-minimalistic-bold-duotone"
                width={40}
              />
            }
          >
            {actionState.message}
          </LoadingButton>
        </Box>
      </Tooltip>
      <ActionConfirmationDialog
        open={isOpen}
        onClose={onClose}
        {...pendingAction}
      />
    </>
  );
};
