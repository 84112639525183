import { api } from '@api';
import { useClerk, useUser } from '@clerk/clerk-react';
import { RouterProvider } from '@tanstack/react-router';
import { useConvexAuth, useMutation, useQuery } from 'convex/react';
import { useEffect, useMemo } from 'react';
import { useImpersonationInfo } from 'src/hooks/useImpersonationInfo';
import { createRouter } from '../../router';

export const router = createRouter();

export function RouterProviderWithContext() {
  const { isAuthenticated, isLoading: isAuthLoading } = useConvexAuth();
  const { user, isLoaded: isUserLoaded } = useUser();
  const clerk = useClerk();

  const superAdminCheckResponse = useQuery(
    api.functions.users.checkSuperAdminInit,
    isAuthenticated && !!user ? {} : 'skip'
  );

  const needsSuperAdminInit = superAdminCheckResponse?.needsSuperAdminInit;

  const me = useQuery(
    api.functions.users.getMe,
    isAuthenticated && needsSuperAdminInit === false ? {} : 'skip'
  );

  const {
    impersonatingServiceProvider,
    impersonatingFleet,
    impersonatingThirdParty,
  } = useImpersonationInfo();

  const initSuperAdmin = useMutation(api.functions.users.initSuperAdminUser);

  // Handle superadmin initialization
  useEffect(() => {
    if (needsSuperAdminInit) {
      initSuperAdmin({}).catch(error => {
        console.error('Failed to initialize superadmin:', error);
      });
    }
  }, [needsSuperAdminInit, initSuperAdmin]);

  // Different loading logic for authenticated vs non-authenticated users
  const isLoading = useMemo(() => {
    // If authenticated, we need both user and me to be loaded
    if (isAuthenticated) {
      return (
        isAuthLoading ||
        !isUserLoaded ||
        !me ||
        !user ||
        needsSuperAdminInit === undefined
      );
    }

    // If not authenticated, just wait for auth check to complete
    return isAuthLoading;
  }, [
    isAuthenticated,
    isAuthLoading,
    isUserLoaded,
    me,
    user,
    needsSuperAdminInit,
  ]);

  // If loading, show loading screen
  if (isLoading) {
    return null;
  }

  return (
    <RouterProvider
      router={router}
      context={{
        isAuthenticated,
        user,
        clerk,
        me,
        impersonatingServiceProvider,
        impersonatingThirdParty,
        impersonatingFleet,
      }}
    />
  );
}
