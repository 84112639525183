import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, useStore } from '@tanstack/react-form';
import { useMutation } from 'convex/react';
import { useState } from 'react';
import { toast } from 'sonner';
import { api } from 'src/convex/_generated/api';
import { ServiceSubmissionInput } from 'src/convex/functions/helpers/request/serviceInitiated';
import { EnhancedRequest } from 'src/convex/functions/requests';
import { EnhancedUser } from 'src/convex/functions/users';
import { MappedVinDecodedFields } from 'src/convex/schema/entities/vehicles';
import {
  decodeVin,
  mapVinData,
} from 'src/convex/utils/vinDecoder/vinDecoder.utils';
import { phoneSchema } from 'src/convex/zodHelpers/phoneSchema';
import { useDebouncedMutation } from 'src/hooks/useDebouncedMutation';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Label } from 'src/minimal-theme/components/label';
import { PhoneInput } from 'src/minimal-theme/components/phone-input';
import { AddressInput } from 'src/sections/request/AddressInput';
import { RequestDetailsCard } from 'src/sections/request/RequestDetailsCard';
import { RequestLocationCard } from 'src/sections/request/RequestLocationCard';
import { RequestMapDisplay } from 'src/sections/request/RequestMapDisplay';
import { useAddressSearch } from 'src/utils/useAddressSearch';
import { z } from 'zod';

type ThirdPartyCallCenterFormProps = {
  request: EnhancedRequest;
  me: EnhancedUser;
  isDialog?: boolean;
  onClose?: () => void;
};

export const ThirdPartyCallCenterForm = ({
  request,
  me,
  isDialog,
  onClose,
}: ThirdPartyCallCenterFormProps) => {
  const { searchResults, coordinates, setCoordinates, searchAddress } =
    useAddressSearch();

  const [isDecoding, setIsDecoding] = useState<boolean>(false);

  const submitRequest = useMutation(
    api.functions.requests.submitThirdPartyRequest
  );

  const updateRequest = useMutation(api.functions.requests.updateRequest);
  const updateDraftRequest = useMutation(
    api.functions.requests.updateDraftRequest
  );

  const debouncedUpdateDraftRequest = useDebouncedMutation(updateDraftRequest);

  const form = useForm<ServiceSubmissionInput>({
    defaultValues: {
      tempDriverFirstName: request.tempDriverFirstName || '',
      tempDriverLastName: request.tempDriverLastName || '',
      tempDriverPhone: request.tempDriverPhone || '',
      tempDriverEmail: request.tempDriverEmail || '',

      fleetReferenceNumber: request.fleetReferenceNumber || '',
      tempVehicleUnitNumber: request.tempVehicleUnitNumber || '',

      isDriverCalling: !!request.isDriverCalling,
      tempFleetDispatchFirstName: request.tempFleetDispatchFirstName || '',
      tempFleetDispatchLastName: request.tempFleetDispatchLastName || '',
      tempFleetDispatchPhone: request.tempFleetDispatchPhone || '',
      tempFleetDispatchEmail: request.tempFleetDispatchEmail || '',

      address: request.address || '',
      longitude: request.longitude || 0,
      latitude: request.latitude || 0,
      tempVehicleInfo: request.tempVehicleInfo || { vin: '' },
      description: request.description || '',
    },
    onSubmit: async ({ value }) => {
      console.log(value);
      try {
        if (isDialog) {
          const resp = await updateRequest({
            requestId: request._id,
            ...value,
            tempVehicleInfo: value.tempVehicleInfo as MappedVinDecodedFields,
            longitude: coordinates?.long ?? request.longitude,
            latitude: coordinates?.lat ?? request.latitude,
            tempDriverEmail: value.tempDriverEmail || undefined,
            tempFleetDispatchPhone: value.tempFleetDispatchPhone || undefined,
            tempFleetDispatchEmail: value.tempFleetDispatchEmail || undefined,
          });

          if (resp.success) {
            toast.success(resp.message);
            onClose?.();
            return;
          }
        }

        if (!coordinates) {
          toast.error('Please enter a valid address');
          return;
        }

        if (!value.tempVehicleInfo) {
          toast.error('Please enter a valid VIN');
          return;
        }

        const resp = await submitRequest({
          input: {
            ...value,
            tempVehicleInfo: value.tempVehicleInfo as MappedVinDecodedFields,
            requestId: request._id,
            longitude: coordinates.long,
            latitude: coordinates.lat,

            tempDriverEmail: value.tempDriverEmail || undefined,
            tempFleetDispatchPhone: value.tempFleetDispatchPhone || undefined,
            tempFleetDispatchEmail: value.tempFleetDispatchEmail || undefined,

            // TODO: SP timezone, better way to get drivers?
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        });

        if (resp.success) {
          toast.success(resp.message);
        } else {
          toast.error(resp.error);
        }
      } catch (error) {
        toast.error("Couldn't submit request. Please try again.");
      }
    },
  });

  const { Field, Subscribe, handleSubmit, store } = form;

  const isDriverCalling = useStore(store, s => s.values.isDriverCalling);
  const tempVehicleInfo = useStore(store, s => s.values.tempVehicleInfo);
  const address = useStore(store, s => s.values.address);

  const vinDecodedAndValid = !!tempVehicleInfo?.isVinDecoded;

  const validAddress = searchResults.some(
    result => result.place_name === address
  );

  const detailsContent = (
    <Box columnGap={2} rowGap={3} display="grid" sx={{ mt: 2 }}>
      <Field
        name="tempVehicleInfo.vin"
        validators={{
          onChange: z.string().min(17, 'VIN must be 17 characters'),
        }}
        listeners={{
          onChange: async ({ value, fieldApi }) => {
            if (!isDialog) {
              if (value === '') {
                debouncedUpdateDraftRequest({
                  requestId: request._id,
                  tempVehicleInfo: {
                    vin: '',
                  },
                });
              }
            }
            if (value.length === 17 && !vinDecodedAndValid) {
              try {
                setIsDecoding(true);
                const vinData = await decodeVin(value);
                setIsDecoding(false);

                const mappedData = mapVinData(value, vinData);

                fieldApi.form.setFieldValue('tempVehicleInfo', {
                  ...mappedData,
                });
                if (!isDialog) {
                  debouncedUpdateDraftRequest({
                    requestId: request._id,
                    tempVehicleInfo: {
                      ...mappedData,
                    },
                  });
                }
              } catch (error) {
                setIsDecoding(false);
                // Handle error
              }
            }
          },
        }}
        children={({ state, handleChange }) => (
          <Stack direction="row" spacing={1} alignItems="top">
            <TextField
              label="VIN"
              placeholder="12345678901234567"
              required
              disabled={state.value.length === 17}
              fullWidth
              value={state.value}
              onChange={e => handleChange(e.target.value)}
              error={state.meta.errors.length > 0}
              helperText={state.meta.errors[0]}
            />
            <Label
              sx={{ minHeight: 54, px: 3, minWidth: 130 }}
              startIcon={
                vinDecodedAndValid ? (
                  <Iconify icon="solar:check-circle-bold-duotone" />
                ) : isDecoding ? (
                  <Iconify icon="solar:key-minimalistic-bold-duotone" />
                ) : (
                  <Iconify icon="solar:close-square-bold-duotone" />
                )
              }
              color={
                vinDecodedAndValid
                  ? 'success'
                  : isDecoding
                    ? 'secondary'
                    : 'error'
              }
            >
              {vinDecodedAndValid
                ? 'Valid VIN'
                : isDecoding
                  ? 'Decoding'
                  : 'Invalid Vin'}
            </Label>
            <Button
              variant="contained"
              onClick={() => handleChange('')}
              sx={{ minWidth: 100, maxHeight: 54 }}
            >
              Clear
            </Button>
          </Stack>
        )}
      />
      <FormControl>
        <Field
          name="isDriverCalling"
          listeners={{
            onChange: ({ value, fieldApi }) => {
              if (!!value) {
                fieldApi.form.deleteField('tempFleetDispatchFirstName');
                fieldApi.form.deleteField('tempFleetDispatchLastName');
                fieldApi.form.deleteField('tempFleetDispatchPhone');
                fieldApi.form.deleteField('tempFleetDispatchEmail');
              }

              if (isDialog) {
                return;
              }
              debouncedUpdateDraftRequest({
                isDriverCalling: value,
                tempFleetDispatchEmail: null,
                tempFleetDispatchFirstName: null,
                tempFleetDispatchLastName: null,
                tempFleetDispatchPhone: null,
                requestId: request._id,
              });
            },
          }}
          children={({ state, handleChange }) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.value}
                  onChange={e => handleChange(e.target.checked)}
                />
              }
              label="Driver Calling Directly (No Fleet Dispatcher)"
            />
          )}
        />
      </FormControl>

      <>
        <Typography variant="h6">Driver Information</Typography>
        <Stack direction="row" spacing={2}>
          <Field
            name="tempDriverFirstName"
            validators={{
              onChange: z.string().min(1, { message: 'First name required' }),
            }}
            listeners={{
              onChange: ({ value }) => {
                if (isDialog) {
                  return;
                }
                debouncedUpdateDraftRequest({
                  tempDriverFirstName: value,
                  requestId: request._id,
                });
              },
            }}
            children={({ state, handleChange, handleBlur }) => (
              <TextField
                fullWidth
                label="First Name"
                required
                value={state.value}
                onBlur={handleBlur}
                onChange={e => handleChange(e.target.value)}
                error={state.meta.errors.length > 0}
                helperText={state.meta.errors[0]}
              />
            )}
          />
          <Field
            name="tempDriverLastName"
            validators={{
              onChange: z.string().min(1, { message: 'Last name required' }),
            }}
            listeners={{
              onChange: ({ value }) => {
                if (isDialog) {
                  return;
                }
                debouncedUpdateDraftRequest({
                  tempDriverLastName: value,
                  requestId: request._id,
                });
              },
            }}
            children={({ state, handleChange, handleBlur }) => (
              <TextField
                fullWidth
                label="Last Name"
                required
                onBlur={handleBlur}
                value={state.value}
                onChange={e => handleChange(e.target.value)}
                error={state.meta.errors.length > 0}
                helperText={state.meta.errors[0]}
              />
            )}
          />
        </Stack>
        <Field
          name="tempDriverPhone"
          validators={{
            onChange: phoneSchema,
          }}
          // TODO: Need to figure out the best way to async submit on valid phone, then clear with undefined when appropriate
          children={({ state, handleChange, handleBlur }) => (
            <PhoneInput
              required
              placeholder="(123) 456-7890"
              fullWidth
              error={state.meta.errors.length > 0}
              value={state.value ?? ''}
              onChange={newValue => handleChange(newValue || '')}
              onBlur={handleBlur}
              helperText={state.meta.errors[0]}
              label="Phone"
            />
          )}
        />
        <Field
          name="tempDriverEmail"
          validators={{
            onChange: z.string().email().optional().or(z.literal('')),
          }}
          children={({ state, handleChange, handleBlur }) => (
            <TextField
              fullWidth
              label="Email"
              value={state.value}
              onBlur={handleBlur}
              onChange={e => handleChange(e.target.value)}
              error={state.meta.errors.length > 0}
              helperText={state.meta.errors[0]}
            />
          )}
        />
      </>

      {!isDriverCalling && (
        <>
          <Typography variant="h6">Fleet Dispatcher Information</Typography>
          <Stack direction="row" spacing={1}>
            <Field
              name="tempFleetDispatchFirstName"
              validators={{
                onChange: z.string().min(1, { message: 'First name required' }),
              }}
              listeners={{
                onChange: ({ value }) => {
                  if (isDialog) {
                    return;
                  }
                  debouncedUpdateDraftRequest({
                    tempFleetDispatchFirstName: value,
                    requestId: request._id,
                  });
                },
              }}
              children={({ state, handleChange, handleBlur }) => (
                <TextField
                  fullWidth
                  label="First Name"
                  required
                  onBlur={handleBlur}
                  value={state.value}
                  onChange={e => handleChange(e.target.value)}
                  error={state.meta.errors.length > 0}
                  helperText={state.meta.errors[0]}
                />
              )}
            />
            <Field
              name="tempFleetDispatchLastName"
              validators={{
                onChange: z.string().min(1, { message: 'Last name required' }),
              }}
              listeners={{
                onChange: ({ value }) => {
                  if (isDialog) {
                    return;
                  }
                  debouncedUpdateDraftRequest({
                    tempFleetDispatchLastName: value,
                    requestId: request._id,
                  });
                },
              }}
              children={({ state, handleChange, handleBlur }) => (
                <TextField
                  fullWidth
                  label="Last Name"
                  required
                  onBlur={handleBlur}
                  value={state.value}
                  onChange={e => handleChange(e.target.value)}
                  error={state.meta.errors.length > 0}
                  helperText={state.meta.errors[0]}
                />
              )}
            />
          </Stack>

          <Field
            name="tempFleetDispatchPhone"
            validators={{
              onChange: phoneSchema,
            }}
            children={({ state, handleChange, handleBlur }) => (
              <PhoneInput
                required
                placeholder="(123) 456-7890"
                fullWidth
                value={state.value ?? ''}
                onChange={newValue => handleChange(newValue || '')}
                onBlur={handleBlur}
                error={state.meta.errors.length > 0}
                helperText={state.meta.errors[0]}
                label="Phone"
              />
            )}
          />
          <Field
            name="tempFleetDispatchEmail"
            validators={{
              onChange: z.string().email().optional().or(z.literal('')),
            }}
            children={({ state, handleChange, handleBlur }) => (
              <TextField
                fullWidth
                label="Email"
                value={state.value}
                onBlur={handleBlur}
                onChange={e => handleChange(e.target.value)}
                error={state.meta.errors.length > 0}
                helperText={state.meta.errors[0]}
              />
            )}
          />
        </>
      )}
    </Box>
  );

  // TODO: fleetReferenceNumber doesn't really belong here, but will do for now
  const complaintContent = (
    <Box columnGap={2} rowGap={3} display="grid" sx={{ mt: 2 }}>
      <FormControl>
        <Field
          name="fleetReferenceNumber"
          listeners={{
            onChange: ({ value }) =>
              debouncedUpdateDraftRequest({
                requestId: request._id,
                fleetReferenceNumber: value ?? '',
              }),
          }}
          children={({ state, handleChange, handleBlur }) => (
            <TextField
              label="Fleet Reference Number"
              placeholder="Optionally provide a fleet reference number"
              variant="outlined"
              fullWidth
              value={state.value}
              error={state.meta.errors.length > 0}
              helperText={state.meta.errors[0]}
              onChange={e => handleChange(e.target.value)}
              onBlur={handleBlur}
            />
          )}
        />
      </FormControl>
      <FormControl>
        <Field
          name="tempVehicleUnitNumber"
          listeners={{
            onChange: ({ value }) =>
              debouncedUpdateDraftRequest({
                requestId: request._id,
                tempVehicleUnitNumber: value ?? '',
              }),
          }}
          children={({ state, handleChange, handleBlur }) => (
            <TextField
              label="Unit #"
              placeholder="Optionally provide a unit number"
              variant="outlined"
              fullWidth
              value={state.value}
              error={state.meta.errors.length > 0}
              helperText={state.meta.errors[0]}
              onChange={e => handleChange(e.target.value)}
              onBlur={handleBlur}
            />
          )}
        />
      </FormControl>
      <FormControl>
        <Field
          name="description"
          validators={{
            onChange: z
              .string()
              .min(1, { message: 'Please provide a description' }),
          }}
          listeners={{
            onChange: ({ value }) => {
              if (isDialog) {
                return;
              }
              debouncedUpdateDraftRequest({
                requestId: request._id,
                description: value,
              });
            },
          }}
          children={({ state, handleChange, handleBlur }) => (
            <TextField
              label="Request Complaint"
              placeholder="Please provide a detailed description of the complaint"
              variant="outlined"
              required
              fullWidth
              multiline
              rows={3}
              value={state.value}
              error={state.meta.errors.length > 0}
              helperText={state.meta.errors[0]}
              onChange={e => handleChange(e.target.value)}
              onBlur={handleBlur}
            />
          )}
        />
      </FormControl>
    </Box>
  );

  const addressField = (
    <FormControl fullWidth>
      <Field
        name="address"
        validators={{
          onChange: z.string().min(1, { message: 'Address required' }),
        }}
        children={({ state, handleChange }) => (
          <AddressInput
            value={state.value}
            onChange={value => {
              handleChange(value || '');
            }}
            error={state.meta.errors.length > 0 || !validAddress}
            helperText={state.meta.errors[0]}
            searchResults={searchResults}
            searchAddress={searchAddress}
            onCoordinatesChange={coords => {
              setCoordinates(
                coords ? { lat: coords.latitude, long: coords.longitude } : null
              );
              // TODO: Should set this async, but need to think of a better pattern for clearing values on the backend
            }}
            required
          />
        )}
      />
    </FormControl>
  );

  const locationContent = (
    <Stack spacing={2}>
      {coordinates && (
        <RequestMapDisplay
          coordinatesOverride={{
            latitude: coordinates.lat,
            longitude: coordinates.long,
          }}
        />
      )}
    </Stack>
  );

  if (isDialog) {
    return (
      <>
        <DialogContent>
          {complaintContent}
          <Box sx={{ mt: 3 }}>{addressField}</Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Subscribe
            selector={state => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => (
              <LoadingButton
                variant="contained"
                loading={isSubmitting}
                disabled={!canSubmit}
                onClick={() => handleSubmit()}
              >
                Save
              </LoadingButton>
            )}
          />
        </DialogActions>
      </>
    );
  }

  return (
    <>
      <RequestDetailsCard>
        {detailsContent} {complaintContent}
      </RequestDetailsCard>
      <RequestLocationCard
        subheader={`Your selected vehicle's location information`}
      >
        <Stack spacing={2}>
          {addressField}
          {locationContent}
        </Stack>
      </RequestLocationCard>
      {request.status === 'DRAFT' && (
        <Box width="100%" display="flex" justifyContent="center">
          <Subscribe
            selector={state => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => {
              return (
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="large"
                  loading={isSubmitting}
                  fullWidth
                  disabled={!canSubmit || !vinDecodedAndValid || !validAddress}
                  onClick={() => handleSubmit()}
                >
                  Submit And Find A Service Provider
                </LoadingButton>
              );
            }}
          />
        </Box>
      )}
    </>
  );
};
