import { zid } from 'convex-helpers/server/zod';
import { z } from 'zod';
import { Doc, Id } from '../../../_generated/dataModel';
import { MutationCtx } from '../../../_generated/server';
import {
  createHistoryEntry,
  messageTemplates,
} from '../../../schema/entities/requests/requestHistory';

export const baseSubmissionSchema = z.object({
  description: z.string().min(1),
  timezone: z.string(),
  requestId: zid('requests'),
  fleetReferenceNumber: z.string().optional(),
});

export type BaseSubmissionInput = z.infer<typeof baseSubmissionSchema>;

type CreateRequestSubmissionHistoryProps = {
  ctx: MutationCtx;
  requestId: Id<'requests'>;
  user: Doc<'users'>;
  userRole: Doc<'roleDefinitions'>;
  dispatcherAssigned?: boolean;
  driver?: Doc<'users'>;
};

export async function createRequestSubmissionHistory({
  ctx,
  requestId,
  user,
  userRole,
  dispatcherAssigned,
  driver,
}: CreateRequestSubmissionHistoryProps) {
  if (dispatcherAssigned && driver) {
    await createHistoryEntry(ctx.db, {
      requestId,
      type: 'WORKFLOW_TRANSITION',
      userId: user._id,
      userRole: userRole._id,
      messageComponents:
        messageTemplates.fleetDispatcherAssignedToDriverToConfirmLocation({
          dispatcherName: user.clerkUser.fullName,
          dispatcherRole: userRole.type,
          driverName: driver.clerkUser.fullName,
          driverRole: driver.primaryRoleType!,
        }),
      visibleToRoles: [],
    });
  } else {
    await createHistoryEntry(ctx.db, {
      requestId,
      type: 'REQUEST_CREATED',
      userId: user._id,
      userRole: userRole._id,
      messageComponents: messageTemplates.requestCreated({
        creatorName: user.clerkUser.fullName,
        creatorRole: userRole.type,
      }),
      visibleToRoles: [],
    });
  }
}
