import { Box, ListItemText, Stack } from '@mui/material';
import { EnhancedRequest } from 'src/convex/functions/requests';
import { EnhancedUser } from 'src/convex/functions/users';
import { getVehicleDisplayName } from 'src/convex/schema/entities/vehicles';
import { RequestDetailsCard } from 'src/sections/request/RequestDetailsCard';
import { RequestLocationCard } from 'src/sections/request/RequestLocationCard';
import { RequestMapDisplay } from 'src/sections/request/RequestMapDisplay';

type RequestDisplayViewProps = {
  request: EnhancedRequest;
  me: EnhancedUser;
};

export const RequestDisplayView = ({
  request,
  me,
}: RequestDisplayViewProps) => {
  let fleetReferenceNumber = request.fleetReferenceNumber ?? 'N/A';
  let tempVehicleUnitNumber = request.tempVehicleUnitNumber ?? 'N/A';

  if (fleetReferenceNumber.length === 0) {
    fleetReferenceNumber = 'N/A';
  }

  if (tempVehicleUnitNumber.length === 0) {
    tempVehicleUnitNumber = 'N/A';
  }
  return (
    <>
      <RequestDetailsCard subheader="">
        <Stack spacing={2}>
          <ListItemText primary="Description" secondary={request.description} />
          <ListItemText
            primary="Fleet Reference #"
            secondary={fleetReferenceNumber}
          />
          <ListItemText
            primary="Unit"
            secondary={
              request.vehicle
                ? getVehicleDisplayName(request.vehicle)
                : `VIN: ${request.tempVehicleInfo?.vin}, Unit #: ${tempVehicleUnitNumber}`
            }
          />
          <ListItemText
            primary="Driver"
            secondary={
              request.activeDriver?.clerkUser.fullName ??
              `${request.tempDriverFirstName} ${request.tempDriverLastName}`
            }
          />
        </Stack>
      </RequestDetailsCard>

      <RequestLocationCard subheader="">
        <Box columnGap={2} rowGap={3} display="grid">
          <ListItemText primary="Address" secondary={request.address} />
          {request.address && (
            <RequestMapDisplay
              driverVehicle={request.vehicle}
              technician={request.technician}
              coordinatesOverride={
                !!request.longitude && !!request.latitude
                  ? {
                      longitude: request.longitude,
                      latitude: request.latitude,
                    }
                  : undefined
              }
            />
          )}
        </Box>
      </RequestLocationCard>
    </>
  );
};
