import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useScheduledActions } from 'src/hooks/useScheduledActions';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { RequestActionProps } from 'src/sections/request/types/RequestActionProps';
import { RequestFindMechanic } from 'src/sections/request/view/request-find-mechanic';
import { InviteMechanicViaRequestDialog } from 'src/sections/request/view/request-invite-mechanic-request-dialog';

export const FindMechanicAction = ({
  request,
  me,
  requestStateInformation,
}: RequestActionProps) => {
  const [error, setError] = useState<string | null>(null);
  const [isAssigningMechanic, setIsAssigningMechanic] =
    useState<boolean>(false);
  const { watchIds } = useScheduledActions();
  const [isInvitingMechanic, setIsInvitingMechanic] = useState<boolean>(false);

  const { isAssignedToMe, isServiceDispatcher, needsMechanic } =
    requestStateInformation;

  const isVisible = needsMechanic;
  const isEnabled = isAssignedToMe && isServiceDispatcher;

  const handleFindAMechanic = () => {
    setIsAssigningMechanic(true);
  };

  const handleInviteMechanic = () => {
    setIsAssigningMechanic(false);
    setIsInvitingMechanic(true);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <LoadingButton
        onClick={handleFindAMechanic}
        fullWidth
        sx={{ height: '100%' }}
        color="primary"
        variant="contained"
        disabled={!isEnabled || isAssigningMechanic}
        startIcon={<Iconify icon="solar:wheel-angle-bold-duotone" width={40} />}
      >
        {isEnabled ? 'Find Technician' : 'Waiting On Service Dispatch'}
      </LoadingButton>

      <InviteMechanicViaRequestDialog
        open={isInvitingMechanic}
        onClose={() => setIsInvitingMechanic(false)}
        watchIds={watchIds}
      />
      <RequestFindMechanic
        open={isAssigningMechanic}
        inviteMechanic={handleInviteMechanic}
        onClose={() => {
          setIsAssigningMechanic(false);
          setError(null);
        }}
        onError={errorMessage => {
          setError(errorMessage);
          setIsAssigningMechanic(false);
        }}
      />
    </>
  );
};
