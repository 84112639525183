import { Card, CardContent, CardHeader } from '@mui/material';

export const RequestDetailsCard = ({
  children,
  title = 'Request Details',
  subheader = 'Enter the details of the request',
  action,
}: {
  children: React.ReactNode;
  title?: string;
  subheader?: string;
  action?: React.ReactNode;
}) => (
  <Card>
    <CardHeader title={title} subheader={subheader} action={action} />
    <CardContent>{children}</CardContent>
  </Card>
);
