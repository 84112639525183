import { useCallback, useState } from 'react';

export type ActionConfirmationParams = {
  onConfirm: () => Promise<void>;
  title: string;
  message: string;
  disableConfirm?: boolean;
  additionalContent?: React.ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
};

export const useActionConfirmationDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [pendingAction, setPendingAction] = useState<ActionConfirmationParams>({
    onConfirm: async () => {},
    title: '',
    message: '',
  });

  const confirmAction = useCallback((params: ActionConfirmationParams) => {
    setPendingAction(params);
    setIsOpen(true);
  }, []);

  return {
    isOpen,
    pendingAction,
    confirmAction,
    onClose: () => setIsOpen(false),
  };
};
