import { Doc } from '../../_generated/dataModel';

export async function generateVehicleSearchFields(unit: Doc<'vehicles'>) {
  const components = [
    unit.vin,
    unit.serialNumber,
    unit.category,
    unit.type,
    unit.maker,
    unit.model,
    unit.color,
    unit.modelYear,
    unit.unitNumber,
    unit.manufacturer,
  ].filter(Boolean);

  return {
    searchQueryText: components.join(' ').toLowerCase(),
  };
}
