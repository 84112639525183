import { RequestActionProps } from 'src/sections/request/types/RequestActionProps';

export const getAcceptRequestActionState = ({
  requestStateInformation,
}: RequestActionProps) => {
  const {
    isFleetDispatchQueue,
    isServiceProviderQueue,
    isWaitingForTechnician,
    isFleetDispatcher,
    isServiceDispatcher,
    isAssignedToMe,
  } = requestStateInformation;

  const isVisible =
    isFleetDispatchQueue || isServiceProviderQueue || isWaitingForTechnician;

  if (!isVisible) {
    return {
      isVisible: false,
      isEnabled: false,
      message: '',
    };
  }

  const acceptMessage = 'Accept Request';
  let canPerform =
    (isFleetDispatchQueue && isFleetDispatcher) ||
    (isServiceProviderQueue && isServiceDispatcher) ||
    isAssignedToMe;

  let waitingMessage = isWaitingForTechnician
    ? 'Technician'
    : isFleetDispatchQueue
      ? 'Fleet Dispatcher'
      : 'Service Provider Dispatcher';

  return {
    isVisible: true,
    isEnabled: canPerform,
    message: canPerform ? acceptMessage : `Waiting On ${waitingMessage}`,
    confirmationTitle: acceptMessage,
    confirmationMessage: 'Are you sure you want to accept this request?',
  };
};
