import { Alert, Button, ListItemText, Menu, MenuItem } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import { getStepTypeDisplay } from 'src/convex/schema/entities/requests/requests';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { useActiveAssignments } from 'src/minimal-theme/hooks/useAssignmentTracking';

export const AssignmentTrackingAlert = () => {
  const { activeAssignments } = useActiveAssignments();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!activeAssignments || activeAssignments.length === 0) {
    return null;
  }

  return (
    <Alert
      severity="warning"
      icon={<Iconify icon="solar:map-point-wave-bold" />}
      sx={{ fontWeight: 700, ml: 2 }}
      action={
        <>
          <Button
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            size="small"
          >
            View
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {activeAssignments.map(a => (
              <MenuItem
                key={a._id}
                onClick={() => {
                  navigate({
                    to: `/dashboard/requests/$requestId`,
                    params: { requestId: a._id },
                  });
                  handleClose();
                }}
              >
                <ListItemText
                  primary={a.caseNumber}
                  secondary={getStepTypeDisplay(a.currentStepType)}
                  primaryTypographyProps={{
                    variant: 'body2',
                    fontWeight: 600,
                  }}
                  secondaryTypographyProps={{
                    variant: 'caption',
                  }}
                />
              </MenuItem>
            ))}
          </Menu>
        </>
      }
    >
      {`${activeAssignments.length} Active Assignment${
        activeAssignments.length > 1 ? 's' : ''
      }`}
    </Alert>
  );
};
