import { api } from '@api';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useLoaderData, useParams } from '@tanstack/react-router';
import { useMutation, useQuery } from 'convex/react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Id } from 'src/convex/_generated/dataModel';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Label } from 'src/minimal-theme/components/label';
import { toast } from 'src/minimal-theme/components/snackbar';
import { ActionConfirmationDialog } from 'src/sections/request/actions/ActionConfirmationDialog';
import { useActionConfirmationDialog } from 'src/sections/request/actions/hooks/useActionConfirmationDialog';
import { RequestTabsContent } from 'src/sections/request/RequestTabs';
import { RequestParticipants } from 'src/sections/request/view/request-participants';
import { RequestStepInformation } from 'src/sections/request/view/request-step-information';
import { getRequestStatusColor } from 'src/utils/helper';
import { RequestWorkflowSteps } from './request-workflow-steps';

export const RequestViewForm = () => {
  const { confirmAction, isOpen, onClose, pendingAction } =
    useActionConfirmationDialog();
  const [initialActiveChecked, setInitialActiveChecked] = useState(false);
  const params = useParams({ from: '/_auth/dashboard/requests/$requestId' });

  const sendPing = useMutation(
    api.functions.requests.sendPingReminderToAssignee
  );
  const me = useQuery(api.functions.users.getMe);

  const loaderData = useLoaderData({
    from: '/_auth/dashboard/requests/$requestId',
  });

  const realTimeRequest = useQuery(api.functions.requests.getRequest, {
    requestId: params.requestId as Id<'requests'>,
  });

  // Use loader data while query is loading, then use real-time data
  const request = realTimeRequest ?? loaderData;

  const handleSendPing = async () => {
    if (!request) return;
    try {
      const resp = await sendPing({ requestId: request?._id });
      if (resp.success) {
        toast.success(resp.message);
      }
    } catch (e) {
      toast.error('Something went wrong with your ping request');
      throw e; // Important: rethrow so the dialog can handle the error state
    }
  };

  // For when transitioning from draft to active initially
  useEffect(() => {
    if (request?.status === 'ACTIVE' && !initialActiveChecked) {
      setInitialActiveChecked(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [request?.status, initialActiveChecked]);

  if (!request || !me) {
    return null;
  }

  const canPing =
    (me?._id === request.activeFleetDispatcherId ||
      me?._id === request.activeServiceDispatcherId ||
      me?._id === request.activeBrokerageDispatcherId) &&
    request.currentAssignedToId &&
    me?._id !== request.currentAssignedToId;

  const isNotDraft = request.status !== 'DRAFT';

  return (
    <Stack spacing={2} maxWidth="lg">
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={1}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
      >
        <Typography variant="h4">Case # {request.caseNumber}</Typography>
        <Label color={getRequestStatusColor(request.status)}>
          {request.status}
        </Label>
        {request.status !== 'DRAFT' && (
          <Label
            color="info"
            startIcon={<Iconify icon="solar:clock-square-bold-duotone" />}
          >
            {`CREATED: ${dayjs(request._creationTime).format('MMM D, YYYY h:mm A')}`}
          </Label>
        )}
      </Stack>
      {isNotDraft && <RequestStepInformation />}

      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} width="100%">
        <Box width={{ xs: '100%', md: '70%', lg: '60%' }}>
          <RequestTabsContent request={request} me={me} />
        </Box>

        {isNotDraft && (
          <Stack
            spacing={2}
            width={{
              xs: '100%',
              md: '30%',
              lg: '40%',
            }}
          >
            {request.status !== 'COMPLETED' && (
              <Button
                variant="contained"
                size="large"
                sx={{ color: '#ff5a7c' }}
                disabled={!canPing}
                onClick={() =>
                  confirmAction({
                    title: 'Send Reminder',
                    message:
                      'Are you sure you want to a send an SMS reminder ping to the current assigned person?',
                    onConfirm: handleSendPing,
                  })
                }
                startIcon={
                  <Iconify icon="solar:bell-bing-bold-duotone" width={30} />
                }
              >
                Remind Assignee
              </Button>
            )}
            <ActionConfirmationDialog
              open={isOpen}
              onClose={onClose}
              {...pendingAction}
            />
            <RequestParticipants />
            <RequestWorkflowSteps
              currentStep={request.currentStepType ?? 'INTAKE'}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
