import { z } from 'zod';
import { Doc } from '../../../_generated/dataModel';
import { tempVehicleInfo } from '../../../schema/entities/vehicles';
import { phoneSchema } from '../../../zodHelpers/phoneSchema';
import { baseSubmissionSchema } from './base';

export function getServiceDispatcherDraftInitiationFields(
  user: Doc<'users'>,
  company: Doc<'companies'>,
  primaryLocation: Doc<'groups'>
) {
  return {
    mechanicDispatchGroupId: primaryLocation._id,
    activeServiceDispatcherId: user._id,
    serviceProviderCompanyId: company._id,
  };
}

export const serviceSubmissionInput = z.object({
  // Driver info - required except email
  tempDriverFirstName: z.string().min(1, 'First name is required'),
  tempDriverLastName: z.string().min(1, 'Last name is required'),
  tempDriverPhone: phoneSchema,
  tempDriverEmail: z.string().email().optional(),
  isDriverCalling: z.boolean(),

  // Dispatcher info - all optional at schema level (in front-end form, we enforce name/phone if there is a dispatcher involved and not driver calling directly)
  tempFleetDispatchFirstName: z.string().optional(),
  tempFleetDispatchLastName: z.string().optional(),
  tempFleetDispatchPhone: phoneSchema.optional(),
  tempFleetDispatchEmail: z.string().email().optional(),

  // Location info
  address: z.string().min(1, 'Address is required'),
  longitude: z.number(),
  latitude: z.number(),

  description: z.string().min(1),
  fleetReferenceNumber: z.string().optional(),
  tempVehicleUnitNumber: z.string().optional(),

  // Vehicle info
  tempVehicleInfo,
});

// Emails optional as providing emails on dispatch calls is non standard whereas phones is standard
export const serviceSubmissionSchema = baseSubmissionSchema.merge(
  serviceSubmissionInput
);

export type ServiceSubmissionInput = z.infer<typeof serviceSubmissionInput>;
