import { zid, zodToConvex } from 'convex-helpers/server/zod';
import { defineTable } from 'convex/server';
import { z } from 'zod';
import { Doc } from '../../_generated/dataModel';
import { clerkVerificationStatusSchema } from '../../types/clerkVerificationStatus';
import {
  locationReferencesSchema,
  locationSchema,
} from '../../types/locationSchema';
import { phoneSchema } from '../../zodHelpers/phoneSchema';
import { currencyCodeSchema } from '../enums/currencyTypes';
import { mechanicStatus } from '../enums/mechanicStatus';
import { UserRole, userRole } from '../enums/userRole';

export const emailAddressSchema = z
  .string()
  .email({ message: 'Please provide a valid email address' });
export const partialClerkVerificationSchema = z.object({
  status: clerkVerificationStatusSchema,
});

export const partialClerkEmailAddressSchema = z.object({
  emailAddress: emailAddressSchema,
  verification: partialClerkVerificationSchema.optional(),
});

export const partialClerkPhoneNumberSchema = z.object({
  phoneNumber: phoneSchema,
  verification: partialClerkVerificationSchema.optional(),
});

export const userNamesSchema = z.object({
  firstName: z.string().min(2, { message: 'First name is required' }),
  lastName: z.string().min(2, { message: 'Last name is required' }),
});

export const userSignUpSchema = userNamesSchema.extend({
  email: emailAddressSchema,
  phone: phoneSchema,
});

export const otherNameComponentsSchema = z.object({
  fullName: z.string().min(5), // 2 + 2 + space
});

export const partialClerkUserSchema = z
  .object({
    id: z.string(),
    hasImage: z.boolean().optional(),
    imageUrl: z.string(),
    primaryEmailAddress: partialClerkEmailAddressSchema,
    isSuperAdmin: z.boolean().optional(),
    companyOwner: z.boolean().optional(),
    primaryPhoneNumber: partialClerkPhoneNumberSchema,
    lastSignInAt: z.number().optional(),
  })
  .merge(userNamesSchema)
  .merge(otherNameComponentsSchema);

export const clerkUserUpdateSchema = z.object({
  id: z.string(),
  hasImage: z.boolean().optional(),
  imageUrl: z.string(),
  primaryEmailAddress: partialClerkEmailAddressSchema,
  isSuperAdmin: z.boolean().optional(),
  companyOwner: z.boolean().optional(),
  primaryPhoneNumber: partialClerkPhoneNumberSchema,
});

export type PartialClerkUser = z.infer<typeof partialClerkUserSchema>;

export const notificationTypes = z.enum([
  'DRIVER_SHARED_LOCATION',
  'FLEET_DISPATCHER_ACCEPTED_REQUEST',
  'DISPATCHER_SUBMITTED_REQUEST_TO_SERVICE_PROVIDER',
  'CONFIRM_LOCATION',
  'SERVICE_DISPATCHER_ACCEPTED_REQUEST',
  'REQUEST_CREATED_BY_FLEET_DISPATCH',
  'REQUEST_CREATED_BY_SERVICE_DISPATCH',
  'DISPATCHER_ASSIGNED_REQUEST_TO_TECHNICIAN',
  'TECHNICIAN_ACCEPTED_REQUEST',
  'TECHNICIAN_DECLINED_REQUEST',
  'TECHNICIAN_ARRIVED_AT_JOB_SITE',
  'TECHNICIAN_STARTED_WORK',
  'TECHNICIAN_COMPLETED_WORK',
  'REQUEST_COMPLETED',
  'REQUEST_CANCELLED',
  'SERVICE_PROVIDER_DECLINED_REQUEST',
  'SERVICE_PROVIDER_TIMEOUT',
]);

export type NotificationType = z.infer<typeof notificationTypes>;

export type NotificationSettingsItemInfo = {
  title: string;
  description: string;
  visibleToRoles: UserRole[];
};

export const notificationInfo: Record<
  NotificationType,
  NotificationSettingsItemInfo
> = {
  DRIVER_SHARED_LOCATION: {
    title: 'Driver Location Sharing',
    description:
      'When a driver shares their current location after dispatcher assigns to them',
    visibleToRoles: ['FLEET_DISPATCHER'],
  },
  FLEET_DISPATCHER_ACCEPTED_REQUEST: {
    title: 'Dispatcher Claims Request',
    description: 'When dispatcher assigns a request you created to themselves',
    visibleToRoles: ['DRIVER_FLEET'],
  },
  DISPATCHER_SUBMITTED_REQUEST_TO_SERVICE_PROVIDER: {
    title: 'Dispatch Assigns To Service Provider',
    description: 'When dispatcher assigns your request to a service provider',
    visibleToRoles: ['DRIVER_FLEET'],
  },
  CONFIRM_LOCATION: {
    title: 'Location Share Request',
    description:
      'When dispatch requests that you share your location for an active request',
    visibleToRoles: ['DRIVER_FLEET'],
  },
  SERVICE_DISPATCHER_ACCEPTED_REQUEST: {
    title: 'Service Provider Accepts Request',
    description:
      'When a service provider accepts a request you are participating in',
    visibleToRoles: [
      'FLEET_DISPATCHER',
      'DRIVER_FLEET',
      'THIRD_PARTY_DISPATCHER',
    ],
  },
  REQUEST_CREATED_BY_FLEET_DISPATCH: {
    title: 'Request Created By Dispatch',
    description:
      'When a dispatcher creates a request on your behalf and has started looking for a provider',
    visibleToRoles: ['DRIVER_FLEET'],
  },
  REQUEST_CREATED_BY_SERVICE_DISPATCH: {
    title: 'Request Created By Service Provider',
    description: 'When a service provider creates a request that involves you',
    visibleToRoles: ['FLEET_DISPATCHER', 'DRIVER_FLEET'],
  },
  DISPATCHER_ASSIGNED_REQUEST_TO_TECHNICIAN: {
    title: 'You Are Assigned A Request',
    description:
      'When a service dispatcher assigns you a case to work on and you need to accept or decline',
    visibleToRoles: ['TECHNICIAN_PROVIDER'],
  },
  TECHNICIAN_ACCEPTED_REQUEST: {
    title: 'Technician Accepts Request',
    description: 'When a technician has accepted a request you are involved in',
    visibleToRoles: [
      'FLEET_DISPATCHER',
      'DRIVER_FLEET',
      'SERVICE_DISPATCHER',
      'THIRD_PARTY_DISPATCHER',
    ],
  },
  TECHNICIAN_DECLINED_REQUEST: {
    title: 'Technician Declines Request',
    description:
      'When a technician has declined a request you are involved in and it has returned to service dispatch',
    visibleToRoles: [
      'FLEET_DISPATCHER',
      'DRIVER_FLEET',
      'SERVICE_DISPATCHER',
      'THIRD_PARTY_DISPATCHER',
    ],
  },
  TECHNICIAN_ARRIVED_AT_JOB_SITE: {
    title: 'Technician Arrived At Job Site',
    description:
      'When a technician has arrived at the job site and is ready to start work',
    visibleToRoles: [
      'FLEET_DISPATCHER',
      'DRIVER_FLEET',
      'SERVICE_DISPATCHER',
      'THIRD_PARTY_DISPATCHER',
    ],
  },
  TECHNICIAN_STARTED_WORK: {
    title: 'Technician Started Work',
    description:
      'When a technician has started work on a request you are involved in',
    visibleToRoles: [
      'FLEET_DISPATCHER',
      'DRIVER_FLEET',
      'SERVICE_DISPATCHER',
      'THIRD_PARTY_DISPATCHER',
    ],
  },
  TECHNICIAN_COMPLETED_WORK: {
    title: 'Technician Completed Work',
    description:
      'When a technician has filled out repair details and completed a request you are involved in',
    visibleToRoles: [
      'FLEET_DISPATCHER',
      'DRIVER_FLEET',
      'SERVICE_DISPATCHER',
      'THIRD_PARTY_DISPATCHER',
    ],
  },
  REQUEST_COMPLETED: {
    title: 'Request Completed',
    description:
      'When a request you are involved in has been completed by someone other than yourself',
    visibleToRoles: [
      'FLEET_DISPATCHER',
      'DRIVER_FLEET',
      'SERVICE_DISPATCHER',
      'TECHNICIAN_PROVIDER',
      'THIRD_PARTY_DISPATCHER',
    ],
  },
  REQUEST_CANCELLED: {
    title: 'Request Cancelled',
    description:
      'When a request you are involved in has been cancelled by someone other than yourself',
    visibleToRoles: [
      'FLEET_DISPATCHER',
      'DRIVER_FLEET',
      'SERVICE_DISPATCHER',
      'TECHNICIAN_PROVIDER',
      'THIRD_PARTY_DISPATCHER',
    ],
  },
  SERVICE_PROVIDER_DECLINED_REQUEST: {
    title: 'Service Provider Declined Request',
    description:
      'When a service provider has declined a request you are involved in',
    visibleToRoles: [
      'FLEET_DISPATCHER',
      'DRIVER_FLEET',
      'THIRD_PARTY_DISPATCHER',
    ],
  },
  SERVICE_PROVIDER_TIMEOUT: {
    title: 'Service Provider Assignment Timeout',
    description:
      'When request returned to fleet due to service provider not accepting within time limit',
    visibleToRoles: [
      'FLEET_DISPATCHER',
      'DRIVER_FLEET',
      'THIRD_PARTY_DISPATCHER',
    ],
  },
};

export const notificationSettingSchema = z.object({
  email: z.boolean(),
  sms: z.boolean(),
  push: z.boolean(),
});

export const notificationConfigSchema = z.object({
  DRIVER_SHARED_LOCATION: notificationSettingSchema,
  FLEET_DISPATCHER_ACCEPTED_REQUEST: notificationSettingSchema,
  DISPATCHER_SUBMITTED_REQUEST_TO_SERVICE_PROVIDER: notificationSettingSchema,
  CONFIRM_LOCATION: notificationSettingSchema,
  SERVICE_DISPATCHER_ACCEPTED_REQUEST: notificationSettingSchema,
  REQUEST_CREATED_BY_FLEET_DISPATCH: notificationSettingSchema,
  REQUEST_CREATED_BY_SERVICE_DISPATCH: notificationSettingSchema,
  DISPATCHER_ASSIGNED_REQUEST_TO_TECHNICIAN: notificationSettingSchema,
  TECHNICIAN_ACCEPTED_REQUEST: notificationSettingSchema,
  TECHNICIAN_DECLINED_REQUEST: notificationSettingSchema,
  TECHNICIAN_ARRIVED_AT_JOB_SITE: notificationSettingSchema,
  TECHNICIAN_STARTED_WORK: notificationSettingSchema,
  TECHNICIAN_COMPLETED_WORK: notificationSettingSchema,
  REQUEST_COMPLETED: notificationSettingSchema,
  REQUEST_CANCELLED: notificationSettingSchema.optional(), // TODO: remove optional after migration has run
  SERVICE_PROVIDER_DECLINED_REQUEST: notificationSettingSchema,
  SERVICE_PROVIDER_TIMEOUT: notificationSettingSchema,
});

export type NotificationSetting = z.infer<typeof notificationSettingSchema>;

const DEFAULT_NOTIFICATION_SETTING: NotificationSetting = {
  email: true,
  sms: true,
  push: true,
};

export const DEFAULT_NOTIFICATION_SETTINGS = {
  DRIVER_SHARED_LOCATION: DEFAULT_NOTIFICATION_SETTING,
  FLEET_DISPATCHER_ACCEPTED_REQUEST: DEFAULT_NOTIFICATION_SETTING,
  DISPATCHER_SUBMITTED_REQUEST_TO_SERVICE_PROVIDER:
    DEFAULT_NOTIFICATION_SETTING,
  CONFIRM_LOCATION: DEFAULT_NOTIFICATION_SETTING,
  SERVICE_DISPATCHER_ACCEPTED_REQUEST: DEFAULT_NOTIFICATION_SETTING,
  REQUEST_CREATED_BY_FLEET_DISPATCH: DEFAULT_NOTIFICATION_SETTING,
  REQUEST_CREATED_BY_SERVICE_DISPATCH: DEFAULT_NOTIFICATION_SETTING,
  DISPATCHER_ASSIGNED_REQUEST_TO_TECHNICIAN: DEFAULT_NOTIFICATION_SETTING,
  TECHNICIAN_ACCEPTED_REQUEST: DEFAULT_NOTIFICATION_SETTING,
  TECHNICIAN_DECLINED_REQUEST: DEFAULT_NOTIFICATION_SETTING,
  TECHNICIAN_ARRIVED_AT_JOB_SITE: DEFAULT_NOTIFICATION_SETTING,
  TECHNICIAN_STARTED_WORK: DEFAULT_NOTIFICATION_SETTING,
  TECHNICIAN_COMPLETED_WORK: DEFAULT_NOTIFICATION_SETTING,
  REQUEST_COMPLETED: DEFAULT_NOTIFICATION_SETTING,
  REQUEST_CANCELLED: DEFAULT_NOTIFICATION_SETTING,
  SERVICE_PROVIDER_DECLINED_REQUEST: DEFAULT_NOTIFICATION_SETTING,
  SERVICE_PROVIDER_TIMEOUT: DEFAULT_NOTIFICATION_SETTING,
} as const;

export type NotificationSettings = typeof DEFAULT_NOTIFICATION_SETTINGS;

export const userSchema = z
  .object({
    clerkUser: partialClerkUserSchema,
    hasVerifiedEmail: z.boolean().optional(),
    hasVerifiedPhone: z.boolean().optional(),
    about: z.string().optional(),

    rate: z.number().optional(),
    rateCurrencyType: currencyCodeSchema.optional(),

    location: locationSchema.optional(),
    // TODO: RF - figure out what this is
    pushToken: z.string().optional(),
    arePushNotificationsEnabled: z.boolean().optional(),
    // TODO: RF - does this mean total completed or total active assigned (not completed?? do we want to track both?)
    jobCount: z.number().int().default(0).optional(),

    notificationSettings: notificationConfigSchema.optional(),

    status: mechanicStatus.optional(),
    averageRating: z.number().int().default(0).optional(),
    activityRadius: z.number().int().default(10).optional(),

    primaryRoleId: zid('roleDefinitions').optional(), // Denormalized primary role
    primaryRoleType: userRole.optional(), // Cached role type for even faster lookups

    impersonatingCompanyId: zid('companies').optional(),
    impersonatingLocationId: zid('groups').optional(),

    createdByUserDuringRequest: z.boolean().optional(),
    createdFromRequestId: zid('requests').optional(),

    invitedByUser: zid('users').optional(),
    invitedByLocation: zid('groups').optional(),
    invitedByCompany: zid('companies').optional(),

    // TODO: this is temporary until we have a better way to track this, lets us not have dispatchers be able to do absolutely everything in the meantime
    // Naming is weird as we're gonna allow multiple to start just to keep it flexible
    isCompanyPrimaryAdmin: z.boolean().optional(),

    // TODO: probably remove updatedAt eventually and if we want to track versions, we do that in a separate document
    updatedAt: z.string().optional(),
    deletedAt: z.number().optional(), // TODO:... isDeleted boolean would be better.. track deletion event and date in separate document
    // In the future we will index this, migrate to false for all users, add to indexed filters, but for now we'll just JS filter at the db
    isDeleted: z.boolean().optional(),

    companyId: zid('companies').optional(),

    defaultDispatchGroupId: zid('groups').optional(), // TODO: Make NON optional after migration runs in all environments

    searchQueryText: z.string().optional(),

    // Users should only belong to a single primaryLocationGroup
    primaryLocationGroupId: zid('groups').optional(),
    // Dispatch could potentially cover multiple groups ... requests created in these groups build their feed
    /// this might not be used right away... will it be an override for the one set on "groups" or a denormalized version of that?
    dispatchCoverageGroupIds: z.array(zid('groups')).optional(),
    // TODO: For wiring up when single dispatch users are assigned to specific technicians
    dispatchForIndividualTechnicians: z.array(zid('users')).optional(),
  })
  .merge(locationReferencesSchema);

export const users = defineTable(zodToConvex(userSchema).fields)
  .index('by_clerkId', ['clerkUser.id'])
  .index('by_primaryEmail', ['clerkUser.primaryEmailAddress.emailAddress'])
  .index('by_primaryPhone', ['clerkUser.primaryPhoneNumber.phoneNumber'])
  .index('by_companyId', ['companyId'])
  .index('by_superAdmin', ['clerkUser.isSuperAdmin'])
  .index('by_companyId_and_primaryRoleType', ['companyId', 'primaryRoleType'])
  .index('by_createdByUserDuringRequest', ['createdByUserDuringRequest'])
  .index('by_primaryLocationGroupId', ['primaryLocationGroupId'])
  .index('by_defaultDispatchGroupId', ['defaultDispatchGroupId'])
  .index('by_primaryLocationGroupId_and_primaryRoleType', [
    'primaryLocationGroupId',
    'primaryRoleType',
  ])
  .index('by_defaultDispatchGroupId_and_primaryRoleType', [
    'defaultDispatchGroupId',
    'primaryRoleType',
  ])
  // TODO: eventually have the same thing on primary location, just trying to get this one case working now, more likely that the
  // dispatch locations that manage many locations will hit high numbers of users they need to pull in
  .searchIndex('searchQueryText_defaultDispatchGroupId_primaryRoleType', {
    searchField: 'searchQueryText',
    filterFields: ['defaultDispatchGroupId', 'primaryRoleType'],
  });

export const userPaymentPermissionsSchema = z.object({
  canCreateInvoices: z.boolean(),
  canManageStripeAccount: z.boolean(), // Can complete onboarding, view payouts etc
  canViewInvoices: z.boolean(),
  canCancelInvoices: z.boolean(),
});

export function isUserADispatcher(roles: Doc<'roleDefinitions'>[]) {
  return roles.some(
    r =>
      r.type === 'FLEET_DISPATCHER' ||
      r.type === 'SERVICE_DISPATCHER' ||
      r.type === 'THIRD_PARTY_DISPATCHER'
  );
}
