import { createFileRoute } from '@tanstack/react-router';
import { api } from 'src/convex/_generated/api';
import { Id } from 'src/convex/_generated/dataModel';
import Page from 'src/pages/dashboard/request/details';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';
import { handleConvexRouteQuery } from 'src/utils/handleConvexRouteQuery';

export const Route = createFileRoute('/_auth/dashboard/requests/$requestId')({
  beforeLoad: ({ context }) =>
    checkRouteAccess(context, routeAccessConfigs.allRolesExceptSuperAdmin),
  loader: async ({ context, params }) => {
    return handleConvexRouteQuery(
      () =>
        context.convex.query(api.functions.requests.getRequest, {
          requestId: params.requestId as Id<'requests'>,
        }),
      { redirectTo: '/dashboard/requests' }
    );
  },
  component: Page,
});
