import { api } from '@api';
import { createFileRoute, notFound } from '@tanstack/react-router';
import { Id } from 'src/convex/_generated/dataModel';
import Page from 'src/pages/dashboard/invoice/edit';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute('/_auth/dashboard/invoices/$invoiceId')({
  beforeLoad: ({ context }) => {
    throw notFound(); // TODO: temporary disablement of invoices to merge
    checkRouteAccess(context, routeAccessConfigs.serviceProviderOnly);
  },
  loader: async ({ context, params }: { context: any; params: any }) => {
    return await context.convex.query(api.functions.invoices.getInvoiceById, {
      invoiceId: params.invoiceId as Id<'invoices'>,
    });
  },
  component: Page,
});
