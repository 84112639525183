import {
  FormControl,
  InputAdornment,
  inputBaseClasses,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Field, FormApi } from '@tanstack/react-form';
import { useEffect } from 'react';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { fCurrency } from 'src/minimal-theme/utils/format-number';

type FormComponentProps = {
  form: FormApi<any>;
  items: InvoiceItem[];
  disabled: boolean;
};

// Add this interface to define the item structure
interface InvoiceItem {
  category: string;
  description: string;
  quantity: number;
  price: number;
  total: number;
}

export function InvoiceNewEditDetails({
  form,
  items,
  disabled,
}: FormComponentProps) {
  const INVOICE_SERVICE_OPTIONS = [
    {
      id: 'DISPATCH_FEE',
      name: 'Dispatch / Call Out Fee',
    },
    {
      id: 'TECHNICIAN_LABOR',
      name: 'Technician Labor',
    },
    {
      id: 'DIAGNOSTIC_FEE',
      name: 'Diagnostic Fee',
    },
    {
      id: 'PART',
      name: 'Part',
    },
    {
      id: 'TIRE',
      name: 'Tire',
    },
    { id: 'FET', name: 'FET Tax' },
    {
      id: 'MILEAGE_CHARGE',
      name: 'Mileage Charge',
    },
    {
      id: 'TOWING_CHARGE',
      name: 'Towing Charge',
    },
    {
      id: 'SHOP_SUPPLIES',
      name: 'Shop Supplies',
    },
    {
      id: 'MISC',
      name: 'Misc',
    },
  ];

  const values = {
    discount: 0,
    taxes: 0,
    items: form.getFieldValue('items') || [],
    category: '',
    totalAmount: 0,
  };

  const totalOnRow: number[] = values.items.map(
    (item: any) => item.quantity * item.price
  );

  const subtotal = totalOnRow.reduce((acc, num) => acc + num, 0);

  const totalAmount = subtotal - values.discount + values.taxes;

  useEffect(() => {
    form.setFieldValue('amount', totalAmount);
  }, [form, totalAmount]);

  const handleAdd = () => {
    const currentItems = items;

    const newItem: InvoiceItem = {
      category: '',
      description: '',
      quantity: 1,
      price: 0,
      total: 0,
    };

    // Force a re-render by creating a new array
    const updatedItems = [...currentItems, newItem];
    console.log('updated', updatedItems);
    form.setFieldValue('items', updatedItems);
  };

  const handleRemove = (index: number) => {
    const currentItems = items || [];
    const updatedItems = currentItems.filter(
      (_item: InvoiceItem, i: number) => i !== index
    );
    form.setFieldValue('items', updatedItems);
  };

  const renderTotal = (
    <Stack
      spacing={2}
      alignItems="flex-end"
      sx={{ mt: 3, textAlign: 'right', typography: 'body2' }}
    >
      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>Subtotal</Box>
        <Box sx={{ width: 160, typography: 'subtitle2' }}>
          {fCurrency(subtotal) || '-'}
        </Box>
      </Stack>

      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>Discount</Box>
        <Box
          sx={{ width: 160, ...(values.discount && { color: 'error.main' }) }}
        >
          {values.discount ? `- ${fCurrency(values.discount)}` : '-'}
        </Box>
      </Stack>

      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>Taxes</Box>
        <Box sx={{ width: 160 }}>
          {values.taxes ? fCurrency(values.taxes) : '-'}
        </Box>
      </Stack>

      <Stack direction="row" sx={{ typography: 'subtitle1' }}>
        <div>Total</div>
        <Box sx={{ width: 160 }}>{fCurrency(totalAmount) || '-'}</Box>
      </Stack>
    </Stack>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ color: 'text.disabled', mb: 3 }}>
        Details:
      </Typography>

      <Stack
        divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />}
        spacing={3}
      >
        <Field name="items" mode="array" form={form}>
          {field => {
            const items = field.state.value || [];

            return (
              items.length > 0 && (
                <>
                  {items.map((item: any, index: number) => {
                    return (
                      <Stack
                        key={`item-stack-${index}`}
                        alignItems="flex-end"
                        spacing={1.5}
                      >
                        <Stack
                          direction={{ xs: 'column', md: 'row' }}
                          spacing={2}
                          sx={{ width: 1 }}
                        >
                          <Field
                            name={`items[${index}].category`}
                            form={form}
                            key={`category-${index}`}
                          >
                            {subField => {
                              return (
                                <FormControl fullWidth>
                                  <InputLabel htmlFor="category-select-label">
                                    Category
                                  </InputLabel>
                                  <Select
                                    disabled={disabled}
                                    inputProps={{
                                      id: 'category-select-label',
                                    }}
                                    label="Category"
                                    value={subField.state.value || ''}
                                    onChange={event => {
                                      const selectedValue = event.target.value;
                                      subField.handleChange(selectedValue);
                                    }}
                                  >
                                    {INVOICE_SERVICE_OPTIONS.map(option => (
                                      <MenuItem
                                        key={option.id}
                                        value={option.id}
                                      >
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              );
                            }}
                          </Field>

                          <Field
                            name={`items[${index}].description`}
                            form={form}
                            key={`description-${index}`}
                          >
                            {subField => {
                              return (
                                <TextField
                                  disabled={disabled}
                                  fullWidth
                                  label="Description"
                                  value={subField.state.value}
                                  onChange={e =>
                                    subField.handleChange(e.target.value)
                                  }
                                />
                              );
                            }}
                          </Field>

                          <Field
                            name={`items[${index}].quantity`}
                            form={form}
                            key={`quantity-${index}`}
                          >
                            {subField => {
                              return (
                                <TextField
                                  disabled={disabled}
                                  label="Quantity"
                                  type="number"
                                  value={
                                    subField.state.value === 0
                                      ? ''
                                      : subField.state.value
                                  }
                                  onChange={e => {
                                    const newQuantity = Number(e.target.value);
                                    subField.handleChange(newQuantity || 0);
                                    // Get current price and update total
                                    const price =
                                      form.getFieldValue(
                                        `items[${index}].price`
                                      ) || 0;
                                    form.setFieldValue(
                                      `items[${index}].total`,
                                      newQuantity * price
                                    );
                                  }}
                                  sx={{ width: { xs: '100%', md: 300 } }}
                                />
                              );
                            }}
                          </Field>

                          <Field
                            name={`items[${index}].price`}
                            form={form}
                            key={`price-${index}`}
                          >
                            {subField => {
                              return (
                                <TextField
                                  disabled={disabled}
                                  type="number"
                                  name={`items[${index}].price`}
                                  label="Price"
                                  value={
                                    subField.state.value === 0
                                      ? ''
                                      : subField.state.value
                                  }
                                  onChange={e => {
                                    const newPrice = Number(e.target.value);
                                    subField.handleChange(newPrice || 0);
                                    // Get current quantity and update total
                                    const quantity =
                                      form.getFieldValue(
                                        `items[${index}].quantity`
                                      ) || 0;
                                    form.setFieldValue(
                                      `items[${index}].total`,
                                      quantity * newPrice
                                    );
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Box
                                          sx={{
                                            typography: 'subtitle2',
                                            color: 'text.disabled',
                                          }}
                                        >
                                          $
                                        </Box>
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{ width: { xs: '100%', md: 250 } }}
                                />
                              );
                            }}
                          </Field>

                          <Field
                            name={`items[${index}].total`}
                            form={form}
                            key={`total-${index}`}
                          >
                            {subField => {
                              return (
                                <TextField
                                  disabled
                                  type="number"
                                  name={`items[${index}].total`}
                                  label="Total"
                                  value={
                                    subField.state.value === 0
                                      ? ''
                                      : subField.state.value.toFixed(2)
                                  }
                                  onChange={event =>
                                    subField.handleChange(event.target.value)
                                  }
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Box
                                          sx={{
                                            typography: 'subtitle2',
                                            color: 'text.disabled',
                                          }}
                                        >
                                          $
                                        </Box>
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{
                                    width: { xs: '100%', md: 350 },
                                    [`& .${inputBaseClasses.input}`]: {
                                      textAlign: { md: 'right' },
                                    },
                                  }}
                                />
                              );
                            }}
                          </Field>
                        </Stack>
                        <Button
                          disabled={disabled}
                          size="small"
                          color="error"
                          startIcon={
                            <Iconify icon="solar:trash-bin-trash-bold" />
                          }
                          onClick={() => handleRemove(index)}
                        >
                          Remove
                        </Button>
                      </Stack>
                    );
                  })}
                </>
              )
            );
          }}
        </Field>
      </Stack>

      <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

      <Stack
        spacing={3}
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-end', md: 'center' }}
      >
        <Button
          disabled={disabled}
          size="small"
          color="primary"
          startIcon={<Iconify icon="mingcute:add-line" />}
          onClick={handleAdd}
          sx={{ flexShrink: 0 }}
        >
          Add Item
        </Button>
      </Stack>

      {renderTotal}
    </Box>
  );
}
