import { LoadingButton } from '@mui/lab';
import { toast } from 'sonner';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { getNavigateActionState } from 'src/sections/request/actions/utils/getNavigateActionState';
import { RequestActionProps } from 'src/sections/request/types/RequestActionProps';

export const NavigateToLocationAction = ({
  request,
  me,
  requestStateInformation,
}: RequestActionProps) => {
  const handleNavigateToLocation = async () => {
    if (!request.latitude || !request.longitude) {
      toast.error('Location coordinates not available');
      return;
    }

    const destination = `${request.latitude},${request.longitude}`;

    // iOS specific URL scheme with Google Maps fallback
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      const appleMapsUrl = `maps://maps.apple.com/?daddr=${destination}`;
      const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;

      // Try opening Apple Maps first
      window.location.href = appleMapsUrl;

      // Set a timeout to check if Apple Maps didn't open
      setTimeout(() => {
        // If we're still on the same page, Apple Maps didn't open, try Google Maps
        if (document.hidden !== true) {
          window.location.href = googleMapsUrl;
        }
      }, 500);
      return;
    }

    // Android
    if (/Android/.test(navigator.userAgent)) {
      window.location.href = `geo:${destination}`;
      return;
    }

    // Desktop/fallback - Google Maps
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${destination}`
    );
  };

  const actionState = getNavigateActionState({
    request,
    me,
    requestStateInformation,
  });

  if (!actionState.isVisible) {
    return null;
  }

  return (
    <LoadingButton
      type="submit"
      variant="contained"
      fullWidth
      sx={{ height: '100%' }}
      onClick={handleNavigateToLocation}
      startIcon={<Iconify icon="gis:route" width={40} />}
    >
      Navigate To Location
    </LoadingButton>
  );
};
