/**
 * https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore?tab=readme-ov-file#_flatten
 * https://github.com/you-dont-need-x/you-dont-need-lodash
 */

// ----------------------------------------------------------------------

import type { requestStatusType } from 'src/convex/schema/enums/requestStatusType';

export function flattenArray<T>(list: T[], key = 'children'): T[] {
  let children: T[] = [];

  const flatten = list?.map((item: any) => {
    if (item[key] && item[key].length) {
      children = [...children, ...item[key]];
    }
    return item;
  });

  return flatten?.concat(
    children.length ? flattenArray(children, key) : children
  );
}

// ----------------------------------------------------------------------

export function flattenDeep(array: any): any[] {
  const isArray = array && Array.isArray(array);

  if (isArray) {
    return array.flat(Infinity);
  }
  return [];
}

// ----------------------------------------------------------------------

export function orderBy<T>(
  array: T[],
  properties: (keyof T)[],
  orders?: ('asc' | 'desc')[]
): T[] {
  return array.slice().sort((a, b) => {
    for (let i = 0; i < properties.length; i += 1) {
      const property = properties[i];
      const order = orders && orders[i] === 'desc' ? -1 : 1;

      const aValue = a[property];
      const bValue = b[property];

      if (aValue < bValue) return -1 * order;
      if (aValue > bValue) return 1 * order;
    }
    return 0;
  });
}

// ----------------------------------------------------------------------

export function keyBy<T>(
  array: T[],
  key: keyof T
): {
  [key: string]: T;
} {
  return (array || []).reduce((result, item) => {
    const keyValue = key ? item[key] : item;

    return { ...result, [String(keyValue)]: item };
  }, {});
}

// ----------------------------------------------------------------------

export function sumBy<T>(array: T[], iteratee: (item: T) => number): number {
  return array.reduce((sum, item) => sum + iteratee(item), 0);
}

// ----------------------------------------------------------------------

export function isEqual(a: any, b: any): boolean {
  if (a === null || a === undefined || b === null || b === undefined) {
    return a === b;
  }

  if (typeof a !== typeof b) {
    return false;
  }

  if (
    typeof a === 'string' ||
    typeof a === 'number' ||
    typeof a === 'boolean'
  ) {
    return a === b;
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return false;
    }

    return a.every((item, index) => isEqual(item, b[index]));
  }

  if (typeof a === 'object' && typeof b === 'object') {
    const keysA = Object.keys(a!);
    const keysB = Object.keys(b!);

    if (keysA.length !== keysB.length) {
      return false;
    }

    return keysA.every(key => isEqual(a[key], b[key]));
  }

  return false;
}

// ----------------------------------------------------------------------

function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export const merge = (target: any, ...sources: any[]): any => {
  if (!sources.length) return target;

  const source = sources.shift();

  // eslint-disable-next-line no-restricted-syntax
  for (const key in source) {
    if (isObject(source[key])) {
      if (!target[key]) Object.assign(target, { [key]: {} });
      merge(target[key], source[key]);
    } else {
      Object.assign(target, { [key]: source[key] });
    }
  }

  return merge(target, ...sources);
};

export const getStatusLabel = (status: string) => {
  switch (status) {
    case 'AVAILABLE':
      return 'Available';
    case 'OFFLINE':
      return 'Offline';
    case 'BUSY':
      return 'Busy';
    case 'MECHANIC_ON_THE_WAY':
      return 'On the way';
    case 'SUBMITTED':
      return 'Submitted';
    case 'RECEIVED':
      return 'Received';
    case 'VIEWED':
      return 'Viewed';
    case 'SCHEDULED':
      return 'Scheduled';
    case 'MECHANIC_STARTED_WORK':
      return 'Work Started';
    case 'INITIATED':
      return 'Initiated';
    case 'REJECTED_BY_MECHANIC':
      return 'Rejected by Mechanic';
    case 'APPROVED_BY_FLEET_MANAGER':
      return 'Approved by Fleet Manager';
    case 'NOTIFICATION_SMS_SENT':
      return 'SMS Sent';
    case 'ASSIGNED_TO_MECHANIC':
      return 'Assigned';
    case 'ACCEPTED':
      return 'Accepted';
    case 'UNASSIGNED_FROM_MECHANIC':
      return 'Unassigned';
    case 'CANCELLED_BY_REPAIR_SHOP':
      return 'Cancelled';
    case 'COMPLETED':
      return 'Completed';
    case 'ACTIVE':
      return 'Active';
    default:
      return 'Unknown Status';
  }
};

export const getServiceRequestStep = (status: string) => {
  switch (status) {
    case 'INITIATED':
      return 'Service Request Initiated';
    case 'SUBMITTED':
      return 'Service request submitted';
    case 'ACCEPTED':
      return 'Service request was accepted by service provider, looking for mechanic...';
    case 'MECHANIC_ON_THE_WAY':
      return 'Mechanic is on the way';
  }
};

export const getServiceRequestStepServiceProvider = (status: string) => {
  switch (status) {
    case 'SUBMITTED':
      return 'ACTION REQUIRED: Please accept the request';
    case 'ACCEPTED':
      return 'ACTION REQUIRED: Please assign a mechanic';
    case 'MECHANIC_ON_THE_WAY':
      return 'Mechanic is on the way';
  }
};

export const getRequestStatusColor = (
  status: (typeof requestStatusType)['_type']
) => {
  switch (status) {
    case 'DRAFT':
      return 'info';
    case 'ACTIVE':
      return 'info';
    case 'COMPLETED':
      return 'success';
    case 'CANCELLED':
      return 'warning';
    default:
      return 'default';
  }
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case 'INITIATED':
      return 'info';
    case 'ACCEPTED':
      return 'success';
    case 'AVAILABLE':
      return 'success';
    case 'OFFLINE':
      return 'default';
    case 'BUSY':
      return 'error';
    case 'MECHANIC_ON_THE_WAY':
      return 'warning';
    case 'SUBMITTED':
      return 'success';
    case 'RECEIVED':
      return 'success';
    case 'VIEWED':
      return 'success';
    case 'COMPLETED':
      return 'primary';
    case 'ASSIGNED_TO_MECHANIC':
      return 'success';
    case 'CANCELLED_BY_REPAIR_SHOP':
      return 'error';
    //this is used in request table filters only
    case 'ACTIVE':
      return 'success';
    default:
      return 'default';
  }
};

export const getBadgeVariant = (status: string) => {
  switch (status) {
    case 'AVAILABLE':
      return 'online';
    case 'OFFLINE':
      return 'offline';
    case 'BUSY':
      return 'busy';
    case 'MECHANIC_ON_THE_WAY':
      return 'alway';
    default:
      return 'offline';
  }
};

export const MECHANIC_STATUS_OPTIONS = [
  { value: 'AVAILABLE', label: 'Available' },
  { value: 'OFFLINE', label: 'Offline' },
  { value: 'BUSY', label: 'Busy' },
  // { value: 'MECHANIC_ON_THE_WAY', label: 'On The Way' },
];

export const REQUEST_TYPE_OPTIONS = [
  { value: 'truck_repair', label: 'Truck Repair' },
  { value: 'tire_repair', label: 'Tire Repair' },
  { value: 'trailer_repair', label: 'Trailer Repair' },
];

export const REQUEST_STATUS_OPTIONS = [
  { value: 'SUBMITTED', label: 'Submitted' },
  { value: 'RECEIVED', label: 'Received' },
  { value: 'ASSIGNED_TO_MECHANIC', label: 'Assigned' },
  { value: 'ACCEPTED', label: 'Accepted by Mechanic' },
  { value: 'MECHANIC_ON_THE_WAY', label: 'Mechanic On the Way' },
  { value: 'MECHANIC_STARTED_WORK', label: 'Work Started' },
  { value: 'CANCELLED_BY_REPAIR_SHOP', label: 'Cancelled' },
  { value: 'COMPLETED', label: 'Completed' },
];

export const isNewRequest = (status: string) => {
  switch (status) {
    case 'SUBMITTED':
      return true;
    case 'RECEIVED':
      return true;
    case 'ASSIGNED_TO_MECHANIC':
      return true;
    case 'ACCEPTED':
      return true;
    case 'MECHANIC_ON_THE_WAY':
      return true;
    case 'MECHANIC_STARTED_WORK':
      return true;
    default:
      return false;
  }
};

export const DISPATCHER_STATUS_OPTIONS = [
  { value: 'AVAILABLE', label: 'Available' },
  { value: 'BUSY', label: 'Busy' },
  { value: 'OFFLINE', label: 'Offline' },
] as const;
