import Chip from '@mui/material/Chip';
import type { SxProps, Theme } from '@mui/material/styles';
import { useCallback } from 'react';
import {
  chipProps,
  FiltersBlock,
  FiltersResult,
} from 'src/minimal-theme/components/filters-result';
import type { UseSetStateReturn } from 'src/minimal-theme/hooks/use-set-state';
import { fDateRangeShortLabel } from 'src/minimal-theme/utils/format-time';
import type { InvoiceTableFilters } from 'src/types/invoice';

type InvoiceTableFiltersResultProps = {
  totalResults: number;
  sx?: SxProps<Theme>;
  onResetPage: () => void;
  filters: UseSetStateReturn<InvoiceTableFilters>;
};

export function InvoiceTableFiltersResult({
  filters,
  totalResults,
  onResetPage,
  sx,
}: InvoiceTableFiltersResultProps) {
  const handleRemoveKeyword = useCallback(() => {
    onResetPage();
    filters.setState({ name: '' });
  }, [filters, onResetPage]);

  const handleRemoveService = useCallback(
    (inputValue: string) => {
      const newValue = filters.state.service.filter(
        (item: string) => item !== inputValue
      );

      onResetPage();
      filters.setState({ service: newValue });
    },
    [filters, onResetPage]
  );

  const handleRemoveStatus = useCallback(() => {
    onResetPage();
    filters.setState({ status: 'all' });
  }, [filters, onResetPage]);

  const handleRemoveDate = useCallback(() => {
    onResetPage();
    filters.setState({ startDate: null, endDate: null });
  }, [filters, onResetPage]);

  return (
    <FiltersResult
      totalResults={totalResults}
      onReset={filters.onResetState}
      sx={sx}
    >
      <FiltersBlock label="Service:" isShow={!!filters.state.service.length}>
        {filters.state.service.map(item => (
          <Chip
            {...chipProps}
            key={item}
            label={item}
            onDelete={() => handleRemoveService(item)}
          />
        ))}
      </FiltersBlock>

      <FiltersBlock label="Status:" isShow={filters.state.status !== 'all'}>
        <Chip
          {...chipProps}
          label={filters.state.status}
          onDelete={handleRemoveStatus}
          sx={{ textTransform: 'capitalize' }}
        />
      </FiltersBlock>

      <FiltersBlock
        label="Date:"
        isShow={Boolean(filters.state.startDate && filters.state.endDate)}
      >
        <Chip
          {...chipProps}
          label={fDateRangeShortLabel(
            filters.state.startDate,
            filters.state.endDate
          )}
          onDelete={handleRemoveDate}
        />
      </FiltersBlock>

      <FiltersBlock label="Keyword:" isShow={!!filters.state.name}>
        <Chip
          {...chipProps}
          label={filters.state.name}
          onDelete={handleRemoveKeyword}
        />
      </FiltersBlock>
    </FiltersResult>
  );
}
