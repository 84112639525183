import { useQuery } from 'convex/react';
import { Helmet } from 'react-helmet-async';
import { CONFIG } from 'src/config-global';
import { api } from 'src/convex/_generated/api';
import { MapView } from 'src/sections/map/view/map-view';

const metadata = { title: `Map | Dashboard - ${CONFIG.appName}` };

export default function Page() {
  const THEMES = {
    streets: 'mapbox://styles/mapbox/streets-v11',
    outdoors: 'mapbox://styles/mapbox/outdoors-v11',
    light: 'mapbox://styles/mapbox/light-v10',
    dark: 'mapbox://styles/mapbox/dark-v10',
    satellite: 'mapbox://styles/mapbox/satellite-v9',
    satelliteStreets: 'mapbox://styles/mapbox/satellite-streets-v11',
  };

  const me = useQuery(api.functions.users.getMe);

  const usersForLocation = useQuery(
    api.functions.users.getAllUsersForLocation,
    {}
  );

  const vehicles = useQuery(api.functions.vehicles.getVehiclesForCompany, {});

  const techniciansForLocation = usersForLocation?.filter(
    u =>
      u.primaryRoleType === 'TECHNICIAN_PROVIDER' ||
      u.primaryRoleType === 'TECHNICIAN_FLEET'
  );

  const companies = useQuery(
    api.functions.companies.getCompanies,
    me && me.clerkUser.isSuperAdmin ? {} : 'skip'
  );

  const locations = useQuery(
    api.functions.companies.getLocations,
    me ? {} : 'skip'
  );

  const fleetLocations = locations?.filter(
    l =>
      l.locationType === 'FLEET_TERMINAL' ||
      l.locationType === 'FLEET_YARD' ||
      l.locationType === 'FLEET_DISPATCH_CENTER'
  );

  const serviceLocations = locations?.filter(
    l =>
      l.locationType === 'REPAIR_SHOP' ||
      l.locationType === 'MOBILE_REPAIR_BASE' ||
      l.locationType === 'SERVICE_DISPATCH_CENTER'
  );

  const thirdPartyLocations = locations?.filter(
    l => l.locationType === 'THIRD_PARTY_CALL_CENTER'
  );

  const activeRequests = useQuery(api.functions.requests.getActiveRequests, {});

  return (
    <>
      <Helmet>
        <title> {metadata.title}</title>
      </Helmet>

      <MapView
        data={{
          technicians: techniciansForLocation ?? [],
          vehicles: vehicles ?? [],
          companies: companies ?? [],
          fleetLocations: fleetLocations ?? [],
          serviceLocations: serviceLocations ?? [],
          thirdPartyLocations: thirdPartyLocations ?? [],
          activeRequests: activeRequests ?? [],
        }}
        mapStyle={THEMES.light}
      />
    </>
  );
}
