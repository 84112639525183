import { EnhancedRequest } from 'src/convex/functions/requests';
import { EnhancedUser } from 'src/convex/functions/users';
import { DriverForm } from 'src/sections/request/DriverForm';
import { FleetDispatchForm } from 'src/sections/request/FleetDispatchForm';
import { ServiceDispatchForm } from 'src/sections/request/ServiceDispatchForm';
import { ThirdPartyCallCenterForm } from 'src/sections/request/ThirdPartyCallCenterForm';

type RequestFormContentProps = {
  request: EnhancedRequest;
  me: EnhancedUser;
  isDialog?: boolean;
  onClose?: () => void;
};

export const RequestFormContent = ({
  request,
  me,
  isDialog,
  onClose,
}: RequestFormContentProps) => {
  if (me.primaryRoleType === 'DRIVER_FLEET') {
    return (
      <DriverForm
        request={request}
        me={me}
        isDialog={isDialog}
        onClose={onClose}
      />
    );
  } else if (me.primaryRoleType === 'FLEET_DISPATCHER') {
    return (
      <FleetDispatchForm
        request={request}
        me={me}
        isDialog={isDialog}
        onClose={onClose}
      />
    );
  } else if (me.primaryRoleType === 'THIRD_PARTY_DISPATCHER') {
    return (
      <ThirdPartyCallCenterForm
        request={request}
        me={me}
        isDialog={isDialog}
        onClose={onClose}
      />
    );
  } else if (me.primaryRoleType === 'SERVICE_DISPATCHER') {
    return (
      <ServiceDispatchForm
        request={request}
        me={me}
        isDialog={isDialog}
        onClose={onClose}
      />
    );
  }
  return null;
};
