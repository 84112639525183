import { z } from 'zod';

export const paymentMethod = z.enum([
  'CREDIT_CARD', // Credit card = stripe, we DO NOT allow credit card processing unless customer has set up a connected stripe account... we allow below as they are cases that come up, but we discourage
  'LOCAL_HOUSE_ACCOUNT',
  'MICHELIN_NATIONAL_ACCOUNT',
  'GOODYEAR_NATIONAL_ACCOUNT',
  'CONTINENTAL_NATIONAL_ACCOUNT',
  'YOKOHAMA_NATIONAL_ACCOUNT',
  'BRIDGESTONE_NATIONAL_ACCOUNT',
  'HANKOOK_NATIONAL_ACCOUNT',
  'PACCAR_NATIONAL_ACCOUNT',
  'PINNACLE_NATIONAL_ACCOUNT',
  'MACK_VOLVO_PREFERRED_NATIONAL_ACCOUNT',
  'FLEETCHARGE_NATIONAL_ACCOUNT',
  'AMBEST_BILLING_ACCOUNT',
  'COMCHECK',
  'TCHECK',
  'EFS',
]);

export type PaymentMethods = z.infer<typeof paymentMethod>;

export const friendlPaymentMethodNames: Record<PaymentMethods, string> = {
  CREDIT_CARD: 'Credit Card',
  LOCAL_HOUSE_ACCOUNT: 'Local House Account',
  MICHELIN_NATIONAL_ACCOUNT: 'Michelin National Account',
  GOODYEAR_NATIONAL_ACCOUNT: 'Goodyear National Account',
  CONTINENTAL_NATIONAL_ACCOUNT: 'Continental National Account',
  YOKOHAMA_NATIONAL_ACCOUNT: 'Yokohama National Account',
  BRIDGESTONE_NATIONAL_ACCOUNT: 'Bridgestone National Account',
  HANKOOK_NATIONAL_ACCOUNT: 'Hankook National Account',
  PACCAR_NATIONAL_ACCOUNT: 'Paccar National Account',
  PINNACLE_NATIONAL_ACCOUNT: 'Pinnacle National Account',
  MACK_VOLVO_PREFERRED_NATIONAL_ACCOUNT:
    'Mack/Volvo Preferred National Account',
  FLEETCHARGE_NATIONAL_ACCOUNT: 'Fleetcharge National Account',
  AMBEST_BILLING_ACCOUNT: 'Ambest Billing Account',
  COMCHECK: 'Comcheck',
  TCHECK: 'T-Check',
  EFS: 'EFS',
};

export const getFriendlyPaymentMethodName = (type: PaymentMethods): string => {
  return friendlPaymentMethodNames[type] || type;
};
