import { Autocomplete, Box, TextField } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { retrieveAddress, SearchResult } from 'src/utils/searchAddress';

type AddressInputProps = {
  value: string;
  onChange: (value?: string) => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: ReactNode;
  onCoordinatesChange?: (
    coords: {
      latitude: number;
      longitude: number;
    } | null
  ) => void;
  searchResults: SearchResult[];
  searchAddress: (query: string) => void;
  required?: boolean;
};

export const AddressInput = ({
  value,
  onChange,
  disabled,
  error,
  helperText,
  onCoordinatesChange,
  searchResults,
  searchAddress,
  required,
}: AddressInputProps) => {
  const [isInitialized, setIsInitialized] = useState(false);

  // Initialize the search results with the current value
  useEffect(() => {
    if (!isInitialized && value) {
      searchAddress(value);
      setIsInitialized(true);
    }
  }, [value, searchAddress, isInitialized]);

  return (
    <Box display="flex" flexDirection="column">
      <Autocomplete
        disabled={disabled}
        fullWidth
        options={searchResults}
        filterOptions={x => x}
        autoComplete
        includeInputInList
        freeSolo
        getOptionLabel={(option: SearchResult | string) => {
          if (typeof option === 'string') return option;
          return option.place_name || '';
        }}
        renderOption={(props, option: SearchResult) => (
          <li {...props} key={option.mapboxId}>
            {option.place_name}
          </li>
        )}
        isOptionEqualToValue={(
          option: SearchResult,
          value: SearchResult | string
        ) => {
          if (typeof value === 'string') {
            return option.place_name === value;
          }
          return option.place_name === value.place_name;
        }}
        value={value ? value : null}
        onChange={async (_event, newValue) => {
          if (!newValue) {
            onChange(undefined);
            onCoordinatesChange?.(null);
            return;
          }

          if (typeof newValue === 'string') {
            onChange(newValue);
            return;
          }

          onChange(newValue.place_name);

          const addressDetails = await retrieveAddress(newValue.mapboxId);

          if (addressDetails) {
            onCoordinatesChange?.({
              latitude: addressDetails.geometry.coordinates[1],
              longitude: addressDetails.geometry.coordinates[0],
            });
          }
        }}
        onInputChange={(_event, newValue, reason) => {
          // Don't clear the value during initialization
          if (reason === 'input') {
            onChange(newValue);
            searchAddress(newValue);
          } else if (reason === 'clear') {
            // Only clear if user explicitly requests it
            onChange(undefined);
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            label="Address"
            error={error}
            required={!!required}
            helperText={helperText}
          />
        )}
      />
    </Box>
  );
};
