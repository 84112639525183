import { useConvexAuth, useMutation, useQuery } from 'convex/react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { getDistanceInMeters } from 'src/context/locationTracking';
import { api } from 'src/convex/_generated/api';

const LOCATION_UPDATE_THRESHOLD_METERS = 50;
const LOCATION_UPDATE_THROTTLE_MS = 30000;

type LocationStatus = {
  isTracking: boolean;
  lastUpdate: Date | null;
  error: string | null;
  isInitializing: boolean;
};

const LocationTrackingContext = createContext<LocationStatus>({
  isTracking: false,
  lastUpdate: null,
  error: null,
  isInitializing: true,
});

export function LocationTrackingProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { isAuthenticated } = useConvexAuth();
  const updateLocation = useMutation(
    api.functions.users.updateTechnicianLocation
  );
  const watchId = useRef<number | null>(null);
  const lastLocation = useRef<{ latitude: number; longitude: number } | null>(
    null
  );
  const lastUpdateTime = useRef<number>(0);

  const [status, setStatus] = useState<LocationStatus>({
    isTracking: false,
    lastUpdate: null,
    error: null,
    isInitializing: true,
  });

  // Get user info to check if technician
  const me = useQuery(api.functions.users.getMe, isAuthenticated ? {} : 'skip');

  const isTechnician = me?.roles.find(r =>
    ['TECHNICIAN', 'TECHNICIAN_FLEET', 'TECHNICIAN_PROVIDER'].includes(r.type)
  );

  // Get active requests
  const myAssignedActiveRequests = useQuery(
    api.functions.requests.getAssignedRequests,
    isTechnician ? {} : 'skip'
  );

  const handleLocationUpdate = useCallback(
    async (position: GeolocationPosition) => {
      const now = Date.now();
      const newLocation = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };

      // Skip if not enough time has passed
      if (now - lastUpdateTime.current < LOCATION_UPDATE_THROTTLE_MS) {
        return;
      }

      // Skip if haven't moved enough
      if (lastLocation.current) {
        const distance = getDistanceInMeters(
          lastLocation.current.latitude,
          lastLocation.current.longitude,
          newLocation.latitude,
          newLocation.longitude
        );

        if (distance < LOCATION_UPDATE_THRESHOLD_METERS) {
          return;
        }
      }

      try {
        lastLocation.current = newLocation;
        lastUpdateTime.current = now;

        await updateLocation(newLocation);

        setStatus({
          isTracking: true,
          lastUpdate: new Date(),
          error: null,
          isInitializing: false,
        });
      } catch (error) {
        setStatus(prev => ({
          ...prev,
          error: 'Failed to update location',
        }));
      }
    },
    [updateLocation]
  );

  const stopTracking = useCallback(() => {
    if (watchId.current !== null) {
      navigator.geolocation.clearWatch(watchId.current);
      watchId.current = null;
      lastLocation.current = null;
      lastUpdateTime.current = 0;
      setStatus({
        isTracking: false,
        lastUpdate: null,
        error: null,
        isInitializing: false,
      });
    }
  }, []);

  const startTracking = useCallback(() => {
    if (!navigator.geolocation || watchId.current !== null) return;

    setStatus(prev => ({
      ...prev,
      isInitializing: true,
    }));

    try {
      watchId.current = navigator.geolocation.watchPosition(
        // On first position success
        position => {
          handleLocationUpdate(position);
          setStatus(prev => ({
            ...prev,
            isInitializing: false,
            isTracking: true,
          }));
        },
        // On error
        error => {
          setStatus(prev => ({
            ...prev,
            isInitializing: false,
            error: error.message,
          }));
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } catch (error) {
      setStatus(prev => ({
        ...prev,
        isInitializing: false,
        error: 'Failed to start location tracking',
      }));
    }
  }, [handleLocationUpdate]);

  useEffect(() => {
    const shouldTrack =
      isAuthenticated &&
      isTechnician &&
      myAssignedActiveRequests &&
      myAssignedActiveRequests.length > 0;

    if (shouldTrack) {
      startTracking();
    } else {
      stopTracking();
    }

    // Cleanup on unmount
    return () => stopTracking();
  }, [
    isAuthenticated,
    isTechnician,
    myAssignedActiveRequests?.length,
    startTracking,
    stopTracking,
  ]);

  return (
    <LocationTrackingContext.Provider value={status}>
      {children}
    </LocationTrackingContext.Provider>
  );
}

export const useLocationTracking = () => useContext(LocationTrackingContext);
