import { useQuery } from 'convex/react';
import { useCallback, useEffect, useState } from 'react';
import { Id } from 'src/convex/_generated/dataModel';
import { toast } from 'src/minimal-theme/components/snackbar';
import { api } from '../convex/_generated/api';

export type ScheduledActionsCallbacks = {
  onComplete?: () => void;
  onSuccess?: () => void;
  onError?: (failedIds: Id<'_scheduled_functions'>[]) => void;
};

export function useScheduledActions() {
  const [ids, setIds] = useState<Id<'_scheduled_functions'>[] | null>(null);
  const [callbacks, setCallbacks] = useState<ScheduledActionsCallbacks | null>(
    null
  );
  const [results, setResults] = useState({
    succeeded: [] as Id<'_scheduled_functions'>[],
    failed: [] as Id<'_scheduled_functions'>[],
    isComplete: false,
  });

  const scheduledActions = useQuery(
    api.functions.schedule.getScheduledMessages,
    ids ? { ids } : 'skip'
  );

  // Wrap setIds to include callbacks
  const watchIds = useCallback(
    async (
      newIds: Id<'_scheduled_functions'>[],
      callbacks?: ScheduledActionsCallbacks
    ) => {
      return new Promise((resolve, reject) => {
        setIds(newIds);
        toast.loading('Processing...');

        setCallbacks({
          onSuccess: () => {
            callbacks?.onSuccess?.();
            resolve(true);
          },
          onError: failedIds => {
            callbacks?.onError?.(failedIds);
            reject(failedIds);
          },
          onComplete: callbacks?.onComplete,
        });
      });
    },
    []
  );

  useEffect(() => {
    if (!ids || !scheduledActions) return;

    const newSucceeded: Id<'_scheduled_functions'>[] = [];
    const newFailed: Id<'_scheduled_functions'>[] = [];

    scheduledActions.forEach(action => {
      if (action.state.kind === 'success') {
        newSucceeded.push(action._id);
      } else if (
        action.state.kind === 'failed' ||
        action.state.kind === 'canceled'
      ) {
        newFailed.push(action._id);
      }
    });

    const isComplete = newSucceeded.length + newFailed.length === ids.length;

    if (isComplete && !results.isComplete) {
      toast.dismiss();

      if (newFailed.length === 0) {
        toast.success('All operations completed successfully');
        callbacks?.onSuccess?.();
      } else if (newSucceeded.length === 0) {
        toast.error('All operations failed');
        callbacks?.onError?.(newFailed);
      } else {
        toast.warning(
          `${newSucceeded.length} succeeded, ${newFailed.length} failed`
        );
        callbacks?.onError?.(newFailed);
      }

      callbacks?.onComplete?.();

      // Clear the ids and callbacks after completion
      setIds(null);
      setCallbacks(null);
    }

    setResults({ succeeded: newSucceeded, failed: newFailed, isComplete });
  }, [scheduledActions, ids, callbacks, results.isComplete]);

  return { watchIds, ...results };
}
