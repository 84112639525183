import { zid, zodToConvex } from 'convex-helpers/server/zod';
import { defineTable } from 'convex/server';
import { z } from 'zod';

export const stripeSettingsSchema = z.object({
  stripeAccountMode: z.enum([
    'COMPANY_MANAGED', // Uses parent company's Stripe account
    'LOCATION_MANAGED', // Location has own Stripe account
  ]),
  stripeAccountId: z.string().optional(), // Only set if LOCATION_MANAGED
  allowedInvoicingRoles: z.array(zid('roleDefinitions')), // Which roles can create invoices
  paymentEnabled: z.boolean().optional(), // Whether this location can process payments
  stripeOnboardingComplete: z.boolean().optional(),
  stripeRequirementsCurrentlyDue: z.array(z.string()).optional(),
  stripeRequirementsPastDue: z.array(z.string()).optional(),
});

// New index
export const stripeSettings = defineTable(
  zodToConvex(stripeSettingsSchema).fields
).index('by_stripeAccountId', ['stripeAccountId']);
