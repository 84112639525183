export type BaseResponse = {
  success: boolean;
  message: string;
  error?: string;
};

export function createResponse(params: {
  success: boolean;
  message: string;
  error?: string;
}): BaseResponse {
  const { success, message, error } = params;
  return {
    success,
    message,
    ...(error && { error }),
  };
}
