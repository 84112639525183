import { Doc } from '../../../_generated/dataModel';

export function getThirdPartyDispatcherDraftInitiationFields(
  user: Doc<'users'>,
  primaryLocation: Doc<'groups'>,
  company: Doc<'companies'>
): Partial<Doc<'requests'>> {
  return {
    brokerageGroupId: primaryLocation._id,
    activeBrokerageDispatcherId: user._id,
    brokerageCompanyId: company._id,
  };
}
