import { redirect } from '@tanstack/react-router';
import { UserRole } from 'src/convex/schema/enums/userRole';
import { RouterContext } from 'src/routes/__root';

type AccessConfig = {
  allowSuperAdmin?: boolean;
  allowImpersonation?: boolean;
  allowedRoles?: UserRole[];
  allowAllRoles?: boolean;
  allowCompanyPrimaryAdmin?: boolean;
  requireFleetOnly?: boolean;
  requireServiceProviderOnly?: boolean;
  redirectTo?: string;
};

export const checkRouteAccess = (
  context: RouterContext,
  config: AccessConfig = {}
) => {
  const {
    allowSuperAdmin = false,
    allowCompanyPrimaryAdmin = false,
    allowImpersonation = true,
    allowedRoles = [],
    requireFleetOnly = false,
    requireServiceProviderOnly = false,
    allowAllRoles = false,
    redirectTo = '/dashboard',
  } = config;

  const {
    user,
    me,
    impersonatingFleet,
    impersonatingServiceProvider,
    impersonatingThirdParty,
  } = context;

  if (!user || !me) {
    throw redirect({ to: redirectTo });
  }

  // Handle super admin access
  const isSuperAdmin = user?.publicMetadata.isSuperAdmin;
  const isCompanyPrimaryAdmin = me?.isCompanyPrimaryAdmin;
  const isImpersonating =
    impersonatingFleet ||
    impersonatingServiceProvider ||
    impersonatingThirdParty;
  const currentRole = me?.primaryRoleType as UserRole;

  // Super admin checks
  if (isSuperAdmin) {
    if (!allowSuperAdmin || (!allowImpersonation && isImpersonating)) {
      throw redirect({ to: redirectTo });
    }

    if (requireFleetOnly && !impersonatingFleet) {
      throw redirect({ to: redirectTo });
    }
    if (requireServiceProviderOnly && !impersonatingServiceProvider) {
      throw redirect({ to: redirectTo });
    }

    return;
  }

  // Company primary admin checks
  if (isCompanyPrimaryAdmin && allowCompanyPrimaryAdmin) {
    return;
  }

  // Role-based access checks
  if (allowAllRoles) {
    return;
  }

  // Specific role requirement checks
  if (requireServiceProviderOnly) {
    if (currentRole !== 'SERVICE_DISPATCHER') {
      throw redirect({ to: redirectTo });
    }
    return;
  }

  if (requireFleetOnly) {
    if (currentRole !== 'FLEET_DISPATCHER') {
      throw redirect({ to: redirectTo });
    }
    return;
  }

  // Allowed roles check
  if (allowedRoles.length > 0) {
    const isRoleAllowed = allowedRoles.includes(currentRole);

    if (!isRoleAllowed) {
      throw redirect({ to: redirectTo });
    }
    return;
  }

  // Final check for configs that require either super admin or company primary admin
  if (
    (allowSuperAdmin && !isSuperAdmin) ||
    (allowCompanyPrimaryAdmin && !isCompanyPrimaryAdmin)
  ) {
    throw redirect({ to: redirectTo });
  }
};

type RouteConfigs = {
  allRolesExceptSuperAdmin: AccessConfig;
  dispatcherGeneral: AccessConfig;
  fleetOnly: AccessConfig;
  serviceProviderOnly: AccessConfig;
  noSuperAdmin: AccessConfig;
  onlySuperAdmin: AccessConfig;
  superAdminAndCompanyPrimaryAdmin: AccessConfig;
};

export const routeAccessConfigs: RouteConfigs = {
  allRolesExceptSuperAdmin: {
    allowAllRoles: true,
  },
  dispatcherGeneral: {
    allowSuperAdmin: true,
    allowImpersonation: true,
    allowedRoles: [
      'FLEET_DISPATCHER',
      'SERVICE_DISPATCHER',
      'THIRD_PARTY_DISPATCHER',
    ],
  },
  fleetOnly: {
    allowSuperAdmin: true,
    allowImpersonation: true,
    requireFleetOnly: true,
    allowedRoles: ['FLEET_DISPATCHER'],
  },
  serviceProviderOnly: {
    allowSuperAdmin: true,
    allowImpersonation: true,
    requireServiceProviderOnly: true,
    allowedRoles: ['SERVICE_DISPATCHER'],
  },
  noSuperAdmin: {
    allowSuperAdmin: false,
    allowedRoles: ['FLEET_DISPATCHER', 'SERVICE_DISPATCHER'],
  },
  onlySuperAdmin: {
    allowSuperAdmin: true,
  },
  superAdminAndCompanyPrimaryAdmin: {
    allowSuperAdmin: true,
    allowCompanyPrimaryAdmin: true,
  },
};
