import { ConvexError } from 'convex/values';
import { Doc } from '../../_generated/dataModel';
import { Capability } from '../../schema/entities/roleDefinitions';
import { UserRole } from '../../schema/enums/userRole';

// TODO: Annoying that roles returned can be null... gotta figure out why that is
export type UserContextRole = Doc<'roleDefinitions'> | null;

type RoleConfig = {
  canInvite: (roles: UserContextRole[], isSuperAdmin: boolean) => boolean;
  errorMessage: string;
  requiredCapabilities?: Capability[];
};

export const roleConfigs: Record<UserRole, RoleConfig> = {
  DRIVER_FLEET: {
    canInvite: (roles, isSuperAdmin) =>
      isSuperAdmin || roleCapabilityChecks.canManageDrivers(roles),
    errorMessage: 'You do not have permission to invite drivers',
  },
  FLEET_DISPATCHER: {
    canInvite: (roles, isSuperAdmin) =>
      isSuperAdmin || roleCapabilityChecks.canManageDispatch(roles),
    errorMessage: 'You do not have permission to invite dispatchers',
  },
  THIRD_PARTY_DISPATCHER: {
    canInvite: (roles, isSuperAdmin) =>
      isSuperAdmin || roleCapabilityChecks.canManageDispatch(roles),
    errorMessage: 'You do not have permission to invite dispatchers',
  },
  TECHNICIAN_FLEET: {
    canInvite: (roles, isSuperAdmin) =>
      isSuperAdmin || roleCapabilityChecks.canManageMechanics(roles),
    errorMessage: 'You do not have permission to invite fleet technicians',
  },
  SERVICE_DISPATCHER: {
    canInvite: (roles, isSuperAdmin) =>
      isSuperAdmin || roleCapabilityChecks.canManageDispatch(roles),
    errorMessage: 'You do not have permission to invite a dispatcher',
  },
  TECHNICIAN_PROVIDER: {
    canInvite: (roles, isSuperAdmin) =>
      isSuperAdmin || roleCapabilityChecks.canManageMechanics(roles),
    errorMessage: 'You do not have permission to invite a service technician',
  },

  // Independent roles - only super admins can invite TODO: FOR NOW...
  DRIVER: {
    canInvite: (roles, isSuperAdmin) => isSuperAdmin,
    errorMessage: 'Only system administrators can invite independent drivers',
  },
  TECHNICIAN: {
    canInvite: (roles, isSuperAdmin) => isSuperAdmin,
    errorMessage:
      'Only system administrators can invite independent technicians',
  },

  // Other roles - keeping them in for type completeness
  FLEET_MANAGER: {
    canInvite: (roles, isSuperAdmin) => isSuperAdmin,
    errorMessage: 'Only system administrators can invite fleet managers',
  },
  MAINTENANCE_MANAGER: {
    canInvite: (roles, isSuperAdmin) => isSuperAdmin,
    errorMessage: 'Only system administrators can invite maintenance managers',
  },
  OPERATIONS_MANAGER: {
    canInvite: (roles, isSuperAdmin) => isSuperAdmin,
    errorMessage: 'Only system administrators can invite operations managers',
  },
  FLEET_ADMIN: {
    canInvite: (roles, isSuperAdmin) => isSuperAdmin,
    errorMessage: 'Only system administrators can invite fleet admins',
  },
  SERVICE_MANAGER: {
    canInvite: (roles, isSuperAdmin) => isSuperAdmin,
    errorMessage: 'Only system administrators can invite service managers',
  },
  SERVICE_ADMIN: {
    canInvite: (roles, isSuperAdmin) => isSuperAdmin,
    errorMessage: 'Only system administrators can invite service admins',
  },
  TOW_OPERATOR: {
    canInvite: (roles, isSuperAdmin) => isSuperAdmin,
    errorMessage: 'Only system administrators can invite tow operators',
  },
  // Dispatch supervisors
  FLEET_DISPATCH_SUPERVISOR: {
    canInvite: (roles, isSuperAdmin) => isSuperAdmin,
    errorMessage: 'Only system administrators can invite dispatch supervisors',
  },
  SERVICE_DISPATCH_SUPERVISOR: {
    canInvite: (roles, isSuperAdmin) => isSuperAdmin,
    errorMessage: 'Only system administrators can invite dispatch supervisors',
  },
  // Location managers
  FLEET_LOCATION_MANAGER: {
    canInvite: (roles, isSuperAdmin) => isSuperAdmin,
    errorMessage: 'Only system administrators can invite location managers',
  },
  SERVICE_LOCATION_MANAGER: {
    canInvite: (roles, isSuperAdmin) => isSuperAdmin,
    errorMessage: 'Only system administrators can invite location managers',
  },
  SERVICE_DISPATCH_LOCATION_MANAGER: {
    canInvite: (roles, isSuperAdmin) => isSuperAdmin,
    errorMessage: 'Only system administrators can invite location managers',
  },
  FLEET_DISPATCH_LOCATION_MANAGER: {
    canInvite: (roles, isSuperAdmin) => isSuperAdmin,
    errorMessage: 'Only system administrators can invite location managers',
  },

  // System Admin Roles
  SUPER_ADMIN: {
    canInvite: (roles, isSuperAdmin) => isSuperAdmin,
    errorMessage: 'Only existing system administrators can invite new ones',
  },
  EXECUTIVE_ADMIN: {
    canInvite: (roles, isSuperAdmin) => isSuperAdmin,
    errorMessage: 'Only system administrators can invite executive admins',
  },
} as const;

export function hasCapability(
  roles: UserContextRole[],
  capability: Capability
): boolean {
  return roles
    .filter((role): role is Doc<'roleDefinitions'> => role !== null)
    .some(role => role.capabilities?.includes(capability));
}

/**
 * Role-specific capability checks using existing context
 */
export const roleCapabilityChecks = {
  canManageDrivers: (roles: UserContextRole[]): boolean =>
    hasCapability(roles, 'manage_drivers') ||
    hasCapability(roles, 'manage_users'),

  canManageDispatch: (roles: UserContextRole[]): boolean =>
    hasCapability(roles, 'manage_dispatch') ||
    hasCapability(roles, 'manage_users'),

  canManageMechanics: (roles: UserContextRole[]): boolean =>
    hasCapability(roles, 'manage_mechanics') ||
    hasCapability(roles, 'manage_users'),

  canManageCompanySettings: (roles: UserContextRole[]): boolean =>
    hasCapability(roles, 'manage_company_settings'),
} as const;

/**
 * Helper to enforce capabilities in mutations/queries
 */
export function enforceCapability(
  roles: UserContextRole[],
  capability: Capability,
  errorMessage?: string
): void {
  if (!hasCapability(roles, capability)) {
    throw new ConvexError({
      code: 'FORBIDDEN',
      message: errorMessage || `Missing required capability: ${capability}`,
    });
  }
}
