import { Helmet } from 'react-helmet-async';
import { CONFIG } from 'src/config-global';
import { UnitCreateView } from 'src/sections/unit/view';
const metadata = { title: `Add Unit | Dashboard - ${CONFIG.appName}` };

export default function Page() {
  return (
    <>
      <Helmet>
        <title> {metadata.title}</title>
      </Helmet>

      <UnitCreateView />
    </>
  );
}
