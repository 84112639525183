import { useConvexAuth, useQuery } from 'convex/react';
import { api } from 'src/convex/_generated/api';

export function useImpersonationInfo() {
  const { isAuthenticated } = useConvexAuth();

  // Only fetch user data if authenticated
  const me = useQuery(api.functions.users.getMe, isAuthenticated ? {} : 'skip');

  // Fetch impersonated company if user has one set
  const impersonatedCompany = useQuery(
    api.functions.companies.getCompany,
    me?.impersonatingCompanyId
      ? { companyId: me.impersonatingCompanyId }
      : 'skip'
  );

  // Fetch impersonated location if user has one set
  const impersonatedLocation = useQuery(
    api.functions.companies.getLocationById,
    me?.impersonatingLocationId ? { id: me.impersonatingLocationId } : 'skip'
  );

  return {
    me,
    impersonatedCompany,
    impersonatedLocation,
    isAuthenticated,
    // Helpful derived states
    isImpersonating: Boolean(me?.impersonatingCompanyId),
    isLoading:
      me === undefined ||
      (me?.impersonatingCompanyId && impersonatedCompany === undefined) ||
      (me?.impersonatingLocationId && impersonatedLocation === undefined),
    impersonatingFleet: impersonatedCompany?.companyType === 'FLEET',
    impersonatingServiceProvider:
      impersonatedCompany?.companyType === 'REPAIR_SHOP',
    impersonatingThirdParty: impersonatedCompany?.companyType === 'THIRD_PARTY',
  };
}
