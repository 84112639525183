import { ClerkProvider, useAuth } from '@clerk/clerk-react';
import { MultisessionAppSupport } from '@clerk/clerk-react/internal';
import * as Sentry from '@sentry/react';
import { ConvexProviderWithClerk } from 'convex/react-clerk';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { GlobalErrorFallbackDialog } from 'src/components/global/GlobalErrorFallbackDialog';
import {
  SettingsProvider,
  defaultSettings,
} from 'src/minimal-theme/components/settings';
import { initSentry } from 'src/sentry/sentryClient';
import { RouterProviderWithContext } from './components/routing/RouterProviderWithContext';
import { convex } from './convexClient';
import './global.css';
import { LocalizationProvider } from './minimal-theme/locales';

const publishableKey = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

initSentry();

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError, eventId }) => (
        <GlobalErrorFallbackDialog
          error={error as any}
          componentStack={componentStack}
          resetError={resetError}
          eventId={eventId}
        />
      )}
    >
      <LocalizationProvider>
        <SettingsProvider settings={defaultSettings}>
          <HelmetProvider>
            <ClerkProvider publishableKey={publishableKey}>
              <MultisessionAppSupport>
                <ConvexProviderWithClerk client={convex} useAuth={useAuth}>
                  <RouterProviderWithContext />
                </ConvexProviderWithClerk>
              </MultisessionAppSupport>
            </ClerkProvider>
          </HelmetProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>
);
