import { zid, zodToConvex } from 'convex-helpers/server/zod';
import { defineTable } from 'convex/server';
import { customAlphabet } from 'nanoid';
import { z } from 'zod';
import { phoneSchema } from '../../../zodHelpers/phoneSchema';
import { paymentMethod } from '../../enums/paymentMethod';
import { requestStatusType } from '../../enums/requestStatusType';
import { tempVehicleInfo } from '../vehicles';

export const searchComponentSchema = z.object({
  type: z.enum([
    'CASE_NUMBER',
    'FLEET_REFERENCE_NUMBER',
    'ADDRESS',
    'DESCRIPTION',
    'DRIVER_NAME',
    'FLEET_DISPATCHER_NAME',
    'SERVICE_DISPATCHER_NAME',
    'TECHNICIAN_NAME',
    'VEHICLE_VIN',
    'VEHICLE_MAKER',
    'VEHICLE_MODEL',
    'VEHICLE_UNIT_NUMBER',
  ]),
  text: z.string(),
  sourceId: zid('users').or(zid('vehicles')).optional(),
});

export type RequestSearchComponent = z.infer<typeof searchComponentSchema>;

export const caseNumberSchema = z
  .string()
  .regex(/^\d{6}-\d{7}$/, 'Invalid case number format')
  .describe('Case number in format YYMMDD-NNNNNNN');

export const stepState = z.enum([
  'UNASSIGNED', // No one assigned yet
  'ASSIGNED', // Someone specific is assigned
  'QUEUED', // Waiting in a dispatch/service queue
  'COMPLETED', // Step is done
  'CANCELED', // Step was canceled
]);

export type StepState = z.infer<typeof stepState>;

// Define service phase to track which group is actively handling the request
export const servicePhase = z.enum([
  'FLEET_DISPATCH', // Initial fleet company dispatch handling
  'TOW_DISPATCH', // Tow company dispatch handling
  'TOW_SERVICE', // Active tow service
  'MECHANIC_DISPATCH', // Service provider dispatch handling
  'MECHANIC_SERVICE', // Active mechanical service
  'VERIFICATION',
  'COMPLETED',
]);

export type ServicePhase = z.infer<typeof servicePhase>;

export const stepType = z.enum([
  'INTAKE',
  'DRIVER_CONFIRM_LOCATION',
  'DISPATCH_TRIAGE',
  'WITH_SERVICE_PROVIDER_DISPATCH',
  'TECHNICIAN_ASSIGNED',
  'TECHNICIAN_ACCEPTED',
  'TECHNICIAN_ARRIVED',
  'TECHNICIAN_STARTED_WORK',
  'TECHNICIAN_COMPLETED_WORK',
  'COMPLETION_VERIFICATION',
  'COMPLETED',
]);

export type StepType = z.infer<typeof stepType>;

export const STEP_DISPLAY: Record<StepType, string> = {
  INTAKE: 'Request Created',
  DRIVER_CONFIRM_LOCATION: 'Driver Confirming Location',
  DISPATCH_TRIAGE: 'With Dispatch',
  WITH_SERVICE_PROVIDER_DISPATCH: 'Awaiting Service Provider',
  TECHNICIAN_ASSIGNED: 'Technician Assigned',
  TECHNICIAN_ACCEPTED: 'Technician En Route',
  TECHNICIAN_ARRIVED: 'Technician Onsite',
  TECHNICIAN_STARTED_WORK: 'Technician Started Work',
  TECHNICIAN_COMPLETED_WORK: 'Technician Has Completed Work',
  COMPLETION_VERIFICATION: 'Awaiting Verification',
  COMPLETED: 'Request Completed',
};

export const getStepTypeDisplay = (role?: StepType) =>
  role ? STEP_DISPLAY[role] : 'N/A';

export const repairDetailsSchema = z.object({
  cause: z.string().min(2, 'Cause is required'),
  correction: z.string().min(2, 'Cause is required'),
  wasATemporaryFix: z.boolean().optional(),
  notes: z.string().optional(),
  completedAt: z.string(), // When these details were submitted
  technicianId: zid('users'), // Who submitted them
});

export type RepairDetails = z.infer<typeof repairDetailsSchema>;

export const submitRepairDetailsInput = repairDetailsSchema
  .omit({
    completedAt: true,
    technicianId: true,
  })
  .extend({ requestId: zid('requests'), wasATemporaryFix: z.boolean() });

export const requestSchema = z.object({
  // workflowDefinitionId: zid('workflowDefinitions'),
  status: requestStatusType,
  // currentStepIndex: z.number(),

  currentPhase: servicePhase.optional(),
  currentStepType: stepType.optional(),
  currentStepState: stepState.optional(),

  caseNumber: caseNumberSchema,

  description: z.string().optional(),
  cancellationReason: z.string().optional(),

  // Location
  address: z.string().optional(), // To trap the initial location of accident in time... separate from where the vehicle is now, etc. can be used to geneerate heatmap of breakdown locations,
  longitude: z.number().optional(),
  latitude: z.number().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  postCode: z.string().optional(),
  country: z.string().optional(),
  stateShortCode: z.string().optional(),

  // Group history - tracks all groups involved
  driverGroupId: zid('groups').optional(),
  fleetDispatchGroupId: zid('groups').optional(), // Fleet dispatch group
  towDispatchGroupId: zid('groups').optional(), // Tow dispatcher group
  towServiceGroupId: zid('groups').optional(), // Tow service group
  mechanicDispatchGroupId: zid('groups').optional(), // Mechanic dispatcher group
  mechanicServiceGroupId: zid('groups').optional(), // Mechanic service group
  brokerageGroupId: zid('groups').optional(), // For third party elements, brokers or call centers, we'll use the same value for now TODO: we will be breaking out into a joiner table "participations", and can possibly eventually remove these

  // Current assignment
  currentAssignedToId: zid('users').optional(),
  currentRequiredRoleId: zid('roleDefinitions').optional(), // Role required for current step if it's queued, awaiting claim

  // Current active participants by role (for real-time coordination)
  activeDriverId: zid('users').optional(),
  activeFleetDispatcherId: zid('users').optional(),
  activeServiceDispatcherId: zid('users').optional(),
  activeTechnicianId: zid('users').optional(),
  activeBrokerageDispatcherId: zid('users').optional(),
  activeTowOperatorId: zid('users').optional(),

  // For technician when they have started their work
  repairDetails: repairDetailsSchema.optional(),

  // Companies involved
  requesterCompanyId: zid('companies'),
  serviceProviderCompanyId: zid('companies').optional(),
  towingCompanyId: zid('companies').optional(),
  brokerageCompanyId: zid('companies').optional(),

  // For full text search/client side search in the meantime
  searchText: z.string().optional(), // Make existing optional, TODO: in the future we need to delete this..
  // Unfortunately we need two search indexes, maybe more in the future, because we need activeFleetDispatcherId for example to be in the filterFields to cut down the results, but we can't also include the mechanic one in the same index array
  fleetSearchText: z.string().optional(),
  serviceSearchText: z.string().optional(),
  searchComponents: z.array(searchComponentSchema).optional(),

  // In cases where dispatcher is creating on behalf of driver, and we require driver location ... determines the order of steps (confirm drivers location will show up and come before dispatch triage in these cases)
  requiresVehicleLocation: z.boolean().optional(),

  // Timing
  currentStepStartedAt: z.number().optional(),
  createdAt: z.number().optional(), // When draft is submitted
  completedAt: z.number().optional(),
  deletedAt: z.number().optional(),

  estimatedTimeOfArrival: z.string().optional(),

  driverRequiresTow: z.boolean().optional(),

  // TODO: RF ... would be more work but might be useful if this wasn't just a string, but pulling from a managed list fo relevant tools
  tools: z.string().optional(),

  // TODO: when workflows are figured out, this will apply to a step, not the whole request
  mapGeneratedEta: z.string().optional(),
  // TODO: RF - should probably not apply to the whole request but on the specific action/service being charged
  paymentMethod: paymentMethod.optional(),

  // Simple external reference - optional string that fleets can use to track their internal number
  fleetReferenceNumber: z.string().optional(),

  // TODO: I think get rid of these??
  externalReferenceNumber: z.string().optional(),
  nationalAccountNumber: z.string().optional(),

  notes: z.string().optional(),
  timezone: z.string().optional(), // TODO RF: Still need to think about this, a single tz set on the request might not be best? If it was always the tz of the broken down driver sure

  // Relationships
  createdById: zid('users'),

  vehicleId: zid('vehicles').optional(), // Optionally initially for creating draft

  // Services the fleet believes are needed
  servicesRequiredByFleet: z.array(zid('services')).optional(),
  // Services the technician deems necessary after inspection
  servicesDeemedNecessaryByTech: z.array(zid('services')).optional(),
  // Services actually performed
  servicesPerformed: z.array(zid('services')).optional(),

  // Temporary fields for when driver is not yet in the system
  tempDriverEmail: z.string().email().optional(),
  tempDriverPhone: phoneSchema.optional(),
  tempFleetDispatchEmail: z.string().email().optional(),
  tempFleetDispatchPhone: phoneSchema.optional(),
  tempDriverFirstName: z.string().optional(),
  tempDriverLastName: z.string().optional(),
  tempFleetDispatchFirstName: z.string().optional(),
  tempFleetDispatchLastName: z.string().optional(),
  tempVehicleInfo: tempVehicleInfo.optional(),
  tempVehicleUnitNumber: z.string().optional(),

  // If there is no dispatcher involved in the request
  isDriverCalling: z.boolean().optional(),
});

export type ServiceRequest = z.infer<typeof requestSchema>;
export const requests = defineTable(zodToConvex(requestSchema).fields)
  .index('by_driverGroupId_and_status_and_createdAt', [
    'driverGroupId',
    'status',
  ])
  .index('by_fleetDispatchGroupId_and_status_and_createdAt', [
    'fleetDispatchGroupId',
    'status',
  ])
  .index('by_mechanicDispatchGroupId_and_status_and_createdAt', [
    'mechanicDispatchGroupId',
    'status',
  ])
  .index('by_mechanicServiceGroupId_and_status_and_createdAt', [
    'mechanicServiceGroupId',
    'status',
  ])

  .index('by_brokerageGroupId_and_status_and_createdAt', [
    'brokerageGroupId',
    'status',
  ])

  // Active participant indexes - all include status
  .index('by_activeDriverId_and_status', ['activeDriverId', 'status'])
  .index('by_activeFleetDispatcherId_and_status', [
    'activeFleetDispatcherId',
    'status',
  ])
  .index('by_activeServiceDispatcherId_and_status', [
    'activeServiceDispatcherId',
    'status',
  ])
  .index('by_activeTechnicianId_and_status', ['activeTechnicianId', 'status'])
  .index('by_activeBrokerageDispatcherId_and_status', [
    'activeBrokerageDispatcherId',
    'status',
  ])
  .index('by_vehicleId_and_status', ['vehicleId', 'status'])

  // Queue indexes (critical for dispatch functionality)
  .index('by_fleetQueue', [
    'currentRequiredRoleId',
    'fleetDispatchGroupId',
    'status',
    'currentStepState',
  ])
  .index('by_serviceQueue', [
    'currentRequiredRoleId',
    'mechanicDispatchGroupId',
    'status',
    'currentStepState',
  ])

  // Assignment indexes
  .index('by_currentAssignedToId_and_status', ['currentAssignedToId', 'status'])

  // Creation and management indexes
  .index('by_createdById_and_status', ['createdById', 'status'])
  .searchIndex('fleetDispatchSearch', {
    searchField: 'fleetSearchText',
    filterFields: ['fleetDispatchGroupId', 'status', 'createdAt'],
  })
  .searchIndex('serviceDispatchSearch', {
    searchField: 'serviceSearchText',
    filterFields: ['mechanicDispatchGroupId', 'status', 'createdAt'],
  });

const generateNumericId = customAlphabet('0123456789', 7);

export function generateCaseNumber(): string {
  const now = new Date();
  const datePart = now.toISOString().slice(2, 10).replace(/-/g, ''); // YYMMDD
  const randomPart = generateNumericId();

  return `${datePart}-${randomPart}`;
}
