import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Button,
  CardHeader,
  Divider,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Card from '@mui/material/Card';
import { formHelperTextClasses } from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useForm } from '@tanstack/react-form';
import { useLoaderData, useNavigate } from '@tanstack/react-router';
import { useStore } from '@tanstack/react-store';
import { zid } from 'convex-helpers/server/zod';
import { useMutation, useQuery } from 'convex/react';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { toast } from 'sonner';
import { api } from 'src/convex/_generated/api';
import { useScheduledActions } from 'src/hooks/useScheduledActions';
import { useResponsive } from 'src/minimal-theme/hooks/use-responsive';
import { z as zod } from 'zod';
import { InvoiceNewEditDetails } from './invoice-new-edit-details';

export type NewInvoiceSchemaType = zod.infer<typeof NewInvoiceSchema>;

export const NewInvoiceSchema = zod.object({});

export function InvoiceNewEditForm() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const mdUp = useResponsive('up', 'md');

  const currentInvoice = (() => {
    try {
      return useLoaderData({
        from: '/_auth/dashboard/invoices/$invoiceId',
      });
    } catch {
      return null;
    }
  })();

  const user = useQuery(api.functions.users.getMe);

  const [selectedServiceRequest, setSelectedServiceRequest] = useState<
    any | null
  >(null);

  const createInvoice = useMutation(api.functions.invoices.finalizeInvoice);

  const requests = useQuery(api.functions.requests.getAllRequests, {});

  const enhancedRequest = useQuery(
    api.functions.requests.getRequest,
    currentInvoice?.requestId
      ? { requestId: currentInvoice.requestId }
      : selectedServiceRequest?._id
        ? { requestId: selectedServiceRequest._id }
        : 'skip'
  );

  // const activeFleetDispatcher = useQuery(
  //   api.functions.users.getUserById,
  //   selectedServiceRequest?.activeFleetDispatcherId
  //     ? { id: selectedServiceRequest.activeFleetDispatcherId }
  //     : 'skip'
  // );

  // console.log('activeFleetDispatcher', activeFleetDispatcher);

  const { watchIds } = useScheduledActions();

  const navigate = useNavigate();

  const form = useForm({
    defaultValues: {
      recipientUserId:
        currentInvoice?.recipientUserId ||
        enhancedRequest?.activeFleetDispatcherId ||
        undefined,
      recipientGroupId:
        currentInvoice?.recipientGroupId ||
        enhancedRequest?.fleetDispatcher?.primaryLocationGroupId ||
        undefined,
      locationGroupId:
        currentInvoice?.locationGroupId ||
        enhancedRequest?.serviceDispatcher?.primaryLocationGroupId ||
        user?.primaryLocationGroupId,
      tempDriverEmail:
        currentInvoice?.tempDriverEmail ||
        enhancedRequest?.tempDriverEmail ||
        undefined,
      tempDriverPhone:
        currentInvoice?.tempDriverPhone ||
        enhancedRequest?.tempDriverPhone ||
        undefined,
      tempDriverFirstName:
        currentInvoice?.tempDriverFirstName ||
        enhancedRequest?.tempDriverFirstName ||
        undefined,
      // tempDriverLastName: selectedServiceRequest?.tempDriverLastName || '',
      // tempFleetDispatchEmail:
      //   selectedServiceRequest?.tempFleetDispatchEmail || '',
      // tempFleetDispatchFirstName:
      //   selectedServiceRequest?.tempFleetDispatchFirstName || '',
      // tempFleetDispatchLastName:
      //   selectedServiceRequest?.tempFleetDispatchLastName || '',
      // tempFleetDispatchPhone:
      //   selectedServiceRequest?.tempFleetDispatchPhone || '',
      amount: currentInvoice?.amount || 0,
      currency: currentInvoice?.currency || 'USD',
      requestId: currentInvoice?.requestId || undefined,
      description: currentInvoice?.description || undefined,
      clientProvidedDueDate: currentInvoice?.dueDate || 0,
      purchaseOrderNumber: currentInvoice?.dueDate || '',
      items: currentInvoice?.items || [
        {
          description: '',
          quantity: 1,
          service: undefined,
          price: 0,
          total: 0,
        },
      ],
    },
    onSubmit: async ({ value }) => {
      // console.log({
      //   ...value,
      //   amount: value.amount * 100,
      //   clientProvidedDueDate: value.clientProvidedDueDate
      //     ? dayjs(value.clientProvidedDueDate).valueOf()
      //     : 0,
      //   items: value.items.map((item: any) => ({
      //     description: item.description,
      //     amount: item.price * item.quantity * 100,
      //     category: item.category || undefined,
      //     quantity: item.quantity || 1,
      //     taxCode: 'txcd_99999999',
      //   })),
      // });
      const response = await createInvoice({
        invoiceId: currentInvoice._id,
      });

      if (response.success && response.data.scheduleId) {
        watchIds([response.data.scheduleId], {
          onSuccess: () => {
            navigate({ to: '/dashboard/invoices' });
          },
          onError: failedIds => {
            // Maybe show an error message or handle failed invites
            console.error('Failed invites:', failedIds);
          },
        });
      } else {
        toast.error(response.message);
      }
    },
  });

  // Add this effect to update form values when selectedServiceRequest changes
  React.useEffect(() => {
    if (selectedServiceRequest) {
      form.setFieldValue(
        'recipientUserId',
        selectedServiceRequest.activeFleetDispatcherId || undefined
      );
      form.setFieldValue(
        'recipientGroupId',
        selectedServiceRequest.fleetDispatchGroupId || undefined
      );
      form.setFieldValue(
        'tempDriverEmail',
        selectedServiceRequest.tempDriverEmail || undefined
      );
      form.setFieldValue(
        'tempDriverPhone',
        selectedServiceRequest.tempDriverPhone || undefined
      );
      form.setFieldValue(
        'tempDriverFirstName',
        selectedServiceRequest.tempDriverFirstName || undefined
      );
      form.setFieldValue('requestId', selectedServiceRequest._id || undefined);
    }
  }, [selectedServiceRequest, form]);

  // console.log('selectedServiceRequest', selectedServiceRequest);

  // console.log('user', user);

  const { Field, Subscribe, handleSubmit, reset, store } = form;

  const items = useStore(store, s => s.values.items);

  if (!user) {
    return null;
  }

  return (
    <form
      onSubmit={e => {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit();
      }}
      style={{ width: '100%' }}
    >
      <Card>
        <CardHeader
          title={!currentInvoice ? 'Add Invoice' : 'View Invoice'}
          sx={{ mb: 3 }}
        />
        <Divider />
        <Stack sx={{ p: 3 }}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            sx={{ p: 3 }}
          >
            <Field
              name="requestId"
              validators={{
                onChange: zid('requests').nullable(),
              }}
              children={({ state, handleChange }) => (
                <Autocomplete
                  disabled={!!currentInvoice}
                  fullWidth
                  options={requests || []}
                  getOptionLabel={option =>
                    `${option.caseNumber} - ${option.status}`
                  }
                  value={
                    selectedServiceRequest ||
                    requests?.find(r => r._id === state.value) ||
                    null
                  }
                  isOptionEqualToValue={(option, value) =>
                    option?._id === value?._id
                  }
                  onChange={(event, newValue) => {
                    setSelectedServiceRequest(newValue || null);
                    handleChange(newValue?._id || null);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Service Request"
                      required
                      error={state.meta.errors.length > 0}
                      helperText={state.meta.errors[0]}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {option.caseNumber} - {option.status}
                    </li>
                  )}
                  sx={{ width: { md: '33.33%' } }}
                />
              )}
            />

            <Field
              name="clientProvidedDueDate"
              children={({ state, handleChange }) => (
                <DesktopDatePicker
                  disabled={!!currentInvoice}
                  label="Due Date"
                  value={state.value ? dayjs(state.value) : null}
                  onChange={(newValue: dayjs.Dayjs | null) => {
                    handleChange(newValue ? newValue.toISOString() : null);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: state.meta.errors.length > 0,
                      helperText: state.meta.errors[0],
                    },
                  }}
                  sx={{
                    width: { md: '33.33%' },
                    [`& .${formHelperTextClasses.root}`]: {
                      bottom: { md: -40 },
                      position: { md: 'absolute' },
                    },
                  }}
                />
              )}
            />
            <Field
              name="purchaseOrderNumber"
              children={({ state, handleChange }) => (
                <TextField
                  disabled={!!currentInvoice}
                  fullWidth
                  label="Purchase Order Number"
                  value={state.value || ''}
                  onChange={e => handleChange(e.target.value)}
                  sx={{ width: { md: '33.33%' } }}
                />
              )}
            />
          </Stack>

          <Stack
            spacing={{ xs: 3, md: 5 }}
            direction={{ xs: 'column', md: 'row' }}
            divider={
              <Divider
                flexItem
                orientation={mdUp ? 'vertical' : 'horizontal'}
                sx={{ borderStyle: 'dashed' }}
              />
            }
            sx={{ p: 3 }}
          >
            <Stack sx={{ width: 1 }}>
              <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                <Typography
                  variant="h6"
                  sx={{ color: 'text.disabled', flexGrow: 1 }}
                >
                  From:
                </Typography>
              </Stack>

              <Stack spacing={1}>
                <Typography variant="subtitle2">
                  {user.company.name} — {user.location.name}
                </Typography>
                <Typography variant="body2">{user.location.address}</Typography>
                <Typography variant="body2">
                  {user.location.phoneNumber}
                </Typography>
              </Stack>
            </Stack>

            <Stack sx={{ width: 1 }}>
              <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                <Typography
                  variant="h6"
                  sx={{ color: 'text.disabled', flexGrow: 1 }}
                >
                  To:
                </Typography>
              </Stack>

              {enhancedRequest?.activeDriver && (
                <Stack spacing={1}>
                  <Typography variant="subtitle2">
                    {enhancedRequest?.activeDriver?.clerkUser?.firstName}{' '}
                    {enhancedRequest?.activeDriver?.clerkUser?.lastName}
                  </Typography>
                  <Typography variant="body2">
                    {
                      enhancedRequest?.activeDriver?.clerkUser
                        ?.primaryEmailAddress?.emailAddress
                    }
                  </Typography>
                  <Typography variant="body2">
                    {
                      enhancedRequest?.activeDriver?.clerkUser
                        ?.primaryPhoneNumber?.phoneNumber
                    }
                  </Typography>
                </Stack>
              )}

              {enhancedRequest?.tempDriverFirstName && (
                <Stack spacing={1}>
                  <Typography variant="subtitle2">
                    {enhancedRequest?.tempDriverFirstName}{' '}
                    {enhancedRequest?.tempDriverLastName}
                  </Typography>
                  <Typography variant="body2">
                    {enhancedRequest?.tempDriverEmail}
                  </Typography>
                  <Typography variant="body2">
                    {enhancedRequest?.tempDriverPhone}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>

          <Stack sx={{ p: 3 }}>
            <Stack spacing={2} sx={{ width: isMobile ? '100%' : '50%' }}>
              <Field
                name="description"
                children={({ state, handleChange }) => (
                  <TextField
                    disabled={!!currentInvoice}
                    multiline
                    rows={4}
                    label="Description"
                    value={state.value || ''}
                    onChange={e => handleChange(e.target.value)}
                  />
                )}
              />
            </Stack>
          </Stack>

          <InvoiceNewEditDetails
            form={form}
            items={items}
            disabled={!!currentInvoice}
          />
        </Stack>
      </Card>
      <Stack
        justifyContent="flex-end"
        direction="row"
        spacing={2}
        sx={{ mt: 3 }}
      >
        {!currentInvoice && (
          <>
            <Subscribe
              selector={state => [state.canSubmit, state.isSubmitting]}
              children={([canSubmit, isSubmitting]) => {
                return (
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={isSubmitting}
                    // disabled={!canSubmit}
                  >
                    {!currentInvoice ? 'Add Invoice' : 'Save changes'}
                  </LoadingButton>
                );
              }}
            />
          </>
        )}

        {currentInvoice &&
          currentInvoice.status !== 'PAID' &&
          currentInvoice.status !== 'CANCELLED' && (
            <Button
              size="large"
              variant="outlined"
              color="error"
              onClick={() => navigate({ to: '/dashboard/invoices' })}
            >
              Cancel Invoice
            </Button>
          )}
      </Stack>
    </form>
  );
}
