import { getManyFrom } from 'convex-helpers/server/relationships';
import { zid } from 'convex-helpers/server/zod';
import { ConvexError } from 'convex/values';
import { z } from 'zod';
import { Id } from '../_generated/dataModel';
import { MutationCtx, QueryCtx } from '../_generated/server';
import {
  baseCompanyInput,
  CompanyCreationResult,
  editCompanyInfoSchema,
  invitationCompanyInput,
} from '../schema/entities/companies';
import {
  adminLocationInputForFleetAndProviderLocation,
  baseLocationInput,
  invitationLocationInput,
  LocationCreationResult,
} from '../schema/entities/groups/groups';
import { getUserContext } from '../utils/getUserContext';
import {
  zInternalMutation,
  zInternalQuery,
  zMutation,
  zQuery,
} from './helpers/zodHelpers';
import { updateLocationServices } from './serviceCategories';

// TODO: RF - since this is exposed to non authenticate users we need at least recaptcha on the front-end
// and check for malformed/duplicate EIN, and a mechanism to periodically delete these if the owner has not verified their account after X number of days ... done some of this
// export const initialSignUp = zAction({
//   args: signUpSchema.shape,
//   async handler(ctx, signUp) {
//     const { email, phone, firstName, lastName } = signUp;

//     const fullName = `${firstName} ${lastName}`;

//     try {
//       // TODO: RF - this part can fail ... and if it does it doesn't run in the same transactional context...
//       // We need to find out of there's still a way to roll back if this part fails...
//       // and if not... we need a way to recover from this in the (hopefully rare) event that it does occur
//       // TODO: RF we also need to decide on optional/nullable...
//       // nullable.default(null) in convex doesn't seem to translate into fields being implicitly set to null which I thought it would
//       // they are still undefined... which they would probably also be if we just had "optional"..
//       // So... right now there's a discrepency where we are typing them as nullable and null but they're undefined at rest
//       // We either explicitly set to null here... but we need to make sure the typing forces us to do this so we don't miss anything
//       // Or we make them optional and we deal with certain field values being undefined which might be fine as well
//       // We also need to return something informative to the user asking them to try again or something
//       const clerkUser = await clerkClient.users.createUser({
//         emailAddress: [email],
//         phoneNumber: [phone],
//         firstName: firstName,
//         lastName: lastName,
//         publicMetadata: {
//           isSuperAdmin: false,
//           companyOwner: true,
//         },
//       });

//       const { userId, companyId } = await ctx.runMutation(
//         internal.functions.companies.initialSignUpMutations,
//         { ...signUp, fullName, subject: clerkUser.id }
//       );
//     } catch (e) {
//       return false;
//     }

//     return true;
//   },
// });

// export const initialSignUpMutations = zInternalMutation({
//   args: signUpSchema
//     .merge(otherNameComponentsSchema)
//     .merge(z.object({ subject: z.string() })).shape,
//   async handler(ctx, signUp) {
//     const { email, phone, firstName, fullName, lastName, ein, name, subject } =
//       signUp;

//     // Attempt to create the company, if a company with the same ein exists, a ConvexError will be thrown
//     const companyId = await ctx.db.insert('companies', {
//       ein,
//       name,
//       companyType: 'FLEET',
//     });

//     const userId = await ctx.db.insert('users', {
//       companyId,
//       clerkUser: {
//         id: subject,
//         firstName,
//         lastName,
//         fullName,
//         primaryEmailAddress: {
//           emailAddress: email,
//         },
//         primaryPhoneNumber: {
//           phoneNumber: phone,
//         },
//         imageUrl: '',
//       },
//     });

//     await ctx.db.patch(companyId, {
//       ownerId: userId,
//     });

//     return { userId, companyId };
//   },
// });

// export const finishCompanySignUp = zMutation({
//   args: {
//
//   },
//   async handler(ctx, args) {
//t
//   },
// });

// TODO: RLS = get companyId from auth and ensure it matches the companyId they are querying
// This will be specific for users getting their own company info (signup/verification/subscription process, etc.)
// There will be fields that company users/owners will be privy to, and a subset of company info that other companies that have formed a trust can access
// But that will come later ...
export const getCompany = zQuery({
  args: { companyId: zid('companies') },
  handler: async (ctx, { companyId }) => {
    const { user, company } = await getUserContext(ctx);

    if (!user.clerkUser.isSuperAdmin && companyId !== company._id) {
      throw new Error('Unauthorized');
    }

    return await ctx.db.get(user.impersonatingCompanyId ?? companyId);
  },
});

export const getCompanies = zQuery({
  handler: async ctx => {
    const { user, primaryLocation, company } = await getUserContext(ctx);

    // TODO: For now... will need to open this up or have a separate function
    if (!user.clerkUser.isSuperAdmin && !user.isCompanyPrimaryAdmin) {
      throw new Error('Unauthorized');
    }

    if (user.isCompanyPrimaryAdmin) {
      const c = await ctx.db.get(company._id);
      if (!c) {
        return [];
      }
      return [c];
    }

    if (user.impersonatingLocationId && primaryLocation.companyId) {
      const c = await ctx.db.get(primaryLocation.companyId);
      if (!c) {
        return [];
      }
      return [c];
    }

    // TODO: Will be lots so need paging.. same with groups/locations, etc.
    return ctx.db.query('companies').collect();
  },
});

export const getLocations = zQuery({
  handler: async ctx => {
    const { user, roles, primaryLocation, isDispatcher } =
      await getUserContext(ctx);

    // For super admins
    if (user.clerkUser.isSuperAdmin) {
      // If impersonating, behave like a regular dispatcher for that location
      if (user.impersonatingLocationId) {
        return getManyFrom(
          ctx.db,
          'groups',
          'by_companyId',
          primaryLocation.companyId
        );
      }
      // Otherwise get all physical locations TODO: need to make this more efficient/take snapshots or something
      return getManyFrom(
        ctx.db,
        'groups',
        'by_representsPhysicalLocation',
        true
      );
    }

    if (!isDispatcher) {
      throw new ConvexError('Must be a dispatcher to access locations');
    }

    // Get locations assigned to their dispatch group, but also... if a dispatcher has switched to a non central dispatch location... we still want to let them move around
    // TODO: Long term we might need something like this for more granular location management permissions, but for now just get everything under the company
    // const [
    //   locationsManagedByMyLocation,
    //   locationsManagedByMyDefaultDispatchLocation,
    // ] = await Promise.all([
    //   getManyFrom(
    //     ctx.db,
    //     'groups',
    //     'by_defaultDispatchGroupId',
    //     primaryLocation._id
    //   ),
    //   getManyFrom(
    //     ctx.db,
    //     'groups',
    //     'by_defaultDispatchGroupId',
    //     primaryLocation.defaultDispatchGroupId
    //   ),
    // ]);

    return getManyFrom(
      ctx.db,
      'groups',
      'by_companyId',
      primaryLocation.companyId
    );
  },

  // return uniqBy(
  //   [
  //     ...locationsManagedByMyLocation,
  //     ...locationsManagedByMyDefaultDispatchLocation,
  //   ].filter(l => l !== null && !!l),
  //   '_id'
  // );
});

// TODO: Need to change this name as we are dual purposing... we also use this for fleets
export const getLocationById = zQuery({
  args: { id: zid('groups') },
  handler: async (ctx, { id }) => {
    return await ctx.db.get(id);
  },
});

export const existingUser = zInternalQuery({
  args: { email: z.string().email(), phone: z.string() },
  handler: async (ctx, { email, phone }) => {
    return getExistingUser(ctx, email, phone);
  },
});

export const getExistingUser = async (
  ctx: QueryCtx,
  email: string,
  phone: string
) => {
  const [byEmail, byPhone] = await Promise.all([
    ctx.db
      .query('users')
      .withIndex('by_primaryEmail', q =>
        q.eq('clerkUser.primaryEmailAddress.emailAddress', email as any)
      )
      .first(),
    ctx.db
      .query('users')
      .withIndex('by_primaryPhone', q =>
        q.eq('clerkUser.primaryPhoneNumber.phoneNumber', phone as any)
      )
      .first(),
  ]);

  return byEmail ?? byPhone;
};

//confirm company owner flow

export const editCompanyInfo = zMutation({
  args: editCompanyInfoSchema.shape,
  handler: async (ctx, args) => {
    const { user, company } = await getUserContext(ctx);

    if (
      !user.clerkUser.isSuperAdmin &&
      !(user.isCompanyPrimaryAdmin && args.companyId === company._id)
    ) {
      throw new Error(
        'Unauthorized. Only company admins can update company information.'
      );
    }

    try {
      const {
        companyId,
        accountExecutiveId,
        customerSuccessRepId,
        ...restOfArgs
      } = args;

      if (user.clerkUser.isSuperAdmin) {
        await ctx.db.patch(companyId, {
          ...restOfArgs,
          accountExecutiveId: accountExecutiveId || undefined,
          customerSuccessRepId: customerSuccessRepId || undefined,
        });
      } else {
        await ctx.db.patch(companyId, restOfArgs);
      }

      return {
        success: true,
        message: 'Company info updated successfully',
      };
    } catch (error) {
      console.log(error);
      throw new Error('Failed to update company info');
    }
  },
});

export const getLocationsForCompany = zQuery({
  args: { companyId: zid('companies') },
  handler: (ctx, { companyId }) => {
    return getManyFrom(ctx.db, 'groups', 'by_companyId', companyId);
  },
});

// Direct company creation by admin
export const createCompany = zMutation({
  args: baseCompanyInput.shape,
  handler: async (ctx, input) => {
    const { user } = await getUserContext(ctx);

    // Verify user is superadmin
    if (!user.clerkUser.isSuperAdmin) {
      throw new Error('Only super admins can directly create companies');
    }

    const existing = await ctx.db
      .query('companies')
      .withIndex('by_name', q => q.eq('name', input.name))
      .first();

    if (existing) {
      return {
        companyId: existing._id,
        success: false,
        message: 'Company with this name already exists',
      };
    }

    return await createCompanyByAdminHelper(ctx, {
      ...input,
    });
  },
});

// Direct location creation by admin
export const createLocation = zMutation({
  args: {
    input: adminLocationInputForFleetAndProviderLocation,
    serviceIds: z.array(zid('services')),
  },
  handler: async (ctx, { input, serviceIds }) => {
    const { user, roles, company, isDispatcher } = await getUserContext(ctx);

    if (!user.clerkUser.isSuperAdmin && !isDispatcher) {
      throw new Error('Not authorized to create locations');
    }

    console.log(input);

    const existing = await ctx.db
      .query('groups')
      .withIndex('by_location_address', q =>
        q.eq('location.address', input.location.address as any)
      )
      .first();

    if (existing) {
      return {
        locationId: existing._id,
        success: false,
        message: 'Location at this address already exists',
      };
    }

    const newLocation = await createLocationByAdminHelper(ctx, {
      ...input,
      companyId: isDispatcher ? company._id : input.companyId,
    });

    await updateLocationServices(
      ctx,
      newLocation.locationId,
      serviceIds,
      input.companyId
    );

    return {
      success: true,
      message: 'Location created',
    };
  },
});

export const editLocationInput = baseLocationInput
  .omit({
    // Remove fields that shouldn't be editable
    companyId: true,
    parentId: true,
  })
  .extend({
    // Add the location ID field for the update
    locationId: zid('groups'),
  });

export const updateLocation = zMutation({
  args: {
    input: editLocationInput,
    serviceIds: z.array(zid('services')),
  },
  handler: async (ctx, { input, serviceIds }) => {
    const { user, roles, isDispatcher } = await getUserContext(ctx);

    if (!user.clerkUser.isSuperAdmin && !isDispatcher) {
      throw new Error('Not authorized to update locations');
    }

    // Get the existing location
    const existingLocation = await ctx.db.get(input.locationId);
    if (!existingLocation) {
      return {
        success: false,
        message: 'Location not found',
      };
    }

    // Check if address is being changed and if new address already exists
    if (input.location.address !== existingLocation.location?.address) {
      const addressExists = await ctx.db
        .query('groups')
        .withIndex('by_location_address', q =>
          q.eq('location.address', input.location.address as any)
        )
        .first();

      if (addressExists && addressExists._id !== input.locationId) {
        return {
          success: false,
          message: 'Location at this address already exists',
        };
      }
    }

    // TODO: Need some more authZ here and in create... can't trust the client for groupids being passed
    // Update the location while preserving protected fields
    await Promise.all([
      ctx.db.patch(input.locationId, {
        name: input.name,
        location: input.location,
        description: input.description,
        showInProviderDirectory: input.showInProviderDirectory,
        hasVerifiedDispatchTeam: input.hasVerifiedDispatchTeam,
        hasVerifiedTechnicians: input.hasVerifiedTechnicians,
        isRetail: input.isRetail,
        simpleServiceAreaByMilesOut: input.simpleServiceAreaByMilesOut,
        contactInfo: input.contactInfo,
        acceptedPaymentMethods: input.acceptedPaymentMethods,
        defaultDispatchGroupId: input.defaultDispatchGroupId,
        locationType: input.locationType,
      }),
      updateLocationServices(
        ctx,
        existingLocation._id,
        serviceIds,
        existingLocation.companyId!
      ),
    ]);

    return {
      success: true,
      message: 'Location updated successfully',
      locationId: input.locationId,
    };
  },
});

/**
 * Core company creation logic used by  invitation flow
 */
export async function createCompanyViaInvitationHelper(
  ctx: MutationCtx,
  input: z.infer<typeof invitationCompanyInput>
): Promise<CompanyCreationResult> {
  try {
    const location = {
      latitude: input.location?.latitude || 0,
      longitude: input.location?.longitude || 0,
      address: input.location?.address || '',
      zip: input.location?.zip || '',
      lastUpdated: input.location?.lastUpdated || new Date().toISOString(),
    };

    const validLocation = !!location.latitude && !!location.longitude;

    const companyId = await ctx.db.insert('companies', {
      name: input.name,
      companyType: input.companyType,
      ein: input.ein,
      dotNo: input.dotNo,
      about: input.about,
      isAdminVerified: false,
      isSignUpFormCompleted: false,
      hasCompletedOnboarding: false,
      showInProviderDirectory: false,
      location: validLocation ? location : undefined,
      contactEmail: input.contactEmail,
      contactPhoneNo: input.contactPhoneNo,
      invitedByCompany: input.invitedByCompanyId,
      invitedByLocation: input.invitedByLocationId,
      invitedByUser: input.invitedByUserId,
      createdFromRequestId: input.createdFromRequestId,
      createdByUserDuringRequest: true,
    });

    return {
      companyId,
      success: true,
      message: 'Company created successfully',
    };
  } catch (error) {
    console.error('Error creating company:', error);
    throw error;
  }
}

/**
 * Core company creation logic used by direct admin creation
 */
export async function createCompanyByAdminHelper(
  ctx: MutationCtx,
  input: z.infer<typeof baseCompanyInput>
): Promise<CompanyCreationResult> {
  try {
    const location = {
      latitude: input.location?.latitude || 0,
      longitude: input.location?.longitude || 0,
      address: input.location?.address || '',
      zip: input.location?.zip || '',
      lastUpdated: input.location?.lastUpdated || new Date().toISOString(),
    };

    const validLocation = !!location.latitude && !!location.longitude;

    const companyId = await ctx.db.insert('companies', {
      createdByUserDuringRequest: false,
      name: input.name,
      companyType: input.companyType,
      ein: input.ein,
      dotNo: input.dotNo,
      about: input.about,
      isAdminVerified: true,
      isSignUpFormCompleted: false,
      hasCompletedOnboarding: false,
      showInProviderDirectory: true,
      location: validLocation ? location : undefined,
      contactEmail: input.contactEmail,
      contactPhoneNo: input.contactPhoneNo,
      customerSuccessRepId: input.customerSuccessRepId ?? undefined,
      accountExecutiveId: input.accountExecutiveId ?? undefined,
    });

    return {
      companyId,
      success: true,
      message: 'Company created successfully',
    };
  } catch (error) {
    console.error('Error creating company:', error);
    throw error;
  }
}

/**
 * Core location creation logic used by direct admin creation
 */
export async function createLocationByAdminHelper(
  ctx: MutationCtx,
  input: z.infer<typeof adminLocationInputForFleetAndProviderLocation>
): Promise<LocationCreationResult> {
  try {
    let defaultDispatchGroupId: undefined | Id<'groups'> =
      input.defaultDispatchGroupId;

    if (defaultDispatchGroupId) {
      const defaultDispatchGroup = await ctx.db.get(defaultDispatchGroupId);

      if (!defaultDispatchGroup) {
        throw new Error('Default dispatch group does not exist');
      }
    }

    const locationId = await ctx.db.insert('groups', {
      name: input.name,
      companyId: input.companyId,
      location: input.location,
      description: input.description,
      locationType: input.locationType,
      isActive: true,
      representsPhysicalLocation: input.representsPhysicalLocation,
      systemGenerated: false,
      hasVerifiedDispatchTeam: input.hasVerifiedDispatchTeam,
      hasVerifiedTechnicians: input.hasVerifiedTechnicians,
      showInProviderDirectory: input.showInProviderDirectory,
      defaultDispatchGroupId,
      parentId: input.parentId,
      contactInfo: input.contactInfo,
      isAdminVerified: true,
      hasCompletedOnboarding: true,
      createdByUserDuringRequest: false,
      acceptedPaymentMethods: input.acceptedPaymentMethods,
      simpleServiceAreaByMilesOut: input.simpleServiceAreaByMilesOut,
    });

    if (!defaultDispatchGroupId) {
      await ctx.db.patch(locationId, { defaultDispatchGroupId: locationId });
    }

    return {
      locationId,
      success: true,
      message: 'Location created successfully',
    };
  } catch (error) {
    console.error('Error creating location:', error);
    throw error;
  }
}

/**
 * Core location creation logic used by invitation flow
 */
export async function createLocationViaInvitationHelper(
  ctx: MutationCtx,
  input: z.infer<typeof invitationLocationInput>
): Promise<LocationCreationResult> {
  try {
    const locationId = await ctx.db.insert('groups', {
      name: input.name,
      companyId: input.companyId,
      location: input.location,
      description: input.description,
      locationType: input.locationType,
      isActive: true,
      representsPhysicalLocation: input.representsPhysicalLocation,
      systemGenerated: false,
      hasVerifiedDispatchTeam: false,
      hasVerifiedTechnicians: false,
      showInProviderDirectory: input.showInProviderDirectory,
      parentId: input.parentId,
      contactInfo: input.contactInfo,
      isAdminVerified: false,
      hasCompletedOnboarding: false,
      invitedByCompany: input.invitedByCompanyId,
      invitedByLocation: input.invitedByLocationId,
      invitedByUser: input.invitedByUserId,
      createdFromRequestId: input.createdFromRequestId,
      createdByUserDuringRequest: !!input.createdFromRequestId,
      acceptedPaymentMethods: input.acceptedPaymentMethods,
      simpleServiceAreaByMilesOut: input.simpleServiceAreaByMilesOut,
    });

    await ctx.db.patch(locationId, { defaultDispatchGroupId: locationId });

    return {
      locationId,
      success: true,
      message: 'Location created successfully',
    };
  } catch (error) {
    console.error('Error creating location:', error);
    throw error;
  }
}

// Query to search companies by name
export const searchCompaniesByName = zQuery({
  args: {
    searchTerm: z.string(),
    limit: z.number().optional().default(10),
  },
  handler: async (ctx, { searchTerm, limit }) => {
    // If empty string, return no results
    if (!searchTerm.trim()) {
      return [];
    }

    const { user } = await getUserContext(ctx);
    if (!user) throw new Error('Not authenticated');

    // Search using the search index and take first 10 results
    return await ctx.db
      .query('companies')
      .withSearchIndex('search', q => q.search('name', searchTerm))
      .take(limit);
  },
});

// Query to search groups (locations) by name
export const searchLocationsByName = zQuery({
  args: {
    searchTerm: z.string(),
    limit: z.number().default(10),
    forSpecificCompanyId: zid('companies').optional(),
  },
  handler: async (ctx, { searchTerm, limit, forSpecificCompanyId }) => {
    // If empty string, return no results
    if (!searchTerm.trim()) {
      return [];
    }

    const { user } = await getUserContext(ctx);
    if (!user) throw new Error('Not authenticated');

    // If searching within a specific company, use company index and filter by name
    if (forSpecificCompanyId) {
      const results = await ctx.db
        .query('groups')
        .withIndex('by_companyId', q => q.eq('companyId', forSpecificCompanyId))
        .collect();

      const searchTermLower = searchTerm.toLowerCase();
      return results
        .filter(location =>
          location.name.toLowerCase().includes(searchTermLower)
        )
        .slice(0, limit);
    }

    // Otherwise use search index for global search across all companies
    return await ctx.db
      .query('groups')
      .withSearchIndex('search', q => q.search('name', searchTerm))
      .take(limit);
  },
});

// update company with stripe info
export const updateGroupStripeInfo = zInternalMutation({
  args: {
    groupId: zid('groups'),
    stripeAccountId: z.string().optional(),
    isStripeConnected: z.boolean().optional(),
    stripeConnectedAccountOnboardingLink: z.string().optional(),
  },
  handler: async (ctx, args) => {
    await ctx.db.patch(args.groupId, {
      stripeAccountId: args.stripeAccountId || '',
      isStripeConnected: args.isStripeConnected || false,
      stripeConnectedAccountOnboardingLink:
        args.stripeConnectedAccountOnboardingLink,
    });

    // create connected group account
    await ctx.db.insert('connectedGroupAccounts', {
      groupId: args.groupId,
      stripeAccountId: args.stripeAccountId || '',
      status: 'PENDING',
      // figure out how to handle requirements current due, past due, disabled etc.
    });

    return {
      success: true,
      message: 'Stripe info updated successfully',
    };
  },
});
