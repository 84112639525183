import { redirect } from '@tanstack/react-router';
import { ConvexError } from 'convex/values';
import { toast } from 'src/minimal-theme/components/snackbar';

// TODO: Still needs work... want nicer error messages that don't show convex stuff
export async function handleConvexRouteQuery<T>(
  queryFn: () => Promise<T>,
  onError?: {
    redirectTo: string;
    // Optionally allow custom error handling before redirect
    handler?: (error: Error) => void;
  }
) {
  try {
    return await queryFn();
  } catch (err) {
    const message =
      err instanceof ConvexError
        ? err.data
        : err instanceof Error
          ? err.message
          : 'An error occurred';

    // Allow custom error handling
    onError?.handler?.(err as Error);

    // Default error toast
    toast.error(message);

    // Redirect
    throw redirect({ to: onError?.redirectTo ?? '/' });
  }
}
