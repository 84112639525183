import { api } from '@api';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import { useQuery } from 'convex/react';
import { useCallback, useState } from 'react';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Label } from 'src/minimal-theme/components/label';
import { RouterLink } from 'src/minimal-theme/components/routes/components/router-link';
import { Scrollbar } from 'src/minimal-theme/components/scrollbar';

import { Doc } from 'src/convex/_generated/dataModel';
import {
  emptyRows,
  getComparator,
  rowInPage,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/minimal-theme/components/table';
import { useBoolean } from 'src/minimal-theme/hooks/use-boolean';
import { useSetState } from 'src/minimal-theme/hooks/use-set-state';
import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';
import { varAlpha } from 'src/minimal-theme/theme/styles';
import { DeleteUserDialog } from 'src/sections/DeleteUserDialogButton';
import type { MechanicTableFilters } from 'src/types/user';
import { getStatusColor, MECHANIC_STATUS_OPTIONS } from 'src/utils/helper';
import { MechanicTableFiltersResult } from '../mechanic-table-filters-result';
import { MechanicTableRow } from '../mechanic-table-row';
import { MechanicTableToolbar } from '../mechanic-table-toolbar';

const STATUS_OPTIONS = [
  { value: 'all', label: 'All' },
  ...MECHANIC_STATUS_OPTIONS,
];

const TABLE_HEAD = [
  { id: 'clerkUser.firstName', label: 'Name' },
  { id: 'clerkUser.primaryPhoneNumber.phoneNumber', label: 'Phone Number' },
  { id: 'status', label: 'Status' },
  { id: 'requests', label: 'Active Requests' },
  { id: 'actions', label: 'Actions' },
];

export function MechanicListView() {
  const table = useTable();

  const confirm = useBoolean();

  // TODO: Change role passed based on company context
  const tableData = useQuery(
    api.functions.users.getUsersOfRoleForMultipleLocations,
    {
      roleType: 'TECHNICIAN_PROVIDER',
    }
  );

  const [userToDelete, setUserToDelete] = useState<Doc<'users'> | null>(null);

  const filters = useSetState<MechanicTableFilters>({
    name: '',
    status: 'all',
  });

  const dataFiltered = applyFilter({
    inputData: tableData ?? [],
    comparator: getComparator(table.order, table.orderBy),
    filters: filters.state,
  });

  const dataInPage = rowInPage(dataFiltered, table.page, table.rowsPerPage);

  const canReset = !!filters.state.name || filters.state.status !== 'all';

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleDeleteRow = useCallback((id: string) => {
    console.log('id', id);
  }, []);

  const handleDeleteRows = useCallback(() => {
    console.log('delete rows');
  }, []);

  const handleEditRow = useCallback((id: string) => {}, []);

  const handleFilterStatus = useCallback(
    (_event: React.SyntheticEvent, newValue: string) => {
      table.onResetPage();
      filters.setState({ status: newValue });
    },
    [filters, table]
  );

  if (!tableData) return null;

  return (
    <>
      <DashboardContent>
        <Button
          component={RouterLink}
          href={'/dashboard/mechanics/new'}
          color="primary"
          variant="contained"
          startIcon={<Iconify icon="mingcute:add-line" />}
          sx={{ width: 'fit-content', mb: 2, ml: 'auto' }}
        >
          Add Mechanic
        </Button>
        <Card>
          <Tabs
            value={filters.state.status}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: theme =>
                `inset 0 -2px 0 0 ${varAlpha(theme.vars.palette.grey['500Channel'], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map(tab => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                icon={
                  <Label
                    variant={
                      ((tab.value === 'all' ||
                        tab.value === filters.state.status) &&
                        'filled') ||
                      'soft'
                    }
                    color={getStatusColor(tab.value)}
                  >
                    {['AVAILABLE', 'OFFLINE', 'BUSY'].includes(tab.value)
                      ? tableData.filter(user => user.status === tab.value)
                          .length
                      : tableData.length}
                  </Label>
                }
              />
            ))}
          </Tabs>

          <MechanicTableToolbar
            filters={filters}
            onResetPage={table.onResetPage}
          />

          {canReset && (
            <MechanicTableFiltersResult
              filters={filters}
              totalResults={dataFiltered.length}
              onResetPage={table.onResetPage}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <Box sx={{ position: 'relative' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={dataFiltered.length}
              onSelectAllRows={checked =>
                table.onSelectAllRows(
                  checked,
                  dataFiltered.map(row => row._id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table
                size={table.dense ? 'small' : 'medium'}
                sx={{ minWidth: 960 }}
              >
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataFiltered.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={checked =>
                    table.onSelectAllRows(
                      checked,
                      dataFiltered.map(row => row._id)
                    )
                  }
                />

                <TableBody>
                  {dataInPage.map(row => (
                    <MechanicTableRow
                      key={row._id}
                      row={row}
                      selected={table.selected.includes(row._id)}
                      onSelectRow={() => table.onSelectRow(row._id)}
                      setUserToDelete={setUserToDelete}
                    />
                  ))}

                  <TableEmptyRows
                    height={table.dense ? 56 : 56 + 20}
                    emptyRows={emptyRows(
                      table.page,
                      table.rowsPerPage,
                      dataFiltered.length
                    )}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </Box>

          <TablePaginationCustom
            page={table.page}
            dense={table.dense}
            count={dataFiltered.length}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onChangeDense={table.onChangeDense}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        </Card>
        <DeleteUserDialog
          user={userToDelete}
          onClose={() => setUserToDelete(null)}
        />
      </DashboardContent>

      {/* <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete{' '}
            <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      /> */}
    </>
  );
}

type ApplyFilterProps = {
  inputData: any[];
  filters: MechanicTableFilters;
  comparator: (a: any, b: any) => number;
};

function applyFilter({ inputData, comparator, filters }: ApplyFilterProps) {
  const { name, status } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map(el => el[0]);

  if (name) {
    inputData = inputData.filter(
      user =>
        user.clerkUser.firstName.toLowerCase().indexOf(name.toLowerCase()) !==
          -1 ||
        user.clerkUser.lastName.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  if (status !== 'all') {
    inputData = inputData.filter(user => user.status === status);
  }

  // if (role.length) {
  //   inputData = inputData.filter(user => role.includes(user.role));
  // }

  return inputData;
}
