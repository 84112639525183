import { Card, CardContent, CardHeader } from '@mui/material';

export const RequestLocationCard = ({
  children,
  title = 'Request Location',
  subheader = 'Location details for the request',
  action,
}: {
  children: React.ReactNode;
  title?: string;
  subheader?: string;
  action?: React.ReactNode;
}) => (
  <Card>
    <CardHeader title={title} subheader={subheader} action={action} />
    <CardContent>{children}</CardContent>
  </Card>
);
