import { RequestActionProps } from 'src/sections/request/types/RequestActionProps';

export const getDeclineAssignmentActionState = ({
  requestStateInformation,
}: RequestActionProps) => {
  const { isWaitingForTechnician, isAssignedToMe, isTechnician } =
    requestStateInformation;

  const isVisible = isWaitingForTechnician && isTechnician;

  if (!isVisible) {
    return {
      isVisible: false,
      isEnabled: false,
      message: '',
    };
  }

  const declineMessage = 'Decline Request';
  const canPerform = isAssignedToMe;

  return {
    isVisible: true,
    isEnabled: canPerform,
    message: canPerform ? declineMessage : `Waiting On Technician`,
    confirmationTitle: declineMessage,
    confirmationMessage: 'Are you sure you want to decline this assignment?',
  };
};
