import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from '@tanstack/react-router';
import { useMutation, useQuery } from 'convex/react';
import { useCallback } from 'react';
import { api } from 'src/convex/_generated/api';
import { useScheduledActions } from 'src/hooks/useScheduledActions';
import { ConfirmDialog } from 'src/minimal-theme/components/custom-dialog';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Label } from 'src/minimal-theme/components/label';
import { RouterLink } from 'src/minimal-theme/components/routes/components/router-link';
import { Scrollbar } from 'src/minimal-theme/components/scrollbar';
import { toast } from 'src/minimal-theme/components/snackbar';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/minimal-theme/components/table';
import { useBoolean } from 'src/minimal-theme/hooks/use-boolean';
import { useSetState } from 'src/minimal-theme/hooks/use-set-state';
import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';
import { varAlpha } from 'src/minimal-theme/theme/styles';
import { fIsAfter, fIsBetween } from 'src/minimal-theme/utils/format-time';
import { sumBy } from 'src/minimal-theme/utils/helper';
import { InvoiceTableFilters } from 'src/types/invoice';
import { InvoiceAnalytic } from '../invoice-analytic';
import { InvoiceTableFiltersResult } from '../invoice-table-filters-result';
import { InvoiceTableRow } from '../invoice-table-row';
import { InvoiceTableToolbar } from '../invoice-table-toolbar';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'request', label: 'Case Number' },
  { id: 'customer', label: 'Customer' },
  { id: 'createDate', label: 'Creation Date' },
  { id: 'dueDate', label: 'Due Date' },
  { id: 'price', label: 'Amount' },
  { id: 'status', label: 'Status' },
  { id: '' },
];

export function InvoiceListView() {
  const theme = useTheme();

  const user = useQuery(api.functions.users.getMe);

  const table = useTable({ defaultOrderBy: 'createDate' });

  const confirm = useBoolean();

  const tableData = useQuery(
    api.functions.invoices.getAllInvoicesForServiceDispatcher,
    {
      paginationOpts: {
        numItems: 10,
        cursor: null,
      },
      locationGroupId: user?.primaryLocationGroupId!,
    }
  );

  const filters = useSetState<any>({
    name: '',
    service: [],
    status: 'all',
    startDate: null,
    endDate: null,
  });

  const dateError = fIsAfter(filters.state.startDate, filters.state.endDate);

  const dataFiltered = applyFilter({
    inputData: tableData?.page || [],
    comparator: getComparator(table.order, table.orderBy),
    filters: filters.state,
    dateError,
  });

  console.log('tableData', tableData);

  const canReset =
    !!filters.state.name ||
    filters.state.service.length > 0 ||
    filters.state.status !== 'all' ||
    (!!filters.state.startDate && !!filters.state.endDate);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const getInvoiceLength = (status: string) =>
    (tableData?.page || []).filter(item => item.status === status).length;

  const getTotalAmount = (status: string) =>
    sumBy(
      (tableData?.page || []).filter(item => item.status === status),
      invoice => (invoice.amount ?? 0) / 100
    );

  const getPercentByStatus = (status: string) =>
    (getInvoiceLength(status) / (tableData?.page.length || 1)) * 100;

  const TABS = [
    {
      value: 'all',
      label: 'All',
      color: 'default',
      count: dataFiltered.length,
    },
    {
      value: 'PAID',
      label: 'Paid',
      color: 'success',
      count: getInvoiceLength('PAID'),
    },
    {
      value: 'PENDING',
      label: 'Pending',
      color: 'warning',
      count: getInvoiceLength('PENDING'),
    },
    {
      value: 'OVERDUE',
      label: 'Overdue',
      color: 'error',
      count: getInvoiceLength('OVERDUE'),
    },
    {
      value: 'DRAFT',
      label: 'Draft',
      color: 'default',
      count: getInvoiceLength('DRAFT'),
    },
  ] as const;

  const handleEditRow = useCallback((id: string) => {
    console.log('edit');
  }, []);

  const handleViewRow = useCallback((id: string) => {
    console.log('view');
  }, []);

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      console.log('newValue', newValue);
      table.onResetPage();
      filters.setState({ status: newValue });
    },
    [filters, table]
  );

  const location = useQuery(
    api.functions.companies.getLocationById,
    user?.primaryLocationGroupId ? { id: user.primaryLocationGroupId } : 'skip'
  );

  console.log('location', location);

  const createConnectedAccount = useMutation(
    api.functions.invoices.createConnectedAccount
  );

  const navigate = useNavigate();

  if (!user) return;

  const { watchIds } = useScheduledActions();

  return (
    <>
      <DashboardContent>
        {!location?.stripeAccountId && (
          <Alert
            severity="warning"
            sx={{ mb: 3, py: 2 }}
            action={
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  const response = await createConnectedAccount({
                    locationGroupId: user.primaryLocationGroupId!,
                  });

                  console.log('response', response);

                  if (response.success && response.data.scheduleId) {
                    watchIds([response.data.scheduleId], {
                      onSuccess: () => {
                        toast.success(
                          'Stripe Connected account successfully created'
                        );
                      },
                    });
                  } else {
                    toast.error(response.message);
                  }
                }}
              >
                Create Stripe Account
              </Button>
            }
          >
            Your location doesn't have an active Stripe account. Please click
            the button to start the setup process.
          </Alert>
        )}

        {location?.stripeAccountId && !location.isStripeConnected && (
          <Alert
            severity="info"
            sx={{ mb: 3, py: 2 }}
            action={
              <Button
                variant="contained"
                color="primary"
                href={location.stripeConnectedAccountOnboardingLink}
                // target="_blank"
              >
                Finish Stripe Setup
              </Button>
            }
          >
            You have successfully setup a Stripe account! Please click the
            button to finish the setup process.
          </Alert>
        )}
        <Button
          component={RouterLink}
          href={'/dashboard/invoices/new'}
          color="primary"
          variant="contained"
          startIcon={<Iconify icon="mingcute:add-line" />}
          sx={{ width: 'fit-content', mb: 2, ml: 'auto' }}
        >
          Add Invoice
        </Button>
        <Card sx={{ mb: { xs: 3, md: 5 } }}>
          <Scrollbar sx={{ minHeight: 108 }}>
            <Stack
              direction="row"
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderStyle: 'dashed' }}
                />
              }
              sx={{ py: 2 }}
            >
              <InvoiceAnalytic
                title="Total"
                total={(tableData?.page || []).length}
                percent={100}
                price={sumBy(
                  tableData?.page || [],
                  invoice => (invoice.amount ?? 0) / 100
                )}
                icon="solar:bill-list-bold-duotone"
                color={theme.vars.palette.info.main}
              />

              <InvoiceAnalytic
                title="Paid"
                total={getInvoiceLength('PAID')}
                percent={getPercentByStatus('PAID')}
                price={getTotalAmount('PAID')}
                icon="solar:file-check-bold-duotone"
                color={theme.vars.palette.success.main}
              />

              <InvoiceAnalytic
                title="Pending"
                total={getInvoiceLength('PENDING')}
                percent={getPercentByStatus('PENDING')}
                price={getTotalAmount('PENDING')}
                icon="solar:sort-by-time-bold-duotone"
                color={theme.vars.palette.warning.main}
              />

              <InvoiceAnalytic
                title="Overdue"
                total={getInvoiceLength('OVERDUE')}
                percent={getPercentByStatus('OVERDUE')}
                price={getTotalAmount('OVERDUE')}
                icon="solar:bell-bing-bold-duotone"
                color={theme.vars.palette.error.main}
              />

              <InvoiceAnalytic
                title="Draft"
                total={getInvoiceLength('DRAFT')}
                percent={getPercentByStatus('DRAFT')}
                price={getTotalAmount('DRAFT')}
                icon="solar:file-corrupted-bold-duotone"
                color={theme.vars.palette.text.secondary}
              />
            </Stack>
          </Scrollbar>
        </Card>

        <Card>
          <Tabs
            value={filters.state.status}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: `inset 0 -2px 0 0 ${varAlpha(theme.vars.palette.grey['500Channel'], 0.08)}`,
            }}
          >
            {TABS.map(tab => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                iconPosition="end"
                icon={
                  <Label
                    variant={
                      ((tab.value === 'all' ||
                        tab.value === filters.state.status) &&
                        'filled') ||
                      'soft'
                    }
                    color={tab.color}
                  >
                    {tab.count}
                  </Label>
                }
              />
            ))}
          </Tabs>

          <InvoiceTableToolbar
            filters={filters}
            dateError={dateError}
            onResetPage={table.onResetPage}
            options={{
              services: [],
            }}
          />

          {canReset && (
            <InvoiceTableFiltersResult
              filters={filters}
              onResetPage={table.onResetPage}
              totalResults={dataFiltered.length}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <Box sx={{ position: 'relative' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={dataFiltered.length}
              onSelectAllRows={checked => {
                table.onSelectAllRows(
                  checked,
                  dataFiltered.map(row => row.id)
                );
              }}
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="iconamoon:send-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="solar:printer-minimalistic-bold" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={confirm.onTrue}>
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />

            <Scrollbar sx={{ minHeight: 444 }}>
              <Table
                size={table.dense ? 'small' : 'medium'}
                sx={{ minWidth: 800 }}
              >
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataFiltered.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={checked =>
                    table.onSelectAllRows(
                      checked,
                      dataFiltered.map(row => row.id)
                    )
                  }
                />

                <TableBody>
                  {dataFiltered
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map(row => (
                      <InvoiceTableRow
                        key={row._id}
                        row={row}
                        selected={table.selected.includes(row._id)}
                        onSelectRow={() => table.onSelectRow(row._id)}
                        onViewRow={() => handleViewRow(row._id)}
                        onEditRow={() => handleEditRow(row._id)}
                      />
                    ))}

                  <TableEmptyRows
                    height={table.dense ? 56 : 56 + 20}
                    emptyRows={emptyRows(
                      table.page,
                      table.rowsPerPage,
                      dataFiltered.length
                    )}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </Box>

          <TablePaginationCustom
            page={table.page}
            dense={table.dense}
            count={dataFiltered.length}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onChangeDense={table.onChangeDense}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        </Card>
      </DashboardContent>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete{' '}
            <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

type ApplyFilterProps = {
  dateError: boolean;
  inputData: any[];
  filters: InvoiceTableFilters;
  comparator: (a: any, b: any) => number;
};

function applyFilter({
  inputData,
  comparator,
  filters,
  dateError,
}: ApplyFilterProps) {
  const { name, status, service, startDate, endDate } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map(el => el[0]);

  if (name) {
    inputData = inputData.filter(
      invoice =>
        invoice._id.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
        invoice.recipientUserId.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  if (status !== 'all') {
    inputData = inputData.filter(invoice => invoice.status === status);
  }

  if (service.length) {
    inputData = inputData.filter(invoice =>
      invoice.items.some((filterItem: any) =>
        service.includes(filterItem.service)
      )
    );
  }

  if (!dateError) {
    if (startDate && endDate) {
      inputData = inputData.filter(invoice =>
        fIsBetween(invoice.createDate, startDate, endDate)
      );
    }
  }

  return inputData;
}
