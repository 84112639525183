import { Alert } from '@mui/material';
import { useLocationTracking } from 'src/context/LocationTrackingProvider';
import { Iconify } from 'src/minimal-theme/components/iconify';

export const LocationTrackingAlert = () => {
  const { isTracking, isInitializing } = useLocationTracking();

  if (!isTracking || isInitializing) return null;

  return (
    <Alert
      severity="info"
      icon={<Iconify icon="solar:map-point-wave-bold" />}
      sx={{ fontWeight: 700 }}
    >
      {`Tracking Location`}
    </Alert>
  );
};
