import { RequestActionProps } from 'src/sections/request/types/RequestActionProps';

export const getDriverShareLocationActionState = ({
  requestStateInformation,
}: RequestActionProps) => {
  const { isAssignedToMe, needsLocationConfirmation } = requestStateInformation;

  const isVisible = needsLocationConfirmation;

  if (!isVisible) {
    return {
      isVisible: false,
      isEnabled: false,
      message: '',
    };
  }

  const acceptMessage = 'Share Location';
  const canPerform = isAssignedToMe;

  return {
    isVisible: true,
    isEnabled: canPerform,
    message: canPerform ? acceptMessage : `Waiting On Driver`,
    confirmationTitle: acceptMessage,
    confirmationMessage:
      'Are you sure you want to share location and send back to dispatch?',
  };
};
