import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useQuery } from 'convex/react';
import { api } from 'src/convex/_generated/api';
import {
  CustomPopover,
  usePopover,
} from 'src/minimal-theme/components/custom-popover';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Label } from 'src/minimal-theme/components/label';
import { MuiTypeSafeLink } from 'src/minimal-theme/components/routes/components/mui-link';
import { useBoolean } from 'src/minimal-theme/hooks/use-boolean';
import { fCurrency } from 'src/minimal-theme/utils/format-number';
import { fDate } from 'src/minimal-theme/utils/format-time';

type InvoiceTableRowProps = {
  row: any;
  selected: boolean;
  onSelectRow: () => void;
  onViewRow: () => void;
  onEditRow: () => void;
};

export function InvoiceTableRow({
  row,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
}: InvoiceTableRowProps) {
  const confirm = useBoolean();

  const popover = usePopover();

  const request = useQuery(api.functions.requests.getRequest, {
    requestId: row.requestId,
  });

  console.log(request);

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox
            checked={selected}
            onClick={onSelectRow}
            inputProps={{
              id: `row-checkbox-${row.id}`,
              'aria-label': `Row checkbox`,
            }}
          />
        </TableCell>
        <TableCell>
          <MuiTypeSafeLink
            to="/dashboard/invoices/$invoiceId"
            params={{ invoiceId: row._id }}
            color="inherit"
            underline="always"
            sx={{ cursor: 'pointer' }}
          >
            {request?.caseNumber}
          </MuiTypeSafeLink>
        </TableCell>

        <TableCell>
          <Stack spacing={2} direction="row" alignItems="center">
            <Stack
              sx={{
                typography: 'body2',
                flex: '1 1 auto',
                alignItems: 'flex-start',
              }}
            >
              {request?.fleetDispatcher?.clerkUser.firstName}{' '}
              {request?.fleetDispatcher?.clerkUser.lastName}
              <Box component="span" sx={{ color: 'text.disabled' }}>
                {
                  request?.fleetDispatcher?.clerkUser.primaryEmailAddress
                    .emailAddress
                }
              </Box>
              <Box component="span" sx={{ color: 'text.disabled' }}>
                {
                  request?.fleetDispatcher?.clerkUser.primaryPhoneNumber
                    .phoneNumber
                }
              </Box>
            </Stack>
          </Stack>
        </TableCell>

        <TableCell>
          <ListItemText
            primary={fDate(row._creationTime)}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell>
          <ListItemText
            primary={fDate(row.dueDate)}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell>{fCurrency(row.amount / 100)}</TableCell>

        <TableCell>
          <Label
            variant="soft"
            color={
              (row.status === 'PAID' && 'success') ||
              (row.status === 'PENDING' && 'warning') ||
              (row.status === 'OVERDUE' && 'error') ||
              'default'
            }
          >
            {row.status}
          </Label>
        </TableCell>

        <TableCell align="right" sx={{ px: 1 }}>
          <IconButton
            color={popover.open ? 'inherit' : 'default'}
            onClick={popover.onOpen}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        slotProps={{ arrow: { placement: 'right-top' } }}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              onViewRow();
              popover.onClose();
            }}
          >
            <Iconify icon="solar:eye-bold" />
            View
          </MenuItem>

          <MenuItem
            onClick={() => {
              onEditRow();
              popover.onClose();
            }}
          >
            <Iconify icon="solar:pen-bold" />
            Edit
          </MenuItem>

          {/* <MenuItem
            onClick={() => {
              confirm.onTrue();
              popover.onClose();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon="solar:trash-bin-trash-bold" />
            Delete
          </MenuItem> */}
        </MenuList>
      </CustomPopover>
    </>
  );
}
