import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  TextField,
} from '@mui/material';
import { useMutation, useQuery } from 'convex/react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { api } from 'src/convex/_generated/api';
import { Doc, Id } from 'src/convex/_generated/dataModel';

type UnitQuickAssignFormProps = {
  open: boolean;
  onClose: () => void;
  truckId: Id<'vehicles'>;
  currentDrivers?: Doc<'users'>[];
};

export function UnitQuickAssignForm({
  open,
  onClose,
  truckId,
  currentDrivers,
}: UnitQuickAssignFormProps) {
  const [selectedDriver, setSelectedDriver] = useState<Doc<'users'> | null>(
    null
  );
  const [selectedDriversToUnassign, setSelectedDriversToUnassign] = useState<
    Doc<'users'>[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setSelectedDriver(null);
      setSelectedDriversToUnassign([]);
    }
  }, [open]);

  const drivers = useQuery(
    api.functions.users.getUsersOfRoleForMultipleLocations,
    {
      roleType: 'DRIVER_FLEET',
    }
  );

  const assignVehicleToDriver = useMutation(
    api.functions.vehicles.assignVehicleToDriver
  );
  const unassignVehicleFromDriver = useMutation(
    api.functions.vehicles.unassignVehicleFromDriver
  );

  const handleUnassign = async () => {
    if (!selectedDriversToUnassign.length) return;
    setIsLoading(true);
    try {
      const res = await unassignVehicleFromDriver({
        vehicleId: truckId,
        driverIds: selectedDriversToUnassign.map(driver => driver._id),
      });
      if (res.success) {
        toast.success(res.message);
        onClose();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Failed to unassign drivers';
      toast.error(errorMessage);

      if (errorMessage.includes('No active assignments')) {
        toast.error(
          'Selected drivers are not currently assigned to this vehicle'
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAssign = async () => {
    if (!selectedDriver) return;
    setIsLoading(true);
    try {
      const res = await assignVehicleToDriver({
        driverId: selectedDriver._id,
        vehicleId: truckId,
      });

      if (res.success) {
        toast.success(res.message);
        onClose();
      } else {
        toast.error(res.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: 500 } }}
    >
      {currentDrivers ? (
        <>
          <DialogTitle>Unassign Vehicle</DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <Autocomplete
                multiple
                fullWidth
                options={currentDrivers ?? []}
                getOptionLabel={(option: Doc<'users'>) =>
                  `${option?.clerkUser?.firstName ?? ''} ${option?.clerkUser?.lastName ?? ''}`
                }
                value={selectedDriversToUnassign}
                onChange={(event, newValue: Doc<'users'>[]) => {
                  setSelectedDriversToUnassign(newValue);
                }}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select Drivers to Unassign"
                    required
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    {option.clerkUser.firstName} {option.clerkUser.lastName}
                  </li>
                )}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              onClick={handleUnassign}
              color="primary"
              variant="contained"
              loading={isLoading}
              disabled={selectedDriversToUnassign.length === 0}
            >
              Unassign
            </LoadingButton>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>Assign Vehicle</DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="driver-label">Driver</InputLabel>
              <Autocomplete
                fullWidth
                options={drivers ?? []}
                getOptionLabel={option =>
                  `${option?.clerkUser?.firstName} ${option?.clerkUser?.lastName}`
                }
                value={selectedDriver}
                onChange={(event, newValue) => {
                  setSelectedDriver(newValue);
                }}
                renderInput={params => (
                  <TextField {...params} label="Driver" required />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    {option.clerkUser.firstName} {option.clerkUser.lastName}
                  </li>
                )}
              />
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              onClick={handleAssign}
              color="primary"
              variant="contained"
              loading={isLoading}
              disabled={!selectedDriver}
            >
              Assign
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
