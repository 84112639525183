import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useState } from 'react';
import { toast } from 'src/minimal-theme/components/snackbar';
import { ActionConfirmationParams } from 'src/sections/request/actions/hooks/useActionConfirmationDialog';

export const ActionConfirmationDialog = ({
  open,
  onClose,
  title,
  message,
  onConfirm,
  additionalContent,
  disableConfirm,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
}: ActionConfirmationParams & { open: boolean; onClose: () => void }) => {
  const [isConfirming, setIsConfirming] = useState(false);

  const handleConfirm = async () => {
    setIsConfirming(true);
    try {
      await onConfirm();
      // TODO: add success toast after checking response... change typings
      onClose();
    } catch (error) {
      // Handle error
      toast.error('Failed to perform action');
    } finally {
      setIsConfirming(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={isConfirming ? () => {} : () => onClose}
      disableEscapeKeyDown={isConfirming}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        {additionalContent}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isConfirming}>
          {cancelButtonText}
        </Button>
        <LoadingButton
          loading={isConfirming}
          disabled={isConfirming || disableConfirm}
          onClick={handleConfirm}
          variant="contained"
        >
          {confirmButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
