import { ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { useElapsedTime } from 'src/sections/request/actions/hooks/useElapsedTime';

type ElapsedDisplayProps = {
  startTimestamp: number;
  heading: string;
};

export const ElapsedDisplay = ({
  startTimestamp,
  heading,
}: ElapsedDisplayProps) => {
  const timeDisplay = useElapsedTime(startTimestamp);

  return (
    <Stack
      sx={{
        p: 2,
        borderRadius: 1,
        bgcolor: 'rgb(28, 37, 46)',
        color: 'white',
        width: '100%',
        height: '100%',
      }}
      justifyContent="center"
    >
      <ListItem sx={{ p: 0 }}>
        <ListItemIcon>
          <Iconify icon="solar:clock-circle-line-duotone" width={30} />
        </ListItemIcon>
        <ListItemText
          secondaryTypographyProps={{
            sx: { color: '#c3dcff', fontWeight: 800 }, // TODO: Figure out nice global colors later and reduce ad hoc hex
          }}
          primary={heading}
          secondary={timeDisplay}
        />
      </ListItem>
    </Stack>
  );
};
