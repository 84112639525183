import { RequestActionProps } from 'src/sections/request/types/RequestActionProps';

export const getVerifyWorkActionState = ({
  requestStateInformation,
}: RequestActionProps) => {
  const { needsVerification, isAssignedToMe } = requestStateInformation;

  const isVisible = needsVerification;

  if (!isVisible) {
    return {
      isVisible: false,
      isEnabled: false,
      message: '',
    };
  }

  const acceptMessage = 'Verify Completion';
  const canPerform = isAssignedToMe;

  return {
    isVisible: true,
    isEnabled: canPerform,
    message: canPerform ? acceptMessage : `Waiting On Dispatch`,
    confirmationTitle: acceptMessage,
    confirmationMessage: 'Are you sure you are ready to close out the request?',
  };
};
