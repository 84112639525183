import { api } from '@api';
import { LoadingButton } from '@mui/lab';
import {
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import Card from '@mui/material/Card';
import { useMutation, useQuery } from 'convex/react';
import { Doc } from 'src/convex/_generated/dataModel';
import { getUserRoleDisplay } from 'src/convex/schema/enums/userRole';
import { toast } from 'src/minimal-theme/components/snackbar';
import { ActionConfirmationDialog } from 'src/sections/request/actions/ActionConfirmationDialog';
import { useActionConfirmationDialog } from 'src/sections/request/actions/hooks/useActionConfirmationDialog';

type CompanyUserListProps = {
  currentCompany: Doc<'companies'>;
};

export function CompanyUserList({ currentCompany }: CompanyUserListProps) {
  const { confirmAction, isOpen, onClose, pendingAction } =
    useActionConfirmationDialog();

  const users = useQuery(
    api.functions.users.getAllUsersForCompany,

    {
      companyId: currentCompany._id,
    }
  );

  const togglePrimaryAdmin = useMutation(
    api.functions.users.setCompanyPrimaryAdmin
  );

  const onToggleUserAdminStatus = async (user: Doc<'users'>) => {
    const res = await togglePrimaryAdmin({
      userId: user._id,
      isAdmin: !user.isCompanyPrimaryAdmin,
    });

    if (res.success) {
      toast.success(res.message);
      onClose();
    } else {
      toast.error(res.message);
      onClose();
    }
  };

  const sortedUsers =
    users?.sort((a, b) => {
      if (a.isCompanyPrimaryAdmin && !b.isCompanyPrimaryAdmin) return -1;
      if (!a.isCompanyPrimaryAdmin && b.isCompanyPrimaryAdmin) return 1;
      return 0;
    }) ?? [];

  const handleOpenToggleAdminDialog = (user: Doc<'users'>) => {
    const userIsAdmin = user.isCompanyPrimaryAdmin;

    const name = user.clerkUser.fullName;

    confirmAction({
      title: userIsAdmin ? `Demote ${name}` : `Promote ${name}`,
      message: userIsAdmin
        ? `Are you sure you want to demote this user as company admin?`
        : 'Are you sure you want to promote this user to company admin?',
      onConfirm: () => onToggleUserAdminStatus(user),
    });
  };

  return (
    <>
      <Card sx={{ mt: 3 }}>
        <CardHeader
          title={'Company Users'}
          subheader={
            'As a Super Admin, you can assign users to be company admins, which allows them to see and update their comapny settings'
          }
          sx={{ mb: 3 }}
        />
        <Divider />
        <List>
          {sortedUsers.map(u => (
            <ListItem key={u._id}>
              <ListItemText
                primary={u.clerkUser.fullName}
                secondary={getUserRoleDisplay(u.primaryRoleType)}
              />
              <ListItemSecondaryAction>
                {u.isCompanyPrimaryAdmin ? (
                  <LoadingButton
                    variant="contained"
                    onClick={() => handleOpenToggleAdminDialog(u)}
                    color="warning"
                  >
                    Set To Regular Company User
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    variant="contained"
                    onClick={() => handleOpenToggleAdminDialog(u)}
                    color="primary"
                  >
                    Promote To Company Admin
                  </LoadingButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <ActionConfirmationDialog
          open={isOpen}
          onClose={onClose}
          {...pendingAction}
        />
      </Card>
    </>
  );
}
