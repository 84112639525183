import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Link,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useParams } from '@tanstack/react-router';
import { useQuery } from 'convex/react';
import { api } from 'src/convex/_generated/api';

import { Id } from 'src/convex/_generated/dataModel';
import { EnhancedRequest } from 'src/convex/functions/requests';
import { Label } from 'src/minimal-theme/components/label';

export function RequestParticipants() {
  const params = useParams({ from: '/_auth/dashboard/requests/$requestId' });
  const request = useQuery(api.functions.requests.getRequest, {
    requestId: params.requestId as Id<'requests'>,
  });

  if (!request) return null;

  return (
    <Card>
      <CardHeader title="Participants" />
      <Stack spacing={3} sx={{ p: 3 }}>
        {getParticipants(request).map(({ title, participant }) => (
          <Participant
            key={title}
            participant={participant}
            title={title}
            isCurrentAssignee={
              !!participant?._id &&
              participant?._id === request.currentAssignedToId
            }
            requestId={request._id}
            isDeleted={participant.isDeleted}
          />
        ))}
      </Stack>
    </Card>
  );
}

type Participant = {
  _id?: Id<'users'>;
  fullName: string;
  phone: string;
  isDeleted?: boolean;
  imageUrl?: string;
  primaryLocationGroupId?: Id<'groups'>;
};

type ParticipantProps = {
  participant: Participant;
  title: string;
  isCurrentAssignee: boolean;
  isDeleted?: boolean;
  requestId: Id<'requests'>;
};

function Participant({
  participant,
  title,
  isCurrentAssignee,
  requestId,
}: ParticipantProps) {
  const theme = useTheme();

  const locationGroup = useQuery(
    api.functions.companies.getLocationById,
    participant.primaryLocationGroupId
      ? { id: participant.primaryLocationGroupId as Id<'groups'> }
      : 'skip'
  );

  const noParticipant = participant.fullName === '';

  if (noParticipant) {
    return null;
  }

  return (
    <>
      <ListItem
        sx={{
          cursor: 'default',
          '&:hover': { backgroundColor: 'transparent' },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              border: isCurrentAssignee
                ? `4px solid ${theme.vars.palette.primary.main}`
                : '4px solid transparent',
              borderRadius: '50%',
            }}
          >
            <Avatar src={participant.imageUrl ?? ''}>
              {participant.fullName[0]}
            </Avatar>
          </Box>
          <Box sx={{ flexGrow: 1, minWidth: 0 }}>
            {isCurrentAssignee && <Label color="primary">Assigned</Label>}
            <Box sx={{ display: 'flex' }}>
              <Typography variant="subtitle2" noWrap>
                {title}
                {locationGroup?.name ? ` - ${locationGroup.name}` : ''}
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {participant.fullName}
            </Typography>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {participant.isDeleted ? (
                `Deleted User`
              ) : (
                <Link href={`tel:${participant.phone}`}>
                  {participant.phone}
                </Link>
              )}
            </Typography>
          </Box>
        </Stack>
      </ListItem>
    </>
  );
}
type Section = {
  title: string;
  participant: Participant;
};

function getParticipants(request: EnhancedRequest) {
  const {
    activeDriver,
    fleetDispatcher,
    serviceDispatcher,
    technician,
    activeBroker,
  } = request;

  return [
    {
      title: 'Driver',
      participant: {
        _id: activeDriver?._id,
        fullName:
          activeDriver?.clerkUser.fullName ??
          `${request.tempDriverFirstName || ''} ${request.tempDriverLastName || ''}`.trim(),
        phone:
          activeDriver?.clerkUser.primaryPhoneNumber.phoneNumber ??
          (request.tempDriverPhone || ''),
        imageUrl: activeDriver?.clerkUser.imageUrl,
        primaryLocationGroupId: activeDriver?.primaryLocationGroupId,
        isDeleted: activeDriver?.isDeleted,
      },
    },
    {
      title: 'Fleet Dispatcher',
      participant: {
        _id: fleetDispatcher?._id,
        fullName:
          fleetDispatcher?.clerkUser.fullName ??
          `${request.tempFleetDispatchFirstName || ''} ${request.tempFleetDispatchLastName || ''}`.trim(),
        phone:
          fleetDispatcher?.clerkUser.primaryPhoneNumber.phoneNumber ??
          (request.tempFleetDispatchPhone || ''),
        imageUrl: fleetDispatcher?.clerkUser.imageUrl,
        primaryLocationGroupId: fleetDispatcher?.primaryLocationGroupId,
        isDeleted: fleetDispatcher?.isDeleted,
      },
    },
    {
      title: 'Third Party Dispatcher',
      participant: activeBroker
        ? {
            _id: activeBroker._id,
            fullName: activeBroker.clerkUser.fullName,
            phone: activeBroker.clerkUser.primaryPhoneNumber.phoneNumber,
            imageUrl: activeBroker?.clerkUser.imageUrl,
            primaryLocationGroupId: activeBroker?.primaryLocationGroupId,
            isDeleted: activeBroker?.isDeleted,
          }
        : null,
    },
    {
      title: 'Service Dispatcher',
      participant: serviceDispatcher
        ? {
            _id: serviceDispatcher._id,
            fullName: serviceDispatcher.clerkUser.fullName,
            phone: serviceDispatcher.clerkUser.primaryPhoneNumber.phoneNumber,
            imageUrl: serviceDispatcher?.clerkUser.imageUrl,
            primaryLocationGroupId: serviceDispatcher?.primaryLocationGroupId,
            isDeleted: serviceDispatcher?.isDeleted,
          }
        : null,
    },
    {
      title: 'Technician',
      participant: technician
        ? {
            _id: technician._id,
            fullName: technician.clerkUser.fullName,
            phone: technician.clerkUser.primaryPhoneNumber.phoneNumber,
            imageUrl: technician?.clerkUser.imageUrl,
            primaryLocationGroupId: technician?.primaryLocationGroupId,
            isDeleted: technician?.isDeleted,
          }
        : null,
    },
  ].filter(section => section.participant !== null) as Section[];
}
