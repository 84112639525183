export function resizeImage(file: File, maxWidth: number): Promise<Blob> {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = e => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;

        let width = img.width;
        let height = img.height;

        if (width > maxWidth) {
          height = (maxWidth * height) / width;
          width = maxWidth;
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          blob => {
            resolve(blob!);
          },
          'image/jpeg',
          0.8
        ); // Quality 0.8 is good balance
      };
      img.src = e.target?.result as string;
    };
    reader.readAsDataURL(file);
  });
}
